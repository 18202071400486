import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { IonicModule } from '@ionic/angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { MaterialModule } from 'app/material/material.module';

import { HorgaszatMegkezdeseModalPage } from './horgaszat-megkezdese-modal.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MaterialModule,
    NgSelectModule,
    ReactiveFormsModule,
  ],
  declarations: [HorgaszatMegkezdeseModalPage]
})
export class HorgaszatMegkezdeseModalPageModule {}
