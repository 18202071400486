import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { CoreModule } from 'app/core/core.module';

import { ListaNezetPageRoutingModule } from './lista-nezet-routing.module';

import { ListaNezetPage } from './lista-nezet.page';
import {ScrollingModule} from '@angular/cdk/scrolling';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        ListaNezetPageRoutingModule,
        ScrollingModule,
        CoreModule
    ],
  declarations: [ListaNezetPage]
})
export class ListaNezetPageModule {}
