import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';

import { PostafiokService } from 'app/services/postafiok.service';
import { ConnectionStateEnum, ConnectionStateService } from 'app/services/connection-state.service';

@Component({
  selector: 'mohosz-header-notification',
  templateUrl: './header-notification.component.html',
  styleUrls: ['./header-notification.component.scss'],
})
export class HeaderNotificationComponent extends OnDestroyMixin implements OnInit {

  kezbesitetlenUzenetekSzama: number;
  connectionState = ConnectionStateEnum.ONLINE;

  constructor(
    public connectionStateService: ConnectionStateService,
    public router: Router,
    private postafiokService: PostafiokService
  ) {
    super();
    this.connectionStateService.state.pipe(untilComponentDestroyed(this)).subscribe(state =>
      this.connectionState = state
    );
  }

  ngOnInit() {
    this.postafiokService.kezbesitetlenUzenetekSzama.pipe(untilComponentDestroyed(this))
      .subscribe(result => this.kezbesitetlenUzenetekSzama = result);
  }

  onNotificationClick() {
    // todo: refresh current page - remove workaround
    this.router.navigateByUrl('/tabs/postafiok/kimeno-uzenetek', {skipLocationChange: true}).then(() => {
      this.router.navigate(['/tabs/postafiok/bejovo-uzenetek']);
    });
  }
}
