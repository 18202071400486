import { Component } from '@angular/core';
import { LifeCycleService } from 'app/services/lifecycle.service';

@Component({
  selector: 'horgasz-jegyvasarlas',
  templateUrl: './jegyvasarlas.page.html',
  styleUrls: ['./jegyvasarlas.page.scss'],
  providers: [LifeCycleService]
})
export class JegyvasarlasPage {

  constructor(
    private lifeCycleService: LifeCycleService
  ) {}

  ionViewDidEnter() {
    this.lifeCycleService.propagateDidEnter();
  }

}
