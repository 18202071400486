import { Injectable } from '@angular/core';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { AddFogasRequest, ElektronikusFogas, ElektronikusFogasNyilvantartas, FogasiNaplo, FogasNyilvantartasRequest, HorgaszNapRequest, MobilAppControllerService, VizteruletHorgaszat } from 'api';
import { TimerMutex } from 'app/core/timer-mutex';
import { Utils } from 'app/core/utils';
import { LocationService } from 'app/services/location.service';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { CacheService } from './cache.service';

export interface LocalFogasiNaplo {
  enaploNyilvantartas: ElektronikusFogasNyilvantartas;
  addFogasRequestList: Array<AddFogasRequest>;
  horgaszNapRequestList: Array<HorgaszNapRequest>;
}

@Injectable({
  providedIn: 'root'
})
export class FogasiNaploService extends OnDestroyMixin {

  static readonly KEY_PREFIX = 'fogasiNaplo';

  ervenyesFogasiNaplo: FogasiNaplo;

  refreshedDate: Observable<Date>;
  enaploNyilvantartas: Observable<ElektronikusFogasNyilvantartas>;

  private refreshedDateSubject = new BehaviorSubject<Date>(null);
  private enaploNyilvantartasSubject = new BehaviorSubject<ElektronikusFogasNyilvantartas>(null);

  private timerMutex = new TimerMutex(1);

  constructor(
    private authService: AuthService,
    private mobilAppControllerService: MobilAppControllerService,
    private cacheService: CacheService,
    private locationService: LocationService,
  ) {
    super();

    this.refreshedDate = this.refreshedDateSubject.asObservable();
    this.enaploNyilvantartas = this.enaploNyilvantartasSubject.asObservable().pipe(
      filter(value => !!value)
    );

    this.authService.authentication.pipe(untilComponentDestroyed(this)).subscribe(async auth => {
      if(auth.felhasznalo.ervenyesFogasiNaplo?.elektronikus){
        this.ervenyesFogasiNaplo = auth.felhasznalo.ervenyesFogasiNaplo;
        this.getLocalFogasiNaplo().then(() => {
          this.refresh();
          this.nextRefreshedDate();
        });
      }
    });
  }

  refresh() {
    this.timerMutex.runExclusive(async () => {
      if(this.ervenyesFogasiNaplo){
        Promise.all([this.syncHorgaszNapRequests(), this.syncLocalFogasok()])
          .then(() => this.refreshEnaploNyilvantartas())
          .catch(() => {});
        await this.refreshEnaploNyilvantartas();
      }
    });
  }

  async enaploAddFogas(request: AddFogasRequest) {
    request.gpsKoordinata = this.locationService.lastGpsKoordinata;
    await this.addFogasToStorage(request);
    this.refresh();
  }

  async enaploAddHorgaszNap(request: HorgaszNapRequest) {
    request.gpsKoordinata = this.locationService.lastGpsKoordinata;
    await this.addHorgaszNapToStorage(request);
    this.refresh();
  }

  private getCacheId(){
    return this.ervenyesFogasiNaplo ? FogasiNaploService.KEY_PREFIX + this.ervenyesFogasiNaplo.sorszam : null;
  }

  private async getLocalFogasiNaplo(){
    let localFogasiNaplo: LocalFogasiNaplo =  await this.cacheService.get<LocalFogasiNaplo>(this.getCacheId());
    if(!localFogasiNaplo){
      localFogasiNaplo = {
        enaploNyilvantartas: undefined,
        addFogasRequestList: [],
        horgaszNapRequestList: []
      } as LocalFogasiNaplo;
      await this.cacheService.set<LocalFogasiNaplo>(this.getCacheId(), localFogasiNaplo);
    }
    return localFogasiNaplo;
  }

  private async refreshEnaploNyilvantartas(){
    if (this.ervenyesFogasiNaplo?.elektronikus) {
      const localFogasiNaplo = await this.getLocalFogasiNaplo();
      if(localFogasiNaplo.enaploNyilvantartas){
        await this.publishEnaploNyilvantartas(localFogasiNaplo.enaploNyilvantartas);
      }
      try {
        const request: FogasNyilvantartasRequest = { fogasiNaploId: this.ervenyesFogasiNaplo.sorszam };
        const enaploNyilvantartas = await this.mobilAppControllerService.enaploLekerdezes(request).toPromise();
        localFogasiNaplo.enaploNyilvantartas = enaploNyilvantartas;
        await this.cacheService.set<LocalFogasiNaplo>(this.getCacheId(), localFogasiNaplo);
        await this.publishEnaploNyilvantartas(enaploNyilvantartas);
        this.nextRefreshedDate();
      } catch (err) {}
    }
  }

  private async publishEnaploNyilvantartas(enaploNyilvantartas: ElektronikusFogasNyilvantartas){
    //local fogasok
    const localFogasiNaplo = await this.getLocalFogasiNaplo();
    const localFogasok = localFogasiNaplo.addFogasRequestList.map(addFogasRequest => {
      const elektronikusFogas: ElektronikusFogas = {
        halfajId: addFogasRequest.halfajId,
        suly: addFogasRequest.suly,
        vizteruletId: addFogasRequest.vizteruletId,
        idopont: addFogasRequest.idopont
      };
      return elektronikusFogas;
    });
    enaploNyilvantartas.fogasok = enaploNyilvantartas.fogasok.concat(localFogasok);

    //local horgasz napok
    const localHorgaszattalToltottNapok = await this.localToHorgaszattalToltottNapMap();
    localHorgaszattalToltottNapok.forEach((localVizteruletHorgaszatok, fogasNap) => {
      const vizteruletHorgaszatok = enaploNyilvantartas.horgaszattalToltottNapok
        .find(htn => htn.datum === fogasNap);
      if(vizteruletHorgaszatok){
        vizteruletHorgaszatok.horgaszatList = vizteruletHorgaszatok.horgaszatList.concat(localVizteruletHorgaszatok);
      } else {
        enaploNyilvantartas.horgaszattalToltottNapok.push({ datum: fogasNap, horgaszatList: localVizteruletHorgaszatok });
      }
    });
    this.enaploNyilvantartasSubject.next(enaploNyilvantartas);
  }

  private async localToHorgaszattalToltottNapMap(){
    const localFogasiNaplo = await this.getLocalFogasiNaplo();
    const localHorgasznapRequests = localFogasiNaplo.horgaszNapRequestList;
    const fogasDateFormat = 'YYYY-MM-DD';
    const horgaszattalToltottNapMap: Map<string, Array<VizteruletHorgaszat>> = new Map();
    Utils.distinct(localHorgasznapRequests.map(addFogasRequest =>
      moment(addFogasRequest.megkezdes).format(fogasDateFormat)
    )).forEach(date => horgaszattalToltottNapMap.set(date, []));
    localHorgasznapRequests.map(horgaszNapRequest => {
      const fogasNap = moment(horgaszNapRequest.megkezdes).format(fogasDateFormat);
      const vizteruletHorgaszat: VizteruletHorgaszat = {
        vizteruletId: horgaszNapRequest.vizteruletId,
        megkezdes: horgaszNapRequest.megkezdes,
        befejezes: horgaszNapRequest.befejezes,
        gpsKoordinata: horgaszNapRequest.gpsKoordinata,
      };
      horgaszattalToltottNapMap.get(fogasNap).push(vizteruletHorgaszat);
    });
    return horgaszattalToltottNapMap;
  }

  private async addHorgaszNapToStorage(horgaszNapRequest: HorgaszNapRequest) {
    const localFogasiNaplo = await this.getLocalFogasiNaplo();
    const horgaszNapRequestList = localFogasiNaplo.horgaszNapRequestList;
    horgaszNapRequestList.filter(hnr => hnr.befejezes === horgaszNapRequest.befejezes)
      .forEach(hnr => hnr.befejezes = Utils.localDateTimeNow());

    //befejezeskor nem tarolunk kulon requestet ha letezik
    const letezoHorgaszNapRequest = horgaszNapRequestList
      .filter(hnr => hnr.fogasiNaploId === horgaszNapRequest.fogasiNaploId)
      .filter(hnr => hnr.vizteruletId === horgaszNapRequest.vizteruletId)
      .find(hnr => hnr.megkezdes === horgaszNapRequest.megkezdes);
    if(letezoHorgaszNapRequest){
      letezoHorgaszNapRequest.befejezes = horgaszNapRequest.befejezes;
    } else {
      horgaszNapRequestList.push(horgaszNapRequest);
    }
    localFogasiNaplo.horgaszNapRequestList = horgaszNapRequestList;
    await this.cacheService.set(this.getCacheId(), localFogasiNaplo);
  }

  private async syncHorgaszNapRequests() {
    const localFogasiNaplo = await this.getLocalFogasiNaplo();
    const localHorgaszNapok = localFogasiNaplo.horgaszNapRequestList;
    if(localHorgaszNapok.length > 0) {
      const jobs = localHorgaszNapok.map(request =>
        this.mobilAppControllerService.enaploAddHorgaszNap(request).toPromise()
      );
      await Promise.all(jobs);
      await this.removeLocalHorgaszNapRequests();
    }
  }

  private async addFogasToStorage(request: AddFogasRequest) {
    const localFogasiNaplo = await this.getLocalFogasiNaplo();
    const localFogasok = localFogasiNaplo.addFogasRequestList;
    localFogasok.push(request);
    await this.cacheService.set(this.getCacheId(), localFogasiNaplo);
  }

  private async syncLocalFogasok() {
    const localFogasiNaplo = await this.getLocalFogasiNaplo();
    const localFogasok = localFogasiNaplo.addFogasRequestList;
    if (localFogasok.length > 0) {
      const jobs = localFogasok.map(fogas =>
        this.mobilAppControllerService.enaploAddFogas(fogas).toPromise()
      );
      await Promise.all(jobs);
      await this.removeLocalFogasok();
    }
  }

  private async removeLocalFogasok() {
    const localFogasiNaplo = await this.getLocalFogasiNaplo();
    localFogasiNaplo.addFogasRequestList = [];
    await this.cacheService.set(this.getCacheId(), localFogasiNaplo);
  }

  private async removeLocalHorgaszNapRequests() {
    const localFogasiNaplo = await this.getLocalFogasiNaplo();
    localFogasiNaplo.horgaszNapRequestList = [];
    await this.cacheService.set(this.getCacheId(), localFogasiNaplo);
  }

  private nextRefreshedDate() {
    this.cacheService.getCacheItem<any>(this.getCacheId()).then(item => {
      if(item){
        this.refreshedDateSubject.next(item.cachedDate);
      }
    });
  }
}
