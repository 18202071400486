import { Component } from '@angular/core';
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { KedvencHorgaszhelyService } from 'app/kedvenc-horgaszhelyek/kedvenc-horgaszhely.service';
import { LifeCycleService } from 'app/services/lifecycle.service';
import { LocationService } from 'app/services/location.service';

@Component({
  selector: 'horgasz-kedvenc-horgaszhelyek',
  templateUrl: './kedvenc-horgaszhelyek.page.html',
  styleUrls: ['./kedvenc-horgaszhelyek.page.scss'],
  providers: [LifeCycleService]
})
export class KedvencHorgaszhelyekPage extends OnDestroyMixin {

  constructor(
    private locationService: LocationService,
    private kedvencHorgaszhelyService: KedvencHorgaszhelyService,
    private lifeCycleService: LifeCycleService
  ) {
    super();
  }

  ionViewDidEnter(){
    this.lifeCycleService.propagateDidEnter();
    this.locationService.getLocation().then(pos => {
      this.kedvencHorgaszhelyService.refreshContentByLocation(pos);
    });
  }

}
