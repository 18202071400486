<div class="slider-frame">

  <div *ngIf="base64Content || iconUrl" class="slider-image">
    <horgasz-base64-content *ngIf="base64Content" [content]="base64Content" width="64px"></horgasz-base64-content>
    <img *ngIf="iconUrl" src="{{iconUrl}}" class="image" alt="">
  </div>

  <div class="slider-bar">
    <span *ngIf="label" class="label">{{label}}</span>
    <ng-container *ngTemplateOutlet="bar"></ng-container>
  </div>

  <input *ngIf="control" style="display: none;" [formControl]="control" [value]="value">

  <ng-template #bar>
    <div [ngClass]="disabled ? 'bar disabled': 'bar'">
      <div *ngFor="let val of values" (click)="jumpTo(val)" (mouseover)="onMouseOver(val)" (touchmove)="onMouseOver(val)" class="flex"
           [ngClass]="(step > 0 && value >= val) || (0 > step && val >= value) ? 'active step' : 'step'">
        <div *ngIf="!disabled && val === value" class="button" [ngClass]="drag ? 'drag' : ''" (mousedown)="onMouseDown()" (touchstart)="onMouseDown()">
          <div class="left arrow" (mousedown)="onMouseDown()" (touchstart)="onMouseDown()"></div>
          <div class="right arrow" (mousedown)="onMouseDown()" (touchstart)="onMouseDown()"></div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
