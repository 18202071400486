<ion-item *ngIf="uzenet" [class.selected]="selected" [class.olvasatlan]="!uzenet.olvasott">
  <ion-avatar slot="start">
    <horgasz-base64-content *ngIf="uzenet.profilKep; else monogram" [content]="uzenet.profilKep"></horgasz-base64-content>
    <ng-template #monogram>
      <span monogram [nev]="uzenet.feladoNev" [monogramText]="uzenet.monogram"></span>
    </ng-template>
  </ion-avatar>
  <ion-label>
    <h6>{{ uzenet.feladoNev }}</h6>
    <div class="datum">{{uzenet.kuldesDatum | uzenetDateTime}}</div>
    <p class="text-overflow-ellipsis line-2">
       {{uzenet.cim}}
    </p>
    <ng-container *ngIf="uzenet.uzenetValaszLehetoseg">
      <ion-buttons>
        <ng-container *ngIf="uzenet.cimzettValasz === null && uzenet.olvasott; else buttonLike">
          <ion-button (click)="onIgenClick($event)" fill="outline" color="primary" class="button-small">Igen</ion-button>
          <ion-button (click)="onNemClick($event)" fill="outline" color="primary" class="button-small">Nem</ion-button>
        </ng-container>
      </ion-buttons>
      <ng-template #buttonLike>
        <div class="buttons-row">
          <div class="button-like-object" [class.selected]="uzenet.cimzettValasz === true">Igen</div>
          <div class="button-like-object" [class.selected]="uzenet.cimzettValasz === false">Nem</div>
        </div>
      </ng-template>
    </ng-container>
  </ion-label>
</ion-item>
