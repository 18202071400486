import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Base64Content, KedvencHorgaszhely, KedvencHorgaszhelyRequest, MobilAppControllerService } from 'api';
import { KedvencHorgaszhelyService } from 'app/kedvenc-horgaszhelyek/kedvenc-horgaszhely.service';
import { routesConfig } from 'config/routesConfig';
import { Utils } from 'app/core/utils';
import { ConnectionStateService } from 'app/services/connection-state.service';
import { ToastService } from 'app/services/toast.service';

@Component({
  selector: 'horgasz-kedvenc-horgaszhely-poi-card',
  templateUrl: './kedvenc-horgaszhely-poi-card.component.html',
  styleUrls: ['./kedvenc-horgaszhely-poi-card.component.scss'],
})
export class KedvencHorgaszhelyPoiCardComponent implements OnInit, OnChanges {

  @Input() poi: KedvencHorgaszhely;
  @Output() closePoiPopup: EventEmitter<any> = new EventEmitter();

  kepBase64Content: Base64Content;

  constructor(
    private connectionStateService: ConnectionStateService,
    private router: Router,
    private mobilAppControllerService: MobilAppControllerService,
    private kedvencHorgaszhelyService: KedvencHorgaszhelyService,
    private toastService: ToastService,
  ) { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.poi && this.poi){
      if(Utils.hasValue(this.poi.details.kepId) && this.connectionStateService.isOnline()){
        const request = { id: this.poi.id } as KedvencHorgaszhelyRequest;
        this.mobilAppControllerService.kedvencHorgaszhelyKepLetoltes(request).toPromise()
          .then(result => this.kepBase64Content = result)
          .catch(error => this.toastService.httpError(error));
      } else {
        this.kepBase64Content = undefined;
      }
    }
  }

  onKedvencHelySzerkesztese() {
    if (this.poi?.id) {
      this.kedvencHorgaszhelyService.setKivalasztottHely(this.poi);
      this.router.navigateByUrl(`${routesConfig.tabs}/${routesConfig.kedvencHorgaszhelyek}/${routesConfig.szerkesztes}`);
    }
  }

  onCloseClick() {
    this.closePoiPopup.emit(true);
  }

  goToLocation(){
    Utils.goToLocation(this.poi?.koordinata.szelessegiFok, this.poi?.koordinata.hosszusagiFok);
  }
}

