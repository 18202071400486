<ion-header>
  <ion-toolbar color="secondary">
    <ion-title>Horgász okmánytárca</ion-title>
    <ion-buttons slot="end" class="ion-margin-top">
      <mohosz-header-notification></mohosz-header-notification>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-tabs>
    <ion-tab-bar slot="top">
      <!-- tab -->
      <ion-tab-button onClickScroll tab="horgaszkartya">
        <ion-label>Horgászkártya</ion-label>
      </ion-tab-button>
      <!-- tab -->
      <ion-tab-button onClickScroll tab="jegyek">
        <ion-label>Jegyeim</ion-label>
      </ion-tab-button>
      <!-- tab -->
      <ion-tab-button onClickScroll tab="nyilatkozatok">
        <ion-label>Nyilatkozatok</ion-label>
      </ion-tab-button>
      <!-- tab -->
      <ion-tab-button onClickScroll tab="horgaszvizsga">
        <ion-label>Horgászvizsga</ion-label>
      </ion-tab-button>
      <!-- tab -->
      <ion-tab-button onClickScroll tab="versenyzoi-engedely">
        <ion-label>Versenyzői engedély</ion-label>
      </ion-tab-button>
    </ion-tab-bar>
  </ion-tabs>
</ion-content>

