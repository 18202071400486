import { Component, ElementRef, EventEmitter, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import {
  Base64Content,
  GPSKoordinata,
  HorgaszturizmusMapPoi,
  HorgaszturizmusPoiItem,
  KedvencHorgaszhely,
  KedvencHorgaszhelyLetrehozasRequest,
  KedvencHorgaszhelyModositasRequest,
  MobilAppControllerService,
  MobilAppFelhasznalo,
  UploadFileResponse
} from 'api';
import { AuthService } from 'app/auth/auth.service';
import { SelectableItem } from 'app/core/apienum/selectable-item';
import { Utils, nameofFactory } from 'app/core/utils';
import { FileUploaderConfiguration } from 'app/file-uploader/file-uploader-configuration';
import { FileUploaderMimeType } from 'app/file-uploader/file-uploader-mime-type.enum';
import { FileUploaderResult } from 'app/file-uploader/file-uploader-result';
import { FileUploaderComponent } from 'app/file-uploader/file-uploader/file-uploader.component';
import { KedvencHorgaszhelyService } from 'app/kedvenc-horgaszhelyek/kedvenc-horgaszhely.service';
import { LifeCycleService } from 'app/services/lifecycle.service';
import { ToastService } from 'app/services/toast.service';
import { TorzsadatProviderService } from 'app/services/torzsadat-provider.service';
import { routesConfig } from 'config/routesConfig';


const KHLR = nameofFactory<KedvencHorgaszhelyLetrehozasRequest>();

@Component({
  selector: 'horgasz-kedvenc-horgaszhely-szerkesztese',
  templateUrl: './kedvenc-horgaszhely-szerkesztese.page.html',
  styleUrls: ['./kedvenc-horgaszhely-szerkesztese.page.scss'],
  providers: [LifeCycleService]
})
export class KedvencHorgaszhelySzerkesztesePage extends OnDestroyMixin {

  @ViewChild('uploader') uploader: FileUploaderComponent;
  @ViewChild('lokacio') lokacio: ElementRef;

  title = 'Kedvenc horgászhely szerkesztése';
  felhasznalo: MobilAppFelhasznalo;
  isLetrehozas: boolean;
  fileUploaderConfig = new FileUploaderConfiguration();
  uploadEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  uploadedFile: UploadFileResponse;
  mentettKepId: number;
  kedvencHorgaszhely: KedvencHorgaszhely;
  form: FormGroup;
  horgasztatasok: Array<SelectableItem> = [];
  id: number;
  kozelbenPoiList: Array<HorgaszturizmusMapPoi>;

  KHLR = KHLR;

  base64Content: Base64Content | undefined;

  constructor(
    public router: Router,
    private torzsadatProviderService: TorzsadatProviderService,
    private authService: AuthService,
    private mobilAppControllerService: MobilAppControllerService,
    private kedvencHorgaszhelyService: KedvencHorgaszhelyService,
    private alertController: AlertController,
    private toastService: ToastService,
    private formBuilder: FormBuilder,
    private lifeCycleService: LifeCycleService
  ) {
    super();

    // felhasznalo
    this.authService.authentication.pipe(untilComponentDestroyed(this)).subscribe(auth => {
      this.felhasznalo = auth.felhasznalo;
    });

    // vizteruletek
    this.torzsadatProviderService.horgasztatasList.pipe(untilComponentDestroyed(this)).subscribe(
      result => this.horgasztatasok = result.map(horgasztatas =>
        SelectableItem.of(horgasztatas.id, horgasztatas.nev)));
    this.fileUploaderConfig.allowedMimeTypes = [FileUploaderMimeType.JPG, FileUploaderMimeType.PNG];
    this.fileUploaderConfig.maxFileSizeInMb = 10;
    this.fileUploaderConfig.uploadPrefix = '/api/mohosz/mobilapp/mobilapp-kedvenchorgaszhely-kepfeltoltes';
    this.fileUploaderConfig.showHint = true;
    this.fileUploaderConfig.enableFilterByMimeType = true;
    this.fileUploaderConfig.feltoltoGomb.szoveg = 'Kép feltöltése';
    this.fileUploaderConfig.externalUploadEvent = this.uploadEvent;
  }

  ionViewDidEnter(){
    this.lifeCycleService.propagateDidEnter();
    this.refresh();
  }

  refresh() {
    this.kedvencHorgaszhely = this.kedvencHorgaszhelyService.getKivalasztottHely();
    if(this.kedvencHorgaszhely && Utils.hasValue(this.kedvencHorgaszhely.details.kepId)){
      this.mobilAppControllerService.kedvencHorgaszhelyKepLetoltes({id: this.kedvencHorgaszhely.id}).toPromise()
        .then(result => this.base64Content = result);
    }
    this.isLetrehozas = !this.kedvencHorgaszhely;
    this.buildForm();
    if (!this.isLetrehozas) {
      this.loadKozeliVizteruletek(this.kedvencHorgaszhely.koordinata);
    }
  }

  onKoordinataTorles(){
    this.form.controls.koordinata.get('hosszusagiFok').setValue(null);
    this.form.controls.koordinata.get('szelessegiFok').setValue(null);
    this.kozelbenPoiList = null;
  }

  onKoordinataChanged(koordinata: GPSKoordinata){
    this.form.controls.koordinata.get('hosszusagiFok').setValue(koordinata.hosszusagiFok);
    this.form.controls.koordinata.get('szelessegiFok').setValue(koordinata.szelessegiFok);
    this.loadKozeliVizteruletek(koordinata);
  }

  onKedvencHorgaszhelyMentesClick() {
    if (this.isLetrehozas) {
      const letrehozasRequest: KedvencHorgaszhelyLetrehozasRequest = this.form.getRawValue();
      letrehozasRequest.szemelyId = this.felhasznalo.szemelyId;
      this.mobilAppControllerService.kedvencHorgaszhelyLetrehozas(letrehozasRequest).toPromise()
        .then(result => {
          if (Utils.hasValue(result.id)) {
            this.toastService.successMessage('Kedvenc horgászhely létrehozása sikeresen megtörtént');
            this.kedvencHorgaszhelyService.refreshContent();
            this.router.navigateByUrl(`/${routesConfig.tabs}/${routesConfig.kedvencHorgaszhelyek}/${routesConfig.terkepNezet}`);
          }
        })
        .catch(error => this.toastService.httpError(error));

    } else {
      const modositasRequest: KedvencHorgaszhelyModositasRequest = this.form.getRawValue();
      modositasRequest.id = this.kedvencHorgaszhely.id;
      this.mobilAppControllerService.kedvencHorgaszhelyModositas(modositasRequest).toPromise()
        .then(result => {
          if (Utils.hasValue(result.id)) {
            this.toastService.successMessage('Kedvenc horgászhely módosítása sikeresen megtörtént');
            this.kedvencHorgaszhelyService.refreshContent();
            this.router.navigateByUrl(`/${routesConfig.tabs}/${routesConfig.kedvencHorgaszhelyek}/${routesConfig.terkepNezet}`);
          }
        })
        .catch(error => this.toastService.httpError(error));
    }
  }

  async onKedvencHorgaszhelyTorlesClick() {
    if (this.kedvencHorgaszhely.id) {
      const confirm = await this.alertController.create({
        header: 'Biztos, hogy töröljük?',
        cssClass: 'moh-alert',
        buttons: [
          {
            text: 'Mégsem',
            cssClass: 'alert-button-cancel',
            role: 'cancel',
            handler: () => {

            },
          },
          {
            text: 'Igen',
            cssClass: 'alert-button-confirm',
            role: 'confirm',
            handler: () => {
              this.mobilAppControllerService.kedvencHorgaszhelyTorles({ id: this.kedvencHorgaszhely.id}).toPromise()
                .then(() => {
                  this.toastService.successMessage('Kedvenc horgászhely törlése sikeresen megtörtént');
                  this.kedvencHorgaszhelyService.resetKivalasztottHely();
                  this.kedvencHorgaszhelyService.refreshContent();
                  this.router.navigateByUrl(`/${routesConfig.tabs}/${routesConfig.kedvencHorgaszhelyek}/${routesConfig.terkepNezet}`);
                })
                .catch(error => this.toastService.httpError(error));
            },
          },
        ],
      });
      await confirm.present();
    }
  }

  async onEditPhotoClick() {
    await this.uploader.onUploadClick();
  }

  onDeletePhotoClick() {
    this.form.get('kepId').setValue(null);
    this.mentettKepId = null;
    this.uploadedFile = null;
  }

  onSuccessUpload(result: FileUploaderResult) {
    this.uploadedFile = result.uploadedFile;
    this.base64Content = this.uploadedFile.content;
    this.form.get('kepId').setValue(result.id);
  }

  onVizteruletKapcsolas(poi: HorgaszturizmusMapPoi) {
    if (poi?.id) {

      this.mobilAppControllerService.horgasztatasIdLekerdezes( poi?.id).toPromise()
        .then(horgasztatasId => {
          this.lokacio.nativeElement.scrollIntoView({behavior: 'smooth'});
          const horgasztatas = this.horgasztatasok.find(t => t.id === horgasztatasId);
          this.form.controls.horgasztatasId.setValue((horgasztatas?.id ?? null));
          this.toastService.successMessage('Sikeres vízterület választás.');
        })
        .catch(error => this.toastService.httpError(error));
    }
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      [KHLR('elnevezes')]: [this.kedvencHorgaszhely?.elnevezes, {validators: [Validators.required]}],
      [KHLR('kepId')]: [this.kedvencHorgaszhely?.details?.kepId],
      [KHLR('koordinata')]: this.formBuilder.group({
        ['szelessegiFok']: [this.kedvencHorgaszhely?.koordinata.szelessegiFok, Validators.required],
        ['hosszusagiFok']: [this.kedvencHorgaszhely?.koordinata.hosszusagiFok, Validators.required],
      }),
      [KHLR('leiras')]: [this.kedvencHorgaszhely?.details?.leiras, {validators: [Validators.required]}],
      [KHLR('horgasztatasId')]: [this.kedvencHorgaszhely?.details?.horgasztatasId],
    });
    if (this.kedvencHorgaszhely) {
      this.mentettKepId = this.kedvencHorgaszhely?.details.kepId;
    }
  }

  private loadKozeliVizteruletek(koordinata: GPSKoordinata) {
    this.mobilAppControllerService.findNearbyMapPois(koordinata).toPromise()
      .then(result => this.kozelbenPoiList = result.filter(poi => poi.type === HorgaszturizmusPoiItem.TypeEnum.VIZTERULET))
      .catch(error => this.toastService.httpError(error));
  }
}
