import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogData } from 'app/core/confirmation-dialog/confirmation-dialog-data';
import { ConfirmationDialogComponent } from 'app/core/confirmation-dialog/confirmation-dialog.component';

@Injectable()
export class ConfirmationDialogService {

  constructor(
    private dialog: MatDialog
  ) {
  }

  async openFigyelmeztetesDialog(dialogData: ConfirmationDialogData): Promise<any> {
    const dialogRef = this.dialog.open<ConfirmationDialogComponent>(
      ConfirmationDialogComponent, {
        data: dialogData,
        panelClass: 'moh-notification'
      });
    return await dialogRef.afterClosed().toPromise();
  }
}
