/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface HorgaszvizsgaIgazolasNyomtatasRequest { 
    nyelv: HorgaszvizsgaIgazolasNyomtatasRequest.NyelvEnum;
    szemelyId: number;
}
export namespace HorgaszvizsgaIgazolasNyomtatasRequest {
    export type NyelvEnum = 'HU' | 'EN';
    export const NyelvEnum = {
        HU: 'HU' as NyelvEnum,
        EN: 'EN' as NyelvEnum
    };
}