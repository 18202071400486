<ion-header>
  <ion-toolbar color="secondary">
    <ion-buttons *ngIf="backButton" slot="start">
      <ion-button (click)="onLocationBack()" class="back-button">
        <ion-icon name="chevron-back-outline" title="Vissza"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title size="large">{{title}}</ion-title>
    <ion-buttons *ngIf="notifications" slot="end" class="ion-margin-top">
      <horgasz-header-notification></horgasz-header-notification>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
