<div class="modal-content" *ngIf="data && vizteruletek">
  <ion-card class="modal-rogzites">
    <ion-card-header class="ion-text-center">
      <ion-item lines="none" tabindex="-1">
        <ion-label>Horgászni szeretnél?</ion-label>
        <ion-buttons slot="end">
          <ion-button (click)="dismiss()">
            <ion-icon slot="icon-only" name="close"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-item>
      <div class="ion-margin-top">
          Válaszd ki a vízterületet, ahol horgászni szeretnél!
      </div>
    </ion-card-header>

    <ion-card-content>
      <form [formGroup]="form">
        <ion-item lines="none" class="mandatory-field has-ng-select">
          <ion-label position="stacked">Vízterület</ion-label>
          <div class="ng-select-field">
            <ng-select class="moh-ng-select"
                       [clearable]="false"
                       formControlName="selectedVizteruletId"
                       [virtualScroll]="true"
                       [items]="vizteruletek">
            </ng-select>
          </div>
        </ion-item>
      </form>

      <div class="ion-text-center ion-padding-top">
        <ion-button class="ion-margin-top"
                    (click)="onHorgaszatMegkezdeseClick()"
                    [disabled]="!form.controls.selectedVizteruletId.value">
          Horgászat megkezdése
        </ion-button>
      </div>

    </ion-card-content>
  </ion-card>
</div>
