import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterLink, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { Base64ContentComponent } from 'app/core/base64-content/base64-content.component';
import { ConfirmationDialogComponent } from 'app/core/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from 'app/core/confirmation-dialog/confirmation-dialog.service';
import { HeaderNotificationComponent } from 'app/core/header-notification/header-notification.component';
import { MonogramDirective } from 'app/core/monogram.directive';
import { SafeHtmlUrlPipe } from 'app/core/safe-html-url.pipe';
import { SafeHtmlPipe } from 'app/core/safe-html.pipe';
import { MaterialModule } from 'app/material/material.module';
import { UzenetDateTimePipe } from 'app/postafiok/uzenet-date-time.pipe';
import { NoContentComponent } from './no-content/no-content.component';


@NgModule({
  declarations: [
    SafeHtmlPipe,
    SafeHtmlUrlPipe,
    Base64ContentComponent,
    MonogramDirective,
    HeaderNotificationComponent,
    UzenetDateTimePipe,
    NoContentComponent,
    ConfirmationDialogComponent
  ],
  imports: [
    MaterialModule,
    CommonModule,
    IonicModule,
    RouterModule,
    RouterLink,
  ],
  exports: [
    SafeHtmlPipe,
    SafeHtmlUrlPipe,
    Base64ContentComponent,
    MonogramDirective,
    HeaderNotificationComponent,
    UzenetDateTimePipe,
    NoContentComponent,
    ConfirmationDialogComponent
  ],
  providers: [
    SafeHtmlPipe,
    SafeHtmlUrlPipe,
    UzenetDateTimePipe,
    ConfirmationDialogService
  ]
})
export class CoreModule { }
