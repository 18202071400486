import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IonSegment, NavController } from '@ionic/angular';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { MobilAppControllerService, VizteruletCsoportLeiras } from 'api';
import { Utils } from 'app/core/utils';
import { HorgasztatasService } from '../horgasztatas.service';
import { HorgaszrendData } from './horgaszrend-data';

@Component({
  selector: 'horgasz-horgaszrend',
  templateUrl: './horgaszrend.component.html',
  styleUrls: ['./horgaszrend.component.scss']
})
export class HorgaszrendComponent extends OnDestroyMixin implements OnInit {

  horgaszrendData: HorgaszrendData;
  horgaszrendPDFContent: Uint8Array;
  hasPDFContent: boolean;
  displayableLeirasList: Array<VizteruletCsoportLeiras>;
  selectedLeiras: VizteruletCsoportLeiras;
  szolgaltatasId: number;

  constructor(
    private mobilAppControllerService: MobilAppControllerService,
    private horgasztatasService: HorgasztatasService,
    private navController: NavController,
    private activatedRoute: ActivatedRoute
  ) {
    super();
   }

  ngOnInit(): void {
    this.activatedRoute.params.pipe(untilComponentDestroyed(this)).subscribe(params => {
      const szolgaltatasId = params.szolgaltatasId ? Number(params.szolgaltatasId) : null;
      if (Utils.hasValue(szolgaltatasId) && szolgaltatasId !== this.szolgaltatasId){
        this.szolgaltatasId = szolgaltatasId;
        this.horgasztatasService.getBundle(szolgaltatasId).then(bundle => {
          this.horgaszrendData = new HorgaszrendData(bundle.regisztracio.horgaszrendContentId, bundle.regisztracio.leirasList);
          this.refreshHorgaszrend();
        });
      } else if (!Utils.hasValue(szolgaltatasId)) {
        this.navController.pop();
      }
    });
  }

  refreshHorgaszrend() {
    this.displayableLeirasList = this.horgaszrendData.dispalyedLeirasList();
    this.hasPDFContent = this.horgaszrendData.hasPDFContent();
    if (this.hasPDFContent) {
      this.mobilAppControllerService.contentBase64Download({contentId: this.horgaszrendData.horgaszrendContentId}).subscribe(
        base64Content => this.horgaszrendPDFContent = Utils.base64ToArrayBuffer(base64Content.content),
        error => console.error(error)
      );
    } else {
      this.horgaszrendPDFContent = null;
      this.selectedLeiras = this.displayableLeirasList[0];
    }
  }

  onTabClick(leiras: VizteruletCsoportLeiras) {
    this.selectedLeiras = leiras;
  }

  onNyelvChange(segment: IonSegment) {
    this.selectedLeiras = this.displayableLeirasList.find(l => l.nyelv === segment.value);
  }

}
