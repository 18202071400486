<div class="map-container">
  <div id="vedettmap" leaflet leafletDraw
       [leafletMarkerCluster]="markerLayerList"
       [leafletMarkerClusterOptions]="clusterGroupOptions"
       (leafletMarkerClusterReady)="markerClusterReady($event)"
       [leafletOptions]="mapOptions"
       [leafletDrawOptions]="drawOptions"
       (leafletMapReady)="onMapReady($event)"
       (leafletMapZoomEnd)="onMapZoomEnd($event)"
       (leafletMapMoveEnd)="onMapMoveEnd($event)">
  </div>
  <div class="poi-popup-container" *ngIf="selectedMarker">
    <horgasz-vedett-vizterulet-terkep-popup
      [vedettVizterulet]="selectedMarker.poi"
      (closePoiPopup)="onClosePoiPopupClick()">
    </horgasz-vedett-vizterulet-terkep-popup>
  </div>
</div>
