import { MobilAppFelhasznalo } from 'api';
import { AuthenticationState } from 'app/auth/authentication-state';

export class Authentication {

  felhasznalo: MobilAppFelhasznalo;

  constructor() {}

  update(felhasznalo: MobilAppFelhasznalo): Authentication {
    this.felhasznalo = felhasznalo;
    return this;
  }

  isAuthenticated(): boolean {
    return !!this.felhasznalo;
  }

  logout(): Authentication {
    this.felhasznalo = null;
    return this;
  }

  getState(): AuthenticationState {
    if (this.felhasznalo) {
      return AuthenticationState.COMPLETED;
    } else {
      return AuthenticationState.INIT;
    }
  }
}
