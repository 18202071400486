import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { SelectableItem } from 'app/core/apienum/selectable-item';
import { AktualisHorgaszatService } from 'app/fogasi-naplo/aktualis-horgaszat.service';
import { HorgaszatMegkezdeseModalData } from 'app/fogasi-naplo/fogasok/horgaszat-megkezdese-modal/horgaszat-megkezdese-modal-data';
import { LocationService } from 'app/services/location.service';

@Component({
  selector: 'horgasz-horgaszat-megkezdese-modal',
  templateUrl: './horgaszat-megkezdese-modal.page.html',
  styleUrls: ['../../rogzites-modal.scss'],
})
export class HorgaszatMegkezdeseModalPage implements OnInit {

  data: HorgaszatMegkezdeseModalData;

  vizteruletek: Array<SelectableItem>;
  form: FormGroup;

  constructor(
    private modalController: ModalController,
    private aktualisHorgaszatService: AktualisHorgaszatService,
    private locationService: LocationService
  ) {
    this.form = new FormGroup({
      ['selectedVizteruletId']: new FormControl()
    });
  }

  ngOnInit() {
    this.locationService.refresh();
    // vizterulet valaszto ertekkeszlet
    this.vizteruletek = this.data.vizteruletList.map(vizterulet =>
      SelectableItem.of(vizterulet.id, vizterulet.nev + ' (' + vizterulet.vizterkod + ')')
    );
  }

  dismiss() {
    this.modalController.dismiss({ dismissed: true });
  }

  onHorgaszatMegkezdeseClick() {
    const selectedVizteruletId = this.form.controls.selectedVizteruletId.value;
    this.aktualisHorgaszatService.megkezdes(this.data.vizteruletList.find(vt => vt.id === selectedVizteruletId));
    this.dismiss();
  }

}
