import { DecimalPipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fogasSuly',
  standalone: true,
})
export class FogasSulyPipe implements PipeTransform {
  private decimalPipe: DecimalPipe;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
  ) {
    this.decimalPipe = new DecimalPipe(locale);
  }

  transform(suly: number): string {
    return this.decimalPipe.transform(suly, '0.1');
  }

}
