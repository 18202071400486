import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppLauncher } from '@capacitor/app-launcher';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { NavController } from '@ionic/angular';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Halmatrix, HorgasztatasSzolgaltatasBundle, Megye, MobilAppControllerService, SzereploRequest, SzolgaltatoNevjegy } from 'api';
import { Utils } from 'app/core/utils';
import { ToastService } from 'app/services/toast.service';
import { TorzsadatProviderService } from 'app/services/torzsadat-provider.service';
import { DisplayObserverService } from 'app/shared/display-observer.service';
import { ApiModuleConfiguration } from 'config/ApiModuleConfiguration';
import { AuthService } from 'app/auth/auth.service';
import { HorgaszrendData } from './horgaszrend/horgaszrend-data';
import { HorgasztatasService } from './horgasztatas.service';


@Component({
  selector: 'horgasz-horgasztatas-reszletek',
  templateUrl: './horgasztatas-reszletek-page.component.html',
  styleUrls: ['./horgasztatas-reszletek-page.component.scss'],
})
export class HorgasztatasReszletekPage extends OnDestroyMixin implements OnInit {

  horgasztatasBundle: HorgasztatasSzolgaltatasBundle;
  szolgaltatoNevjegy: SzolgaltatoNevjegy;
  megyeLista: Array<Megye> = [];
  halmatrixList: Array<Halmatrix>;
  displayedHonlapCim: string;
  isSmallDisplay: boolean;
  hasHorgaszrend: boolean;
  isLoading = true;
  szolgaltatasId: number;

  hostUrl: string;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private displayObserverService: DisplayObserverService,
    private mobilAppControllerService: MobilAppControllerService,
    private torzsadatProviderService: TorzsadatProviderService,
    private toastService: ToastService,
    private horgasztatasService: HorgasztatasService,
    private router: Router,
    private navController: NavController,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
  ) {
    super();
    this.hostUrl = ApiModuleConfiguration.default().hostUrl;
  }

  ngOnInit() {
    this.activatedRoute.params.pipe(untilComponentDestroyed(this)).subscribe(params => {
      const szolgaltatasId = params.szolgaltatasId ? Number(params.szolgaltatasId) : null;
      if (Utils.hasValue(szolgaltatasId)){
        this.szolgaltatasId = szolgaltatasId;
        this.horgasztatasService.getBundle(szolgaltatasId).then(bundle => {
          this.horgasztatasBundle = bundle;
          const horgaszrendData = new HorgaszrendData(this.horgasztatasBundle.regisztracio.horgaszrendContentId, this.horgasztatasBundle.regisztracio.leirasList);
          this.hasHorgaszrend = !horgaszrendData.isEmpty();
          const nevjegyRequest: SzereploRequest = {szereploId: this.horgasztatasBundle.szolgaltatasListItem.ownerSzereploId};
          this.mobilAppControllerService.szolgaltatoNevjegy(nevjegyRequest).toPromise()
            .then(nevjegyResult => this.szolgaltatoNevjegy = nevjegyResult)
            .catch(error => this.toastService.httpError(error));
          this.displayedHonlapCim = Utils.removeUrlProtocol(this.horgasztatasBundle.regisztracio.honlapCim);
        });
      } else {
        this.toastService.errorMessage('Nem létező szolgáltató azonosító');
        this.navController.pop();
      }
    });
    this.displayObserverService.displaySize.pipe(untilComponentDestroyed(this)).subscribe(size => {
      this.isSmallDisplay = size.isSmall();
      this.changeDetectorRef.detectChanges();
    });
    this.horgasztatasService.bundleLoading.pipe(untilComponentDestroyed(this)).subscribe(loading => this.isLoading = loading);
    // megyek
    this.torzsadatProviderService.torzsadat.pipe(untilComponentDestroyed(this)).subscribe(
      torzsadat => this.megyeLista = torzsadat.megyeList
    );
    // halmatrix
    this.torzsadatProviderService.halmatrixList.pipe(untilComponentDestroyed(this)).subscribe(
      halmatrixLista => this.halmatrixList = halmatrixLista
    );
  }

  onHonlapCimClick() {
    Browser.open({url: this.horgasztatasBundle.regisztracio?.honlapCim, windowName: '_blank'});
  }

  async onJegyvasarlasClick(szolgaltatasId: number) {
    const authToken = encodeURIComponent(await this.authService.getSessionToken());
    const szolgaltatasIdString = encodeURIComponent(szolgaltatasId);
    const redirectUrl = `${this.hostUrl}/htapp-redirect/?szolgaltatasId=${szolgaltatasIdString}&authToken=${authToken}`;
    if(Capacitor.getPlatform() === 'android'){
      await Browser.open({ url: redirectUrl, windowName: '_blank' });
    } else {
      await AppLauncher.openUrl({ url: redirectUrl });
    }
  }

  async openHorgaszrend() {
    this.router.navigateByUrl(`/tabs/jegyvasarlas/horgasztatas/horgaszrend/${this.horgasztatasBundle.szolgaltatasId}`);
  }
}
