<ion-content class="moh-bg-light">

  <div [ngClass]="!versenyzoiEngedely ? 'vertical-center' : ''">

    <ion-grid>
      <ion-row>
        <ion-col size-md="6" offset-md="3">

          <ng-container *ngIf="versenyzoiEngedely; else noContent">

            <ion-card>
              <ion-card-header>
                <ion-item>
                  <ion-avatar slot="start" class="icon-moh-primary">
                    <ion-icon src='assets/icon/hbot.svg'></ion-icon>
                  </ion-avatar>
                  <ion-label class="ion-text-wrap">
                    <h6>Versenyzői engedély ({{versenyzoiEngedely.ervenyessegiEv}})</h6>
                    <p class="allapot" [ngClass]="versenyzoiEngedely?.statusz === VersenyzoiEngedelyStatuszEnum.ERVENYES ? 'active' : 'inactive'">
                      {{ versenyzoiEngedely?.statusz | versenyzoiEngedelyStatusz }}
                    </p>
                  </ion-label>
                </ion-item>
              </ion-card-header>
              <ion-card-content class="no-padding-top">
                <ion-list lines="none">
                  <ion-item>
                    <span slot="start">Kategória:</span>
                    <ion-label><strong>{{versenyzoiEngedely.korkategoria | versenyzoiEngedelyKorkategoria}}</strong></ion-label>
                  </ion-item>
                  <ion-item>
                    <span slot="start" class="ion-text-wrap">Érvényes:</span>
                    <ion-label class="ion-text-wrap">
                      <strong>
                        {{versenyzoiEngedely.ervenyessegKezdete | date: 'shortDate'}}-tól
                        <br>
                        {{versenyzoiEngedely.ervenyessegVege | date: 'shortDate'}}-ig
                      </strong>
                    </ion-label>
                  </ion-item>

                  <ion-item>
                    <ion-button [disabled]="isLetoltesDisabled" fill="outline" class="btn" (click)="onIgazolasLetoltes()">
                      <ion-icon slot="start" name="eye-outline"></ion-icon>
                      Igazolás megtekintése
                    </ion-button>
                  </ion-item>
                </ion-list>
              </ion-card-content>
            </ion-card>

          </ng-container>

        </ion-col>
      </ion-row>
    </ion-grid>

    <ng-template #noContent>
      <ion-card class="no-bg ion-text-center">
        <img alt="Figyelem" src="assets/icon/warning.svg" class="moh-warning"/>
        <ion-card-header>
          <ion-card-title>Az aktuális {{ervenyessegiEv}}-es évre még nem rendelkezel versenyzői engedéllyel.</ion-card-title>
        </ion-card-header>
      </ion-card>
    </ng-template>

  </div>

</ion-content>
