import { Component, Input, OnInit } from '@angular/core';
import { HorgaszkartyaPreviewData } from 'api';

@Component({
  selector: 'horgasz-horgaszkartya-preview',
  templateUrl: './horgaszkartya-preview.component.html',
  styleUrls: ['./horgaszkartya-preview.component.scss'],
})
export class HorgaszkartyaPreviewComponent implements OnInit {

  @Input() previewData: HorgaszkartyaPreviewData;

  DATE_FORMAT = 'yyyy.MM.dd.';

  constructor() { }

  ngOnInit() {}

}
