<ion-content class="moh-bg-light">

  <ion-refresher slot="fixed" (ionRefresh)="handleRefresh($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <ion-grid>
    <ion-row>
      <ion-col size-md="6" offset-md="3">

        <ion-list *ngIf="uzenetList">
          <ng-container *ngIf="uzenetList.length > 0 else emptyList">
            <ng-container *ngFor="let uzenet of uzenetList">
              <horgasz-uzenet-list-item
                (click)="onUzenetListItemClick(uzenet)"
                (igenClicked)="onValaszClick(uzenet, true)"
                (nemClicked)="onValaszClick(uzenet, false)"
                [uzenet]="uzenet"
                [selected]="uzenet === selectedUzenet">
              </horgasz-uzenet-list-item>
            </ng-container>
          </ng-container>
          <ng-template #emptyList>
            <p class="note">Nincs új üzenet</p>
          </ng-template>
        </ion-list>

      </ion-col>
    </ion-row>

  </ion-grid>

</ion-content>
