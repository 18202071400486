<horgasz-poi-popup *ngIf="vedettVizterulet"
  [title]="vedettVizterulet.megnevezes"
  [subTitle]="vedettVizterulet.vizteruletNev"
  (closePoiPopup)="onCloseClick()"
  [iconUrl]="vedettVizterulet?.tipus === VedettVizteruletTipusEnum.TILTOTTVIZTERULET
  ? 'assets/poi/icon/tiltott-vizterulet-popup.svg' : 'assets/poi/icon/korlatozott-vizterulet-popup.svg'">
  <ng-container middleSection>
    <div class="scrollbar-strait">
      <p style="white-space: pre-wrap;" class="ion-padding-top leiras">
        {{ vedettVizterulet.leiras }}
      </p>
    </div>
  </ng-container>
  <p endSection style="padding: 16px;">A korlátozott/tiltott területek pontos elhelyezkedésről tájékozódjon a vízparton kihelyezettinformációs táblák alapján.</p>
</horgasz-poi-popup>

