<ion-content scroll-y="false">
  <ion-grid>
    <ion-row>
      <ion-col size-md="6" offset-md="3">
        <div class="logo">
          <ion-img src="assets/logo/logo-light-mohosz.svg"></ion-img>
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-md="6" offset-md="3">
        <ion-card>
          <ion-card-header>
            <ion-card-title>Belépés</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <!-- input -->
            <ion-item class="ion-no-padding">
              <ion-label position="floating">E-mail cím/Felhasználó azonosító</ion-label>
              <ion-input
                appAutofill autocomplete="email" type="email"
                email [(ngModel)]="email">
              </ion-input>
            </ion-item>
            <!-- input -->
            <ion-item class="ion-no-padding">
              <ion-label position="floating">Jelszó</ion-label>
              <ion-input
                appAutofill autocomplete="current-password"
                [type]="hidePassword ? 'password' : 'text'"
                [(ngModel)]="password">
              </ion-input>
              <ion-icon
                style="margin-top: 24px" slot="end"
                [name]="hidePassword ? 'eye' : 'eye-off'"
                (click)="hidePassword = !hidePassword">
              </ion-icon>
            </ion-item>

            <ion-item>
              <horgasz-error-message [serverError]="serverError"></horgasz-error-message>
            </ion-item>

            <div class="login-buttons">
              <!-- button -->
<!--              <ion-button fill="clear" class="moh-link button-small ion-no-padding">-->
<!--                Elfelejtettem a jelszavamat-->
<!--              </ion-button>-->
              <!-- button -->
              <ion-button (click)="onLogin()" expand="block" [disabled]="submitted">
                Belépek
                <ion-label>
                  <ion-spinner *ngIf="submitted"></ion-spinner>
                </ion-label>
              </ion-button>
            </div>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size-md="6" offset-md="3">
        <div class="ion-margin moh-content moh-content-invert-colors">
          <p>A belépéshez csak a horgászregisztrációkor használt e-mail címedre, vagy kapott felhasználó azonosítódra és a hozzájuk tartozó jelszóra van szükséged!
            E telefonos alkalmazás használatához további regisztrációra nincs szükség!</p>
        </div>
      </ion-col>
    </ion-row>

    <section class="bg-login-bottom">
      <img alt="" src="assets/bg-login.svg">
    </section>

  </ion-grid>

</ion-content>
