import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { PostafiokItem } from 'api';
import { Utils } from 'app/core/utils';
import { PostafiokService } from 'app/services/postafiok.service';
import { ToastService } from 'app/services/toast.service';

@Component({
  selector: 'horgasz-uzenet-reszletek',
  templateUrl: './uzenet-reszletek.component.html',
  styleUrls: ['../uzenet.scss'],
})
export class UzenetReszletekComponent extends OnDestroyMixin implements OnInit {

  uzenetId: number;
  uzenet: PostafiokItem;

  constructor(
    private postafiokService: PostafiokService,
    private activatedRoute: ActivatedRoute,
    private toastService: ToastService
  ) {
    super();
   }

  ngOnInit() {
    // szolgaltatas
    this.activatedRoute.params.pipe(untilComponentDestroyed(this)).subscribe(params => {
      this.uzenetId = params.id ? Number(params.id) : null;
      if (Utils.hasValue(this.uzenetId)){
        this.uzenet = this.postafiokService.getUzenet(this.uzenetId);
      } else {
        this.toastService.errorMessage('Nem található az üzenet');
      }
    });
    this.postafiokService.uzenetek.pipe(untilComponentDestroyed(this)).subscribe(result => {
      if (result) {
        this.uzenet = this.postafiokService.getUzenet(this.uzenetId);
      }
    });
  }

  onValaszClick(valasz: boolean) {
    this.postafiokService.uzenetValasz(this.uzenet, valasz);
  }

  onIgenClick() {
    this.onValaszClick(true);
  }

  onNemClick() {
    this.onValaszClick(false);
  }

}
