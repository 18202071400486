/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FogasiRekord } from './fogasiRekord';
import { GPSKoordinata } from './gPSKoordinata';
import { Halallomany } from './halallomany';
import { HorgasztatasFigyelmeztetoUzenet } from './horgasztatasFigyelmeztetoUzenet';
import { HorgasztatasFogas } from './horgasztatasFogas';
import { HorgasztatasSzolgaltatasVegrehajtottLepes } from './horgasztatasSzolgaltatasVegrehajtottLepes';
import { SzolgaltatasRegisztracioBiralat } from './szolgaltatasRegisztracioBiralat';
import { VedettVizterulet } from './vedettVizterulet';
import { VizteruletCsoportLeiras } from './vizteruletCsoportLeiras';
import { VizteruletZarvatartas } from './vizteruletZarvatartas';
import { VizteruletZarvatartasItem } from './vizteruletZarvatartasItem';

export interface HorgasztatasSzolgaltatasRegisztracio { 
    aktualisLepes?: HorgasztatasSzolgaltatasRegisztracio.AktualisLepesEnum;
    altalanosLeiras?: string;
    atlagosVizmelyseg?: string;
    bigThumbnailKepId?: number;
    candidateThumbnailKepId?: number;
    egyebNevList?: Array<string>;
    egyediAzonosito: string;
    elutasitasList?: Array<SzolgaltatasRegisztracioBiralat>;
    felszereltsegList?: Array<HorgasztatasSzolgaltatasRegisztracio.FelszereltsegListEnum>;
    figyelmeztetoUzenetIdSequence?: number;
    figyelmeztetoUzenetList?: Array<HorgasztatasFigyelmeztetoUzenet>;
    fogasIdSequence?: number;
    fogasList?: Array<HorgasztatasFogas>;
    fogasiRekordList?: Array<FogasiRekord>;
    halallomanyList?: Array<Halallomany>;
    halgazdasagiHasznositoEmail?: string;
    halgazdasagiHasznositoTelefonszam?: string;
    halorTelefonszam?: string;
    helyrajziSzam?: string;
    honlapCim?: string;
    horgaszmodszerAltipusList?: Array<HorgasztatasSzolgaltatasRegisztracio.HorgaszmodszerAltipusListEnum>;
    horgaszrendContentId?: number;
    jovahagyasList?: Array<SzolgaltatasRegisztracioBiralat>;
    kepIds?: Array<number>;
    kiegeszitoInformacio?: string;
    koordinatak: Array<GPSKoordinata>;
    korlatozottVizteruletList?: Array<VedettVizterulet>;
    leirasList?: Array<VizteruletCsoportLeiras>;
    megkozelithetoseg?: string;
    megyeKod: string;
    nev?: string;
    originalKepIds?: Array<number>;
    ownerSzereploId: number;
    ownerSzereploNev?: string;
    terulet?: number;
    thumbnailKepId?: number;
    tiltottVizteruletList?: Array<VedettVizterulet>;
    tovabbiKoordinatak?: Array<GPSKoordinata>;
    vegrehajtottLepesek?: Array<HorgasztatasSzolgaltatasVegrehajtottLepes>;
    vizterkod?: string;
    vizteruletCsoportId?: number;
    vizteruletId?: number;
    vizteruletTipus?: HorgasztatasSzolgaltatasRegisztracio.VizteruletTipusEnum;
    zarvatartas?: VizteruletZarvatartas;
    zarvatartasIdSequence?: number;
    zarvatartasList?: Array<VizteruletZarvatartasItem>;
}
export namespace HorgasztatasSzolgaltatasRegisztracio {
    export type AktualisLepesEnum = 'ALAPADATOK' | 'ELHELYEZKEDES' | 'HORGASZREND' | 'FENYKEPEK' | 'JELLEMZOK' | 'HALALLOMANY' | 'OSSZEGZES';
    export const AktualisLepesEnum = {
        ALAPADATOK: 'ALAPADATOK' as AktualisLepesEnum,
        ELHELYEZKEDES: 'ELHELYEZKEDES' as AktualisLepesEnum,
        HORGASZREND: 'HORGASZREND' as AktualisLepesEnum,
        FENYKEPEK: 'FENYKEPEK' as AktualisLepesEnum,
        JELLEMZOK: 'JELLEMZOK' as AktualisLepesEnum,
        HALALLOMANY: 'HALALLOMANY' as AktualisLepesEnum,
        OSSZEGZES: 'OSSZEGZES' as AktualisLepesEnum
    };
    export type FelszereltsegListEnum = 'BERELHETO_HORGASZHELY' | 'HORGASZTANYA' | 'SOLYAPALYA';
    export const FelszereltsegListEnum = {
        BERELHETOHORGASZHELY: 'BERELHETO_HORGASZHELY' as FelszereltsegListEnum,
        HORGASZTANYA: 'HORGASZTANYA' as FelszereltsegListEnum,
        SOLYAPALYA: 'SOLYAPALYA' as FelszereltsegListEnum
    };
    export type HorgaszmodszerAltipusListEnum = 'KLASSZIKUS_FENEKEZES' | 'BOJLIZAS_DOBASSAL_BEHUZASSAL' | 'FEEDER_BOTOS_HORGASZAT' | 'RAGADOZO_HALAS_FENEKEZOS_HORGASZAT' | 'SPICCBOTOS_RAKOSBOTOS_HORGASZAT' | 'KLASSZIKUS_USZOZAS' | 'HARCSAZAS_BEHUZASSAL' | 'MATCH_BOTOS_HORGASZAT' | 'USZOS_RAGADOZO_HALAS_HORGASZAT' | 'WOBBLEREZES' | 'VILLANTOZAS' | 'GUMIHALAZAS_TWISTEREZES' | 'MARTOGATAS_TAPOGATAS' | 'KUTTYOGATAS' | 'LEKHORGASZAT_MORMISKAZAS' | 'MULEGYEZO_HORGASZAT';
    export const HorgaszmodszerAltipusListEnum = {
        KLASSZIKUSFENEKEZES: 'KLASSZIKUS_FENEKEZES' as HorgaszmodszerAltipusListEnum,
        BOJLIZASDOBASSALBEHUZASSAL: 'BOJLIZAS_DOBASSAL_BEHUZASSAL' as HorgaszmodszerAltipusListEnum,
        FEEDERBOTOSHORGASZAT: 'FEEDER_BOTOS_HORGASZAT' as HorgaszmodszerAltipusListEnum,
        RAGADOZOHALASFENEKEZOSHORGASZAT: 'RAGADOZO_HALAS_FENEKEZOS_HORGASZAT' as HorgaszmodszerAltipusListEnum,
        SPICCBOTOSRAKOSBOTOSHORGASZAT: 'SPICCBOTOS_RAKOSBOTOS_HORGASZAT' as HorgaszmodszerAltipusListEnum,
        KLASSZIKUSUSZOZAS: 'KLASSZIKUS_USZOZAS' as HorgaszmodszerAltipusListEnum,
        HARCSAZASBEHUZASSAL: 'HARCSAZAS_BEHUZASSAL' as HorgaszmodszerAltipusListEnum,
        MATCHBOTOSHORGASZAT: 'MATCH_BOTOS_HORGASZAT' as HorgaszmodszerAltipusListEnum,
        USZOSRAGADOZOHALASHORGASZAT: 'USZOS_RAGADOZO_HALAS_HORGASZAT' as HorgaszmodszerAltipusListEnum,
        WOBBLEREZES: 'WOBBLEREZES' as HorgaszmodszerAltipusListEnum,
        VILLANTOZAS: 'VILLANTOZAS' as HorgaszmodszerAltipusListEnum,
        GUMIHALAZASTWISTEREZES: 'GUMIHALAZAS_TWISTEREZES' as HorgaszmodszerAltipusListEnum,
        MARTOGATASTAPOGATAS: 'MARTOGATAS_TAPOGATAS' as HorgaszmodszerAltipusListEnum,
        KUTTYOGATAS: 'KUTTYOGATAS' as HorgaszmodszerAltipusListEnum,
        LEKHORGASZATMORMISKAZAS: 'LEKHORGASZAT_MORMISKAZAS' as HorgaszmodszerAltipusListEnum,
        MULEGYEZOHORGASZAT: 'MULEGYEZO_HORGASZAT' as HorgaszmodszerAltipusListEnum
    };
    export type VizteruletTipusEnum = 'FOLYO' | 'VIZTAROZO' | 'CSATORNA' | 'PATAK' | 'FOCSATORNA' | 'BANYATO' | 'MENTETT_OLDALI_HOLTAG' | 'MENTETLEN_OLDALI_HOLTAG' | 'TO';
    export const VizteruletTipusEnum = {
        FOLYO: 'FOLYO' as VizteruletTipusEnum,
        VIZTAROZO: 'VIZTAROZO' as VizteruletTipusEnum,
        CSATORNA: 'CSATORNA' as VizteruletTipusEnum,
        PATAK: 'PATAK' as VizteruletTipusEnum,
        FOCSATORNA: 'FOCSATORNA' as VizteruletTipusEnum,
        BANYATO: 'BANYATO' as VizteruletTipusEnum,
        MENTETTOLDALIHOLTAG: 'MENTETT_OLDALI_HOLTAG' as VizteruletTipusEnum,
        MENTETLENOLDALIHOLTAG: 'MENTETLEN_OLDALI_HOLTAG' as VizteruletTipusEnum,
        TO: 'TO' as VizteruletTipusEnum
    };
}