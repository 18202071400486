import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
  selector: 'horgasz-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent implements OnChanges {

  @Input() label: string;
  @Input() iconUrl: string;
  @Input() backgroundColor: string;
  @Input() withoutFrame: boolean;
  @Input() deleteButton: boolean;
  @Input() deleteButtonStyle: 'cross' | 'trash' = 'cross';
  @Input() type: 'custom' | 'fogaslathatosag' = 'custom';
  @Input() small: boolean;

  @Output() deleteButtonClicked: EventEmitter<boolean> = new EventEmitter();

  whiteTextColor: boolean;

  constructor() { }

  ngOnChanges() {
    this.whiteTextColor = this.backgroundColor !== 'white';
  }

  onDeleteClick() {
    this.deleteButtonClicked.emit(true);
  }

}
