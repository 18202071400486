import { Component, Input, OnInit } from '@angular/core';
import { HorgasztatasSzolgaltatasRegisztracio } from 'api';

@Component({
  selector: 'horgasz-horgaszmodszer-card',
  templateUrl: './horgaszmodszer-card.component.html',
  styleUrls: ['../card.component.scss', './horgaszmodszer-card.component.scss'],
})
export class HorgaszmodszerCardComponent implements OnInit {

  @Input() horgaszmodszerAltipusList: Array<HorgasztatasSzolgaltatasRegisztracio.HorgaszmodszerAltipusListEnum>;

  constructor() { }

  ngOnInit() {}

}
