<ion-content [fullscreen]="true" class="moh-bg-light" scroll-y="false">

  <horgasz-no-content *ngIf="!poiList" [isLoading]="isLoading"></horgasz-no-content>

  <horgasz-horgaszturizmus-terkep *ngIf="poiList"
    [poiList]="poiList"
    [locationPos]="locationPos"
    (mapZoomEnd)="onMapZoomEnd($event)"
    (mapMoveEnd)="onMapMoveEnd($event)">
  </horgasz-horgaszturizmus-terkep>

</ion-content>
