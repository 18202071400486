import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Utils } from 'app/core/utils';
import { ToastService } from 'app/services/toast.service';
import { from, Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { routesConfig } from 'config/routesConfig';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private toastService: ToastService,
    private router: Router
  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return from(this.authService.getAuthToken()).pipe(
      switchMap((authToken) => {
          let nextRequest = authToken ? request.clone({
            headers: request.headers.set('X-Auth-Token', authToken),
          }) : request;
          nextRequest = nextRequest.clone({
            headers: nextRequest.headers.set('horgaszapp', 'true'),
          });
          return next.handle(nextRequest).pipe(
            catchError((response) => {
              if (response instanceof HttpErrorResponse) {
                const status = response.error?.status ? response.error.status : response.status;
                if (status === Utils.HTTP_ERROR_CODE_401_UNATHORIZED) {
                  this.toastService.errorMessageAutoClose('A megadott email cím, vagy jelszó helytelen.');
                } else if (status === Utils.HTTP_ERROR_CODE_403_ACCESS_DENIED) {
                  if (!response.url.endsWith('/currentprincipal') && !response.url.endsWith('login?logout')) {
                    this.router.navigateByUrl(routesConfig.bejelentkezes, { replaceUrl: true }).then(() => {
                        this.toastService.errorMessageAutoClose('Lejárt munkamenet, kérlek jelentkezz be újra!');
                        this.authService.logout();
                      });
                  }
                } else if (status === Utils.HTTP_ERROR_CODE_502_MAINTENANCE) {
                  this.toastService.errorMessageAutoClose('Karbantartás miatt a kiszolgáló nem elérhető, kérjük próbálja meg újra pár perc múlva.');
                } else if (status === Utils.HTTP_ERROR_CODE_500_INTERNAL_SERVER_ERROR) {
                  //this.toastService.errorMessageAutoClose('Hiba történt a végrehajtás során.', 10000);
                } else {
                  if (!response.url.endsWith('/logout')) {
                    this.toastService.errorMessageAutoClose('A kiszolgáló nem elérhető, kérjük próbálja meg újra pár perc múlva.');
                  }
                }
              }
              return throwError(response);
            })
          );
        }
      )
    );
  }
}
