import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { AuthService } from 'app/auth/auth.service';
import { FogasiNaploService } from 'app/services/fogasi-naplo.service';
import { LastModifiedType } from './last-modified-type';

@Component({
  selector: 'mohosz-last-modified-refresher',
  templateUrl: './last-modified-refresher.component.html',
  styleUrls: ['./last-modified-refresher.component.scss'],
})
export class LastModifiedRefresherComponent extends OnDestroyMixin implements OnInit, OnChanges {

  @Input() lastModifiedType: LastModifiedType;
  @Output() refresh = new EventEmitter<boolean>();

  refreshedDate: Date;

  private felhasznaloRefreshedDate: Date;
  private fogasiNaploRefreshedDate: Date;

  constructor(
    private authService: AuthService,
    private fogasiNaploService: FogasiNaploService
  ) {
    super();
    this.authService.authentication.pipe(untilComponentDestroyed(this)).subscribe(auth => {
      if(auth){
        this.felhasznaloRefreshedDate = new Date();
        this.updateRefreshedDate();
      }
    });

    this.fogasiNaploService.refreshedDate.pipe(untilComponentDestroyed(this)).subscribe(date => {
      this.fogasiNaploRefreshedDate = date;
      this.updateRefreshedDate();
    });
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.lastModifiedType){
      this.updateRefreshedDate();
    }
  }

  onRefreshClick() {
    switch (this.lastModifiedType) {
      case LastModifiedType.FELHASZNALO: this.authService.refreshAuthenticationFromServer(); break;
      case LastModifiedType.FOGASINAPLO: this.refresh.emit(true);break;
    }
  }

  private updateRefreshedDate(){

    if(this.felhasznaloRefreshedDate && this.lastModifiedType === LastModifiedType.FELHASZNALO){
      this.refreshedDate = this.felhasznaloRefreshedDate;
    }

    if(this.fogasiNaploRefreshedDate && this.lastModifiedType === LastModifiedType.FOGASINAPLO){
      this.refreshedDate = this.fogasiNaploRefreshedDate;
    }
  }
}
