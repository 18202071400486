import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  //eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[onClickScroll]'
})
export class OnClickScrollDirective {

  constructor(private el: ElementRef) {}

  @HostListener('click', ['$event.target'])

  onClick() {
    this.el?.nativeElement?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
  }
}
