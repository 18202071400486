import { Component, Input, OnInit } from '@angular/core';
import {
  NativeSettings,
  AndroidSettings,
  IOSSettings,
} from 'capacitor-native-settings';

@Component({
  selector: 'mohosz-no-content',
  templateUrl: './no-content.component.html',
  styleUrls: ['./no-content.component.scss'],
})
export class NoContentComponent implements OnInit {
  @Input() noContentText =
    'Nincs megjeleníthető adat, vagy nem engedélyezte a helymeghatározást!';
  @Input() isLoading = true;

  constructor() {}

  async onSettingRedirectClick() {
    console.log('onSettingRedirectClick started');
    const settingResult = await NativeSettings.open({
      optionAndroid: AndroidSettings.ApplicationDetails,
      optionIOS: IOSSettings.App,
    });
    console.log('onSettingRedirectClick finished', settingResult);
  }

  ngOnInit() {}
}
