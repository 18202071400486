/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AllamiJegy } from './allamiJegy';
import { Base64Content } from './base64Content';
import { FogasiNaplo } from './fogasiNaplo';
import { HorgaszkartyaPreviewData } from './horgaszkartyaPreviewData';
import { MobilAppVizsgaOsszesito } from './mobilAppVizsgaOsszesito';
import { TeruletiJegy } from './teruletiJegy';
import { VersenyzoiEngedely } from './versenyzoiEngedely';

export interface MobilAppFelhasznalo { 
    enaploEngedelyezett?: boolean;
    ervenyesAllamiJegy?: AllamiJegy;
    ervenyesFogasiNaplo?: FogasiNaplo;
    ervenyesTeruletiJegy?: Array<TeruletiJegy>;
    ervenyesVersenyzoiEngedely?: VersenyzoiEngedely;
    horgaszkartyaPreviewData?: HorgaszkartyaPreviewData;
    keresztnev?: string;
    loginId?: string;
    loginIdType?: MobilAppFelhasznalo.LoginIdTypeEnum;
    profilkep?: Base64Content;
    szemelyId?: number;
    vezeteknev?: string;
    vizsgaOsszesito?: MobilAppVizsgaOsszesito;
}
export namespace MobilAppFelhasznalo {
    export type LoginIdTypeEnum = 'ALTALANOS' | 'EMAIL' | 'HITELESITETT_EMAIL';
    export const LoginIdTypeEnum = {
        ALTALANOS: 'ALTALANOS' as LoginIdTypeEnum,
        EMAIL: 'EMAIL' as LoginIdTypeEnum,
        HITELESITETTEMAIL: 'HITELESITETT_EMAIL' as LoginIdTypeEnum
    };
}