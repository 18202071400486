import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { GPSKoordinata, KedvencHorgaszhely } from 'api';
import { KedvencHorgaszhelyService } from 'app/kedvenc-horgaszhelyek/kedvenc-horgaszhely.service';
import { LifeCycleService } from 'app/services/lifecycle.service';
import { KedvencHorgaszhelyTerkepComponent } from 'app/terkep/leaflet/kedvenc-horgaszhely-terkep/kedvenc-horgaszhely-terkep.component';
import { routesConfig } from 'config/routesConfig';
import { ConnectionStateService } from 'app/services/connection-state.service';


@Component({
  selector: 'mohosz-terkep-nezet',
  templateUrl: './terkep-nezet.page.html',
  styleUrls: ['./terkep-nezet.page.scss'],
  providers: [LifeCycleService]
})
export class TerkepNezetPage extends OnDestroyMixin implements OnInit {
  @ViewChild('terkep') terkepComponent: KedvencHorgaszhelyTerkepComponent;

  isLoading = true;

  kedvencHorgaszHelyList: Array<KedvencHorgaszhely>;
  kivalasztottKedvencHorgaszhely: KedvencHorgaszhely;
  poiPopupIsOpen = false;
  locationPos: GPSKoordinata;

  constructor(
    private connectionStateService: ConnectionStateService,
    private kedvencHorgaszhelyService: KedvencHorgaszhelyService,
    private router: Router,
    private lifeCycleService: LifeCycleService
  ){
    super();
  }

  ngOnInit() {
    this.kedvencHorgaszhelyService.bundleLoading.pipe(untilComponentDestroyed(this)).subscribe(result => {
      this.isLoading = result;
    });
    this.kedvencHorgaszhelyService.kedvencHorgaszhelyList.pipe(untilComponentDestroyed(this)).subscribe(result => {
      this.isLoading = false;
      this.locationPos = this.kedvencHorgaszhelyService.locationPos;
      this.kedvencHorgaszHelyList = result;
    });
    this.kedvencHorgaszhelyService.kivalasztottHely.pipe(untilComponentDestroyed(this)).subscribe(kivalasztottHely => {
      this.kivalasztottKedvencHorgaszhely = kivalasztottHely;
      this.poiPopupIsOpen = !!this.kivalasztottKedvencHorgaszhely;
    });
    this.lifeCycleService.didEnter.pipe(untilComponentDestroyed(this)).subscribe(() => {
      this.onViewEnter();
    });
  }

  ionViewDidEnter(){
    this.lifeCycleService.propagateDidEnter();
  }

  onViewEnter() {
    if (this.kivalasztottKedvencHorgaszhely && this.kedvencHorgaszhelyService.requiredMoveToKivalsztottHely) {
      this.terkepComponent?.moveToTarget(this.kivalasztottKedvencHorgaszhely);
      this.kedvencHorgaszhelyService.moveToKivalasztottHelyDone();
    }
  }

  onAddKedvencHorgaszhelyClick() {
    if(this.connectionStateService.isOnline()){
      this.kedvencHorgaszhelyService.setKivalasztottHely(undefined);
      this.router.navigateByUrl(`/${routesConfig.tabs}/${routesConfig.kedvencHorgaszhelyek}/${routesConfig.szerkesztes}`);
    }
  }

  onMarkerSelectionChanged(kedvencHorgaszhely: KedvencHorgaszhely) {
    this.kedvencHorgaszhelyService.setKivalasztottHely(kedvencHorgaszhely);
  }
}
