import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';
import { GPSKoordinata, VedettVizterulet } from 'api';
import { Utils } from 'app/core/utils';
import { LifeCycleService } from 'app/services/lifecycle.service';
import { VedettVizteruletTerkepHelper } from 'app/terkep/leaflet/vedett-vizterulet-terkep/vedett-vizterulet-terkep-helper';
import { PoiMarkerDataVedettVizterulet } from 'app/terkep/shared/poi-marker-data-vedett-vizterulet';
import { TerkepConstants } from 'app/terkep/shared/terkep-constants';
import { TerkepHelper } from 'app/terkep/shared/terkep-helper';
import { UiVedettVizterulet } from 'app/vedett-vizterulet/ui-vedett.vizterulet';
import * as Leaflet from 'leaflet';
import { Observable, ReplaySubject } from 'rxjs';

@Component({
  selector: 'horgasz-vedett-vizterulet-terkep',
  templateUrl: './vedett-vizterulet-terkep.component.html',
  styleUrls: ['./vedett-vizterulet-terkep.component.scss'],
})
export class VedettVizteruletTerkepComponent
  extends OnDestroyMixin
  implements OnInit, OnChanges
{
  @ViewChild('map', { static: true }) mapElement: HTMLElement;

  @Input() locationPos: GPSKoordinata;
  @Input() vedettVizteruletList: Array<UiVedettVizterulet> = [];
  @Input() targetGPSKoordinata: GPSKoordinata;
  @Output() mapReady: EventEmitter<boolean> = new EventEmitter();
  @Output() mapZoomEnd: EventEmitter<Leaflet.LeafletEvent> = new EventEmitter();
  @Output() mapMoveEnd: EventEmitter<Leaflet.LeafletEvent> = new EventEmitter();

  mapOptions: Leaflet.MapOptions = TerkepConstants.getDefaultOptions();
  map: Leaflet.Map;
  locationMarker: Leaflet.Marker;
  kozelbenCircleLayer: Leaflet.Layer;
  markerLayerList: Array<Leaflet.Marker> = [];
  clusterGroup: Leaflet.MarkerClusterGroup;
  clusterGroupOptions: Leaflet.MarkerClusterGroupOptions =
    TerkepHelper.getHorgaszturizmusMarkerClusterOptions(
      TerkepConstants.DEFAULT_MAP_ZOOM
    );
  displayLayerList: Array<Leaflet.Layer> = [];
  drawnItems: Leaflet.FeatureGroup = Leaflet.featureGroup();
  drawOptions: Leaflet.Control.DrawConstructorOptions;
  locationInitCompleted: boolean;
  selectedMarker: PoiMarkerDataVedettVizterulet;

  private simpleChangesSubject = new ReplaySubject<SimpleChanges>();
  private simpleChangesObservable: Observable<SimpleChanges>;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private lifeCycleService: LifeCycleService
  ) {
    super();
    this.simpleChangesObservable = this.simpleChangesSubject.asObservable();
    this.drawOptions = {
      draw: {
        marker: false,
        circle: false,
        rectangle: false,
        circlemarker: false,
        polygon: false,
        polyline: false,
      },
      edit: {
        edit: false,
        remove: false,
        featureGroup: this.drawnItems,
      },
    };
  }

  ngOnInit(): void {
    this.lifeCycleService.didEnter
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        if (this.map) {
          TerkepHelper.invalidateSize(this.map);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.simpleChangesSubject.next(changes);
  }

  onMapReady(map: Leaflet.Map) {
    this.map = map;
    this.mapReady.emit(true);

    //start processing changes
    this.simpleChangesObservable
      .pipe(untilComponentDestroyed(this))
      .subscribe((changes) => {
        if (changes.locationPos) {
          this.refreshLocationLayer();
        }
        if (changes.vedettVizteruletList) {
          this.updateMapPoiList();
        }
        if (changes.targetGPSKoordinata) {
          this.moveToTarget();
        }
        TerkepHelper.invalidateSize(this.map);
      });
  }

  onClosePoiPopupClick(): void {
    this.clearMarkerSelection();
  }

  onMapZoomEnd(event: Leaflet.LeafletEvent) {
    this.mapZoomEnd.emit(event);
  }

  onMapMoveEnd(event: Leaflet.LeafletEvent) {
    this.mapMoveEnd.emit(event);
  }

  markerClusterReady(markerCluster: Leaflet.MarkerClusterGroup) {
    this.clusterGroup = markerCluster;
  }

  private moveToTarget() {
    if (this.targetGPSKoordinata) {
      const targetMarker = this.markerLayerList.find(
        (m) =>
          JSON.stringify(m.getLatLng()) ===
          JSON.stringify(
            TerkepHelper.koordinataToLatLng(this.targetGPSKoordinata)
          )
      );
      const terulet = this.vedettVizteruletList?.find(
        (t) =>
          JSON.stringify(t.markerKoordinata) ===
          JSON.stringify(this.targetGPSKoordinata)
      );
      if (targetMarker && terulet) {
        const targetBounds = new Leaflet.LatLngBounds(
          TerkepHelper.koordinataToLatLng(terulet.koordinatak[0]),
          TerkepHelper.koordinataToLatLng(terulet.koordinatak[0])
        );
        terulet.koordinatak.forEach((koordinata) =>
          targetBounds.extend(TerkepHelper.koordinataToLatLng(koordinata))
        );
        if (this.map && targetBounds) {
          this.map.fitBounds(targetBounds, { animate: false });
          this.clusterGroup.zoomToShowLayer(targetMarker);
          this.openPopup(targetMarker, terulet);
        }
      }
    }
  }

  private updateMapPoiList() {
    this.markerLayerList = [];
    this.drawnItems.clearLayers();
    this.displayLayerList.forEach((layer) => this.map.removeLayer(layer));
    this.vedettVizteruletList?.forEach((terulet) => {
      const layer = this.createLayerFromKoordiata(
        terulet.koordinatak,
        terulet.tipus,
        false
      );
      this.displayLayerList.push(layer);
      this.map.addLayer(layer);
      this.addMarkerToTerulet(terulet, layer);
    });
    this.changeDetectorRef.detectChanges();
  }

  private addMarkerToTerulet(
    terulet: UiVedettVizterulet,
    layer: Leaflet.Polygon
  ) {
    const marker = new Leaflet.Marker(
      TerkepHelper.koordinataToLatLng(
        terulet.markerKoordinata ?? terulet.koordinatak[0]
      ),
      {
        icon: new Leaflet.Icon({
          iconUrl:
            VedettVizteruletTerkepHelper.getMarkerIcon(terulet.tipus) ??
            TerkepConstants.VIZTERULET_POI,
          iconAnchor: [22, 63], // point of the icon which will correspond to marker's location
          popupAnchor: [0, -63], // point from which the popup should open relative to the iconAnchor
        }),
        title: terulet?.megnevezes,
      }
    ).on('click', () => {
      // markerre kattintás
      this.openPopup(marker, terulet);
    });
    // területre kattintás
    layer.addEventListener('click', () => {
      this.openPopup(marker, terulet);
    });
    this.markerLayerList.push(marker);
  }

  private openPopup(marker: Leaflet.Marker, terulet: UiVedettVizterulet) {
    this.clearMarkerSelection();
    this.selectedMarker = {
      poi: terulet,
      marker,
    };
    this.changeDetectorRef.detectChanges();
  }

  private clearMarkerSelection() {
    if (this.selectedMarker) {
      this.selectedMarker = undefined;
      this.changeDetectorRef.detectChanges();
    }
  }

  private createLayerFromKoordiata(
    koordinatak: Array<GPSKoordinata>,
    teruletTipus: VedettVizterulet.TipusEnum,
    editable = true
  ): Leaflet.Polygon {
    return Leaflet.polygon(
      TerkepHelper.koordinataListToLatLng(koordinatak),
      editable
        ? VedettVizteruletTerkepHelper.getDrawLayerOptions()
        : VedettVizteruletTerkepHelper.getDisplayLayerOptions(teruletTipus)
    );
  }

  private refreshLocationLayer() {
    if (Utils.hasValue(this.locationPos)) {
      // lokáció marker
      this.locationMarker?.removeFrom(this.map);
      this.locationMarker = TerkepHelper.createLocationMarker(this.locationPos);
      this.locationMarker.addTo(this.map);

      this.kozelbenCircleLayer?.removeFrom(this.map);
      this.kozelbenCircleLayer = Leaflet.circle(
        TerkepHelper.koordinataToLatLng(this.locationPos),
        TerkepConstants.DEFAULT_LAYER_RADIUS
      );
      this.kozelbenCircleLayer.addTo(this.map);
      if (!this.locationInitCompleted) {
        this.map.setView(
          this.locationMarker.getLatLng(),
          TerkepConstants.ZOOM_LEVEL_KOZELBEN
        );
        this.locationInitCompleted = true;
      }
    }
  }
}
