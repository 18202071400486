import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FogasiNaploPageModule } from '../fogasi-naplo/fogasi-naplo.module';
import { HorgaszEngedelyekPageModule } from '../horgasz-engedelyek/horgasz-engedelyek.module';
import { JegyVasarlasPageModule } from '../jegyvasarlas/jegyvasarlas.module';
import { KedvencHorgaszhelyekPageModule } from '../kedvenc-horgaszhelyek/kedvenc-horgaszhelyek.module';
import { MenuPageModule } from '../menu/menu.module';
import { PostafiokPageModule } from '../postafiok/postafiok.module';
import { VedettVizteruletPageModule } from '../vedett-vizterulet/vedett-vizterulet.module';

import { FogasaimPageModule } from 'app/fogasaim/fogasaim.module';
import { FogasiNaploGuard } from 'app/fogasi-naplo/fogasi-naplo.guard';
import { routesConfig } from 'config/routesConfig';
import { TabsPage } from './tabs.page';

const routes: Routes = [
  {
    path: '',
    component: TabsPage,
    children: [
      { path: routesConfig.menu, loadChildren: () => MenuPageModule },
      { path: routesConfig.horgaszEngedelyek, loadChildren: () => HorgaszEngedelyekPageModule },
      { path: routesConfig.teruletiJegyVasarlas, loadChildren: () => JegyVasarlasPageModule },
      { path: routesConfig.korlatozottTeruletek, loadChildren: () => VedettVizteruletPageModule },
      { path: routesConfig.fogasiNaplo, loadChildren: () => FogasiNaploPageModule, canActivate: [FogasiNaploGuard] },
      { path: routesConfig.kedvencHorgaszhelyek, loadChildren: () => KedvencHorgaszhelyekPageModule },
      { path: routesConfig.postafiok, loadChildren: () => PostafiokPageModule },
      { path: routesConfig.fogasaim, loadChildren: () => FogasaimPageModule },
      { path: '**', redirectTo: routesConfig.menu
      }
    ]
  },
  { path: '**', redirectTo: routesConfig.menu }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TabsPageRoutingModule {}
