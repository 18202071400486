/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface VizsgaElozmeny { 
    availableCommands?: Array<VizsgaElozmeny.AvailableCommandsEnum>;
    idopont?: Date;
    statusz?: VizsgaElozmeny.StatuszEnum;
    tipus?: VizsgaElozmeny.TipusEnum;
    tuhir?: boolean;
    vizsgaId?: number;
    vizsgaSzam?: string;
    vizsgabizottsagNev?: string;
    vizsgabizottsagVizsgaId?: number;
    vizsgahely?: string;
}
export namespace VizsgaElozmeny {
    export type AvailableCommandsEnum = 'TORTENET';
    export const AvailableCommandsEnum = {
        TORTENET: 'TORTENET' as AvailableCommandsEnum
    };
    export type StatuszEnum = 'JELENTKEZETT' | 'FOLYAMATBAN' | 'KIZART' | 'SIKERES' | 'SIKERTELEN' | 'NEM_JELENT_MEG' | 'MEGSZAKITOTT' | 'MEGHIUSULT';
    export const StatuszEnum = {
        JELENTKEZETT: 'JELENTKEZETT' as StatuszEnum,
        FOLYAMATBAN: 'FOLYAMATBAN' as StatuszEnum,
        KIZART: 'KIZART' as StatuszEnum,
        SIKERES: 'SIKERES' as StatuszEnum,
        SIKERTELEN: 'SIKERTELEN' as StatuszEnum,
        NEMJELENTMEG: 'NEM_JELENT_MEG' as StatuszEnum,
        MEGSZAKITOTT: 'MEGSZAKITOTT' as StatuszEnum,
        MEGHIUSULT: 'MEGHIUSULT' as StatuszEnum
    };
    export type TipusEnum = 'TURISTA' | 'ALLAMI';
    export const TipusEnum = {
        TURISTA: 'TURISTA' as TipusEnum,
        ALLAMI: 'ALLAMI' as TipusEnum
    };
}