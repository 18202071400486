<div class="map-container">
  <ng-container *ngIf="kedvencHorgaszhelyList">
    <div leaflet id="kedvencmap"
         [leafletOptions]="mapOptions"
         [leafletMarkerCluster]="markerLayerList"
         (leafletMapReady)="onMapReady($event)"
         (leafletClick)="onMapClick($event)">
    </div>
    <div class="poi-popup-container" *ngIf="selectedMarker">
      <horgasz-kedvenc-horgaszhely-poi-card
        [poi]="selectedMarker.poi"
        (closePoiPopup)="onClosePoiPopupClick()">
      </horgasz-kedvenc-horgaszhely-poi-card>
    </div>
  </ng-container>
</div>
