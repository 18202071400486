import { Pipe, PipeTransform } from '@angular/core';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Halfaj } from 'api';
import { TorzsadatProviderService } from 'app/services/torzsadat-provider.service';

@Pipe({
  name: 'halfaj',
  standalone: true
})
export class HalfajPipe extends OnDestroyMixin implements PipeTransform {

  private halfajList: Array<Halfaj>;

  constructor(
    private torzsadatProviderService: TorzsadatProviderService,
  ) {
    super();
    this.torzsadatProviderService.halfajList.pipe(untilComponentDestroyed(this)).subscribe(
      result => this.halfajList = result
    );
  }

  transform(halfajId: number): string | number {
    return this.halfajList.find(halfaj => halfaj.id === halfajId)?.nev ?? halfajId;
  }

}
