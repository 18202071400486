import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { QRCodeModule } from 'angularx-qrcode';
import { HorgaszkartyaRoutingModule } from 'app/horgasz-engedelyek/horgaszkartya/horgaszkartya-routing.module';
import { HorgaszkartyaPreviewComponent } from './horgaszkartya-preview/horgaszkartya-preview.component';
import { HorgaszkartyaPage } from './horgaszkartya.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    QRCodeModule,
    HorgaszkartyaRoutingModule,
  ],
    declarations: [
      HorgaszkartyaPage,
      HorgaszkartyaPreviewComponent
    ]
})
export class HorgaszkartyaPageModule {}
