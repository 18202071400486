import { Injectable } from '@angular/core';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Halfaj, Halmatrix, HorgasztatasSzolgaltatasTorzsadat, MobilAppControllerService, SelectItem, TorzsadatBundle, VizteruletTorzsadat } from 'api';
import { AuthService } from 'app/auth/auth.service';

import { Utils } from 'app/core/utils';
import { save } from 'ionicons/icons';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { TimerMutex } from '../core/timer-mutex';
import { CacheService } from './cache.service';
import { ConnectionStateService } from './connection-state.service';

const FETCH_ALLTORZSADAT = 'fetchAllTorzsadat';
const HORGASZTATAS_TORZSADAT = 'horgasztatasTorzsadat';
const VIZTERULETLIST = 'vizteruletList';
const HALFAJLIST = 'halfajList';

@Injectable({
  providedIn: 'root'
})
export class TorzsadatProviderService extends OnDestroyMixin{

  torzsadat: Observable<TorzsadatBundle>;
  halmatrixList: Observable<Array<Halmatrix>>;
  vizteruletList: Observable<Array<VizteruletTorzsadat>>;
  halfajList: Observable<Array<Halfaj>>;
  horgasztatasList?: Observable<Array<SelectItem>>;

  private timerMutex = new TimerMutex(2);

  private torzsadatSubject = new BehaviorSubject<TorzsadatBundle>(null);
  private halmatrixListSubject = new BehaviorSubject<Array<Halmatrix>>(null);
  private vizteruletListSubject = new BehaviorSubject<Array<VizteruletTorzsadat>>(null);
  private halfajListSubject = new BehaviorSubject<Array<Halfaj>>(null);
  private horgasztatasListSubject = new BehaviorSubject<Array<SelectItem>>(null);

  constructor(
    private connectionStateService: ConnectionStateService,
    private authService: AuthService,
    private mobilAppControllerService: MobilAppControllerService,
    private cacheService: CacheService
  ) {
    super();
    this.torzsadat = this.torzsadatSubject.asObservable().pipe(
      filter(torzsadat => Utils.hasValue(torzsadat))
    );
    this.halmatrixList = this.halmatrixListSubject.asObservable().pipe(
      filter(halmatrixLista => Utils.hasValue(halmatrixLista))
    );
    this.vizteruletList = this.vizteruletListSubject.asObservable().pipe(
      filter(vizteruletList => Utils.hasValue(vizteruletList))
    );
    this.halfajList = this.halfajListSubject.asObservable().pipe(
      filter(halfajList => Utils.hasValue(halfajList))
    );
    this.horgasztatasList = this.horgasztatasListSubject.asObservable().pipe(
      filter(horgasztatasList => Utils.hasValue(horgasztatasList))
    );

    this.authService.authentication.pipe(untilComponentDestroyed(this)).subscribe(auth => {
      if (auth.isAuthenticated()){
        this.refreshTorzsadat();
      }
    });

    this.connectionStateService.onlineState.pipe(untilComponentDestroyed(this)).subscribe(state => {
      this.refreshTorzsadat();
    });
  }

  private refreshTorzsadat(){
    if(this.authService.getAuthentication().isAuthenticated()){
      this.timerMutex.runExclusive(() => {
        this.cacheService.cache<TorzsadatBundle>(this.mobilAppControllerService.fetchAllTorzsadat(), FETCH_ALLTORZSADAT)
          .subscribe({
            next: result => this.torzsadatSubject.next(result),
            error: () => {}
          });

        this.cacheService.cache<HorgasztatasSzolgaltatasTorzsadat>(this.mobilAppControllerService.horgasztatasTorzsadat(), HORGASZTATAS_TORZSADAT)
          .subscribe({
            next: result => this.halmatrixListSubject.next(result.halmatrixList),
            error: () => {}
          });

        this.cacheService.cache<Array<VizteruletTorzsadat>>(this.mobilAppControllerService.vizteruletList(), VIZTERULETLIST)
          .subscribe({
            next: result => this.vizteruletListSubject.next(result),
            error: () => {}
          });

        this.cacheService.cache<Array<Halfaj>>(this.mobilAppControllerService.halfajList(), HALFAJLIST)
          .subscribe({
            next: result => this.halfajListSubject.next(result),
            error: () => {}
          });

        // this.cacheService.cache<KedvencHorgaszhelyTorzsadat>(this.mobilAppControllerService.kedvencHorgaszhelyTorzsadat(), 'kedvencHorgaszhelyTorzsadat').toPromise()
        //   .then(result => this.horgasztatasListSubject.next(result.horgasztatasList));
        //   //.catch(error => this.toastService.httpError(error));
      });
    }
  }
}
