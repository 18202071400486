import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'horgasz-ngtpl-card',
  templateUrl: './ngtpl-card.component.html',
  styleUrls: ['../card.component.scss', './ngtpl-card.component.scss'],
})
export class NgtplCardComponent implements OnInit {

  @Input() title: string;
  @Input() contentTpl: TemplateRef<any>;

  constructor() { }

  ngOnInit() {}

}
