import { Component, OnInit } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';
import { SavePassword } from 'capacitor-ios-autofill-save-password';
import { routesConfig } from 'config/routesConfig';
import { ApiModuleConfiguration } from 'config/ApiModuleConfiguration';
import { AuthService } from './auth.service';

@Component({
  selector: 'mohosz-auth',
  templateUrl: './auth.page.html',
  styleUrls: ['./auth.page.scss'],
})
export class AuthPage extends OnDestroyMixin implements OnInit {
  email = '';
  password = '';

  hostUrl: string;

  hidePassword = true;
  submitted = false;

  constructor(private authService: AuthService) {
    super();
    this.hostUrl = ApiModuleConfiguration.default().hostUrl;
    this.authService.authentication
      .pipe(untilComponentDestroyed(this))
      .subscribe((auth) => {
        if (this.submitted && auth.getState()) {
        }
      });
  }

  ionViewWillEnter() {
    this.submitted = false;
  }

  ngOnInit() {}

  onLogin() {
    this.submitted = true;
    const email = this.email?.trim();
    const password = this.password;
    this.onSubmit().then(() => {
      this.authService
        .login(email, password, routesConfig.menu)
        .then((result) => {
          if (!result) {
            //csak hiba eseten allitjuk false ertekre
            this.submitted = false;
          }
        });
    });
  }

  async onSubmit() {
    console.log('onSubmit');
    if (Capacitor.getPlatform() === 'ios') {
      SavePassword.promptDialog({
        username: this.email,
        password: this.password,
      })
        .then(() => console.log('promptDialog success'))
        .catch((err) => console.error('promptDialog failure', err));
    }
  }
}
