import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fogasDate',
  standalone: true,
})
export class FogasDatePipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {
  }

  transform(idopont: Date | string): any {
    return this.datePipe.transform(idopont, 'yyyy MMM dd HH:mm');
  }
}
