import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { AltalanosModule } from 'app/altalanos/altalanos.module';
import { CoreModule } from 'app/core/core.module';
import { MaterialModule } from 'app/material/material.module';
import { FelszereltsegCardComponent } from 'app/shared/card/felszereltseg-card/felszereltseg-card.component';
import { FogasirekordCardComponent } from 'app/shared/card/fogasirekord-card/fogasirekord-card.component';
import { HalallomanyCardComponent } from 'app/shared/card/halallomany-card/halallomany-card.component';
import { HorgaszmodszerCardComponent } from 'app/shared/card/horgaszmodszer-card/horgaszmodszer-card.component';
import { NgtplCardComponent } from 'app/shared/card/ngtpl-card/ngtpl-card.component';
import { SliderComponent } from 'app/shared/slider/slider.component';
import { TagComponent } from 'app/shared/tag/tag.component';
import { HorgaszturizmusPoiCardComponent } from 'app/terkep/horgaszturizmus-poi-card/horgaszturizmus-poi-card.component';
import { VedettVizteruletTerkepComponent } from 'app/terkep/leaflet/vedett-vizterulet-terkep/vedett-vizterulet-terkep.component';
import { PoiPopupComponent } from 'app/terkep/poi-popup/poi-popup.component';
import { TerkepModule } from 'app/terkep/terkep.module';
import { VizteruletKapcsolasPopupCardComponent } from 'app/terkep/vizterulet-kapcsolas-popup-card/vizterulet-kapcsolas-popup-card.component';
import { KedvencHorgaszhelyPoiCardComponent } from '../terkep/kedvenc-horgaszhely-poi-card/kedvenc-horgaszhely-poi-card.component';
import { HorgaszturizmusTerkepComponent } from '../terkep/leaflet/horgaszturizmus-terkep/horgaszturizmus-terkep.component';
import { KedvencHorgaszhelyTerkepEditorComponent } from '../terkep/leaflet/kedvenc-horgaszhely-terkep-editor/kedvenc-horgaszhely-terkep-editor.component';
import { KedvencHorgaszhelyTerkepComponent } from '../terkep/leaflet/kedvenc-horgaszhely-terkep/kedvenc-horgaszhely-terkep.component';
import { RegPontHeaderComponent } from '../terkep/leaflet/regpont-terkep/header/reg-pont-header.component';
import { RegPontInfoPanelComponent } from '../terkep/leaflet/regpont-terkep/info-panel/reg-pont-info-panel.component';
import { RegpontTerkepComponent } from '../terkep/leaflet/regpont-terkep/regpont-terkep.component';
import { VedettVizteruletTerkepPopupComponent } from '../terkep/leaflet/vedett-vizterulet-terkep/vedett-vizterulet-terkep-popup/vedett-vizterulet-terkep-popup.component';
import { ImageResourceContentComponent } from './image-resource-content/image-resource-content.component';
import { LastModifiedRefresherComponent } from './last-modified-refresher/last-modified-refresher.component';
import { OnClickScrollDirective } from './on-click-scroll.directive';
import { SimpleHeaderComponent } from './simple-header/simple-header.component';


@NgModule({
  declarations: [
    SliderComponent,
    TagComponent,
    HorgaszturizmusTerkepComponent,
    HorgaszturizmusPoiCardComponent,
    VedettVizteruletTerkepComponent,
    KedvencHorgaszhelyTerkepComponent,
    KedvencHorgaszhelyTerkepEditorComponent,
    RegPontHeaderComponent,
    RegPontInfoPanelComponent,
    RegpontTerkepComponent,
    HalallomanyCardComponent,
    HalallomanyCardComponent,
    FogasirekordCardComponent,
    FelszereltsegCardComponent,
    HorgaszmodszerCardComponent,
    NgtplCardComponent,
    VedettVizteruletTerkepPopupComponent,
    KedvencHorgaszhelyPoiCardComponent,
    VizteruletKapcsolasPopupCardComponent,
    ImageResourceContentComponent,
    SimpleHeaderComponent,
    PoiPopupComponent,
    LastModifiedRefresherComponent,
    OnClickScrollDirective
  ],
  imports: [
    CommonModule,
    IonicModule,
    CoreModule,
    AltalanosModule,
    ReactiveFormsModule,
    TerkepModule,
    RouterLink,
    NgSelectModule,
    MaterialModule,
  ],
  exports: [
    ReactiveFormsModule,
    TerkepModule,
    SliderComponent,
    TagComponent,
    HorgaszturizmusTerkepComponent,
    HorgaszturizmusPoiCardComponent,
    VedettVizteruletTerkepComponent,
    KedvencHorgaszhelyTerkepComponent,
    KedvencHorgaszhelyTerkepEditorComponent,
    FelszereltsegCardComponent,
    HorgaszmodszerCardComponent,
    HalallomanyCardComponent,
    FogasirekordCardComponent,
    NgtplCardComponent,
    VedettVizteruletTerkepPopupComponent,
    KedvencHorgaszhelyPoiCardComponent,
    VizteruletKapcsolasPopupCardComponent,
    ImageResourceContentComponent,
    RegpontTerkepComponent,
    SimpleHeaderComponent,
    PoiPopupComponent,
    LastModifiedRefresherComponent,
    OnClickScrollDirective
  ]
})
export class SharedModule { }
