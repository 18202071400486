<ion-header>
  <ion-toolbar color="secondary">
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/tabs/fogasaim"></ion-back-button>
    </ion-buttons>
    <ion-title>{{ title }}</ion-title>
    <ion-buttons slot="end" class="ion-margin-top">
      <horgasz-header-notification></horgasz-header-notification>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="moh-bg-light" *ngIf="form">
  <ion-grid>
    <ion-row>
      <ion-col size-md="6" offset-md="3" [style.background]="'white'">

        <form [formGroup]="form">

          <mat-error class="align-center">
            <span *ngIf="form.errors?.server">{{form.errors.server.message}}</span>
          </mat-error>

          <div>
            <horgasz-file-uploader
              #uploader
              [configuration]="fileUploaderConfig"
              [disabled]="!!form.controls.kepId.value"
              (uploadResult)="onSuccessUpload($event)">
            </horgasz-file-uploader>
          </div>

          <ng-container *ngIf="form.controls.kepId.value">
            <div class="photo-container">
              <ion-fab-button size="small" (click)="onEditPhotoClick()" class="fab-kep-szerkesztes">
                <ion-icon size="small" name="pencil"></ion-icon>
              </ion-fab-button>
              <ion-fab-button size="small" (click)="onDeletePhotoClick()" class="fab-kep-torles">
                <ion-icon size="small" name="trash"></ion-icon>
              </ion-fab-button>
              <div *ngIf="base64Content">
                <horgasz-base64-content [content]="base64Content"></horgasz-base64-content>
              </div>
            </div>
          </ng-container>

          <mat-error class="align-center">
            <horgasz-form-field-error-content [control]="form.controls.kepId"></horgasz-form-field-error-content>
          </mat-error>

          <ion-row class="inner-row">
            <ion-col style="width: 100%;">
              <mat-form-field class="mandatory-field">
                <input matInput type="text" name="fogasDatum" formControlName="fogasDatum"
                       placeholder="Fogás napja"
                       [matDatepicker]="pickerFogas">
                <mat-datepicker-toggle matSuffix [for]="pickerFogas">
                  <mat-icon matDatepickerToggleIcon svgIcon="moh-icon-calendar"></mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #pickerFogas></mat-datepicker>
                <mat-error>
                  <horgasz-form-field-error-content [control]="form.controls.fogasDatum"></horgasz-form-field-error-content>
                </mat-error>
              </mat-form-field>
            </ion-col>
            <ion-col style="max-width: 100px;">
              <mat-form-field class="mandatory-field">
                <input matInput type="number" min="0" max="24" name="fogasOra" placeholder="Óra" formControlName="fogasOra">
                <mat-error>
                  <horgasz-form-field-error-content [control]="form.controls.fogasOra"></horgasz-form-field-error-content>
                </mat-error>
              </mat-form-field>
            </ion-col>
            <ion-col style="max-width: 100px;">
              <mat-form-field class="mandatory-field">
                <input matInput type="number" min="0" max="60" name="fogasPerc" placeholder="Perc" formControlName="fogasPerc">
                <mat-error>
                  <horgasz-form-field-error-content [control]="form.controls.fogasPerc"></horgasz-form-field-error-content>
                </mat-error>
              </mat-form-field>
            </ion-col>
          </ion-row>

          <ion-item lines="none" class="has-ng-select">
            <ion-label position="stacked">Vízterület</ion-label>
            <div class="ng-select-field">
              <ng-select class="moh-ng-select"
                         formControlName="vizteruletId"
                         [virtualScroll]="true"
                         [items]="vizteruletList"
              ></ng-select>
            </div>
          </ion-item>
          <ion-note slot="error" color="danger">
            <horgasz-form-field-error-content [control]="form.controls.vizteruletId"></horgasz-form-field-error-content>
          </ion-note>

          <ion-item lines="none" class="has-ng-select mandatory-field">
            <ion-label position="stacked">Hal fajtája</ion-label>
            <div class="ng-select-field">
              <ng-select class="moh-ng-select"
                         formControlName="halfajId"
                         [virtualScroll]="true"
                         [items]="halmatrixList"
              ></ng-select>
            </div>
          </ion-item>
          <ion-note slot="error" color="danger">
            <horgasz-form-field-error-content [control]="form.controls.halfajId"></horgasz-form-field-error-content>
          </ion-note>

          <ion-item class="mandatory-field">
            <ion-label position="stacked">Súly (kg)</ion-label>
            <ion-input type="number" formControlName="suly"></ion-input>
          </ion-item>
          <ion-note slot="error" color="danger">
            <horgasz-form-field-error-content [control]="form.controls.suly"></horgasz-form-field-error-content>
          </ion-note>

          <ion-row class="inner-row">
            <ion-col>
              <ion-item class="mandatory-field">
                <ion-label position="stacked">Hossz (cm)</ion-label>
                <ion-input type="number" formControlName="hossz"></ion-input>
              </ion-item>
              <ion-note slot="error" color="danger">
                <horgasz-form-field-error-content [control]="form.controls.hossz"></horgasz-form-field-error-content>
              </ion-note>
            </ion-col>
            <ion-col>
              <ion-item class="mandatory-field">
                <ion-label position="stacked">Kerület (cm)</ion-label>
                <ion-input type="number" formControlName="kerulet"></ion-input>
              </ion-item>
              <ion-note slot="error" color="danger">
                <horgasz-form-field-error-content [control]="form.controls.kerulet"></horgasz-form-field-error-content>
              </ion-note>
            </ion-col>
          </ion-row>

          <h4>Részletek</h4>

          <ion-item lines="none" class="has-ng-select">
            <ion-label position="stacked">Fogási módszer</ion-label>
            <div class="ng-select-field">
              <ng-select class="moh-ng-select"
                         formControlName="fogasModszer"
                         [virtualScroll]="true"
                         [items]="fogasModszerList"
              ></ng-select>
            </div>
          </ion-item>
          <ion-note slot="error" color="danger">
            <horgasz-form-field-error-content [control]="form.controls.fogasModszer"></horgasz-form-field-error-content>
          </ion-note>

          <ion-item>
            <ion-label position="stacked">Csali fajtája</ion-label>
            <ion-input formControlName="csali"></ion-input>
          </ion-item>
          <ion-note slot="error" color="danger">
            <horgasz-form-field-error-content [control]="form.controls.csali"></horgasz-form-field-error-content>
          </ion-note>

          <ion-row class="inner-row">
            <ion-col>
              <ion-item>
                <ion-label position="stacked">Fárasztási idő (perc)</ion-label>
                <ion-input type="number" formControlName="farasztasPerc"></ion-input>
              </ion-item>
              <ion-note slot="error" color="danger">
                <horgasz-form-field-error-content [control]="form.controls.farasztasPerc"></horgasz-form-field-error-content>
              </ion-note>
            </ion-col>
            <ion-col>
              <ion-item>
                <ion-label position="stacked">Etetőanyag</ion-label>
                <ion-input formControlName="etetoanyag"></ion-input>
              </ion-item>
              <ion-note slot="error" color="danger">
                <horgasz-form-field-error-content [control]="form.controls.etetoanyag"></horgasz-form-field-error-content>
              </ion-note>
            </ion-col>
          </ion-row>

          <div class="label">Visszaengedtem?</div>
          <div class="checkbox-container" [class.active]="form.get('visszaengedett')?.value" (click)="onVisszaengedettChange()">
            <ion-checkbox formControlName="visszaengedett" #checkbox></ion-checkbox>
            <div><strong>Igen</strong></div>
          </div>
          <ion-note slot="error" color="danger">
            <horgasz-form-field-error-content [control]="form.controls.visszaengedett"></horgasz-form-field-error-content>
          </ion-note>

          <div class="label">Fogás láthatósága</div>
          <ion-radio-group formControlName="lathatosag" #buttonGroup>
            <div (click)="buttonGroup.value = item.id" class="radio-button-container" [class.active]="buttonGroup.value === item.id" *ngFor="let item of lathatosagList">
              <ion-radio labelPlacement="start" [value]="item.id">
              </ion-radio>
              <div><strong>{{item.nev}}</strong></div>
            </div>
          </ion-radio-group>
        </form>

        <div class="ion-text-center ion-margin-top">
          <ion-button fill="outline" color="danger" style="margin-right: 10px" *ngIf="flog && flog?.availableCommands.includes('TORLES')"
            (click)="onFogasTorlesClick()">
            Törlés
          </ion-button>
          <ion-button *ngIf="!flog"
            (click)="onFogasMentesClick()" [disabled]="form.invalid">
            Létrehozás
          </ion-button>
          <ion-button *ngIf="flog && flog.availableCommands.includes('SZERKESZTES')"
            (click)="onFogasMentesClick()" [disabled]="form.invalid">
            Módosítás
          </ion-button>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
