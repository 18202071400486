<ion-content class="moh-bg-light" [fullscreen]="true">

  <ion-fab slot="fixed" vertical="bottom" horizontal="end">
    <ion-fab-button size="small" (click)="onAddKedvencHorgaszhelyClick()">
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>

  <ng-container *ngIf="kedvencHorgaszhelyList?.length > 0; else noContent">
    <ion-searchbar placeholder="Keresés" show-clear-button="always" [debounce]="500" (ionChange)="handleSearchChange($event)"></ion-searchbar>

    <cdk-virtual-scroll-viewport class="viewport ion-content-scroll-host" itemSize="72" minBufferPx="720" maxBufferPx="1440">
      <ion-list lines="full" #list>
        <ion-item-sliding *cdkVirtualFor="let item of filteredKedvencHorgaszhelyList">
          <ion-item button detail="false"
                    (click)="onKedvencHorgaszhelyListItemClick(item)">
            <ion-avatar slot="start" class="icon-moh-favorite">
              <ion-icon src="assets/icon/favorite-outline.svg" role="img"></ion-icon>
            </ion-avatar>
            <ion-label>
              <h6>{{ item.elnevezes }}</h6>
              <p class="text-overflow-ellipsis line-1">
                {{ item.details.horgasztatasNev }}
              </p>
            </ion-label>
          </ion-item>

          <ion-item-options>
            <ion-item-option color="danger" (click)="onDeleteClick(item)">
              Törlés
            </ion-item-option>
          </ion-item-options>
        </ion-item-sliding>
      </ion-list>
    </cdk-virtual-scroll-viewport>
  </ng-container>

  <ng-template #noContent>
    <horgasz-no-content [isLoading]="isLoading" noContentText="Nincs kedvenc horgászhely"></horgasz-no-content>
  </ng-template>
</ion-content>
