import { NgIf } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Utils } from 'app/core/utils';

@Component({
  selector: 'horgasz-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss'],
  imports: [ NgIf ],
  standalone: true
})
export class ErrorMessageComponent implements OnInit, OnChanges {

  @Input() serverError: any;
  @Input() errorMessage: any;
  @Output() finished = new EventEmitter<boolean>();

  textErrorMessage: string;

  constructor() { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.serverError) {
      this.setTextErrorMessage(this.parseServerError());
    } else if (changes.errorMessage) {
      this.setTextErrorMessage(this.errorMessage);
    }
  }

  private parseServerError() {
    if (this.serverError instanceof HttpErrorResponse) {
      const status = Utils.serverErrorStatus(this.serverError);
      if (status === Utils.HTTP_ERROR_CODE_401_UNATHORIZED) {
        return 'A megadott email cím, vagy jelszó helytelen.';
      } else if (status === Utils.HTTP_ERROR_CODE_403_ACCESS_DENIED) {
        if (!this.serverError.url.endsWith('/currentprincipal') && !this.serverError.url.endsWith('login?logout')) {
          //this.router.navigateByUrl(routesConfig.bejelentkezes, { replaceUrl: true }).then(() => {
          //this.toastService.errorMessageAutoClose('Lejárt munkamenet, kérlek jelentkezz be újra!');
          //this.authService.logout();
          //});
        }
      } else if (status === Utils.HTTP_ERROR_CODE_502_MAINTENANCE) {
        return 'Karbantartás miatt a kiszolgáló nem elérhető, kérjük próbálja meg újra pár perc múlva.';
      } else if (status === Utils.HTTP_ERROR_CODE_500_INTERNAL_SERVER_ERROR) {
        return this.serverError.error?.message ?? 'Hiba történt a végrehajtás során.';
      } else {
        return 'A kiszolgáló nem elérhető, kérjük próbálja meg újra pár perc múlva.';
      }
    }
  }

  private setTextErrorMessage(textErrorMessage: string): void {
    if(textErrorMessage){
      this.textErrorMessage = textErrorMessage;
      setTimeout(() => {
        this.textErrorMessage = undefined;
        this.finished.emit(true);
      }, 3000);
    }
  }
}
