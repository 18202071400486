import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HorgaszatMegkezdeseModalPageModule } from 'app/fogasi-naplo/fogasok/horgaszat-megkezdese-modal/horgaszat-megkezdese-modal.module';

import { FogasokPage } from './fogasok.page';
import { FogasRogzitesModalPageModule } from './rogzites-modal/fogas-rogzites-modal.module';

const routes: Routes = [
  {
    path: '',
    component: FogasokPage,
  },
  {
    path: 'rogzites-modal', loadChildren: () => FogasRogzitesModalPageModule
  },
  {
    path: 'horgaszat-megkezdese-modal', loadChildren: () => HorgaszatMegkezdeseModalPageModule
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FogasokPageRoutingModule {}
