import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileOpener } from '@capacitor-community/file-opener';
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { MobilAppControllerService, TeruletiJegy, TeruletiJegyTermekRequest } from 'api';
import { ErvenyessegDatumPipe } from 'app/altalanos/pipes/ervenyesseg-datum-pipe';
import { Utils } from 'app/core/utils';
import { ConnectionStateEnum, ConnectionStateService } from 'app/services/connection-state.service';
import { ToastService } from 'app/services/toast.service';


@Component({
  selector: 'horgasz-teruleti-jegy-card',
  templateUrl: './teruleti-jegy-card.component.html',
  styleUrls: ['../jegyek.page.scss'],
})
export class TeruletiJegyCardComponent extends OnDestroyMixin implements OnInit {

  @Input() teruletiJegy: TeruletiJegy;
  @Output() kiegeszitoVasarlas = new EventEmitter();

  ervenyessegDatumPipe = new ErvenyessegDatumPipe();
  isLetoltesDisabled = true;

  constructor(
    private connectionStateService: ConnectionStateService,
    private mobilAppControllerService: MobilAppControllerService,
    private toastService: ToastService,
  ) {
    super();
    this.connectionStateService.state.pipe(untilComponentDestroyed(this)).subscribe(state =>
      this.isLetoltesDisabled = state !== ConnectionStateEnum.ONLINE
    );
  }

  ngOnInit() {
  }

  onHorgaszrendLetoltes() {
    if(this.connectionStateService.isOnline()){
      const teruletiJegyTermekId = this.teruletiJegy.teruletiJegyTermekId;
      const request: TeruletiJegyTermekRequest = { teruletiJegyTermekId };
      this.mobilAppControllerService.horgaszrendNyomtatas(request).toPromise()
        .then(result => {
          if(Capacitor.isNativePlatform()){
            const filename = 'horgaszrend_' + teruletiJegyTermekId + '.pdf';
            Filesystem.writeFile({ path: filename, data: result.content, directory: Directory.Data})
              .then(fwResult => FileOpener.open({filePath: fwResult.uri}))
              .catch(wfError => this.toastService.errorMessage(wfError.message));
          } else {
            Utils.openBase64Content(result);
          }
        })
        .catch(error => this.toastService.httpError(error));
    }
  }
}
