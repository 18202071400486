import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HorgaszNapRequest, VizteruletTorzsadat } from 'api';
import { Utils } from 'app/core/utils';
import { AktualisHorgaszatData } from 'app/fogasi-naplo/aktualis-horgaszat-data';
import { FogasiNaploService } from 'app/services/fogasi-naplo.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AktualisHorgaszatService {

  aktualisHorgaszatDataObservable: Observable<AktualisHorgaszatData>;
  private aktualisHorgaszatDataSubject = new BehaviorSubject<AktualisHorgaszatData>(null);
  private aktualisHorgaszatData: AktualisHorgaszatData = new AktualisHorgaszatData();

  constructor(
    private storage: Storage,
    private fogasiNaploService: FogasiNaploService,
  ) {
    this.aktualisHorgaszatDataObservable = this.aktualisHorgaszatDataSubject.asObservable()
      .pipe(filter(value => !!value));
    this.aktualisHorgaszatDataSubject.next(this.aktualisHorgaszatData);
  }

  refresh(){
    this.storage.get(this.aktualisHorgaszatKey())
      .then(storedAktualisHorgaszat  => {
        if (storedAktualisHorgaszat) {
          this.aktualisHorgaszatData.fromJson(JSON.parse(storedAktualisHorgaszat) as AktualisHorgaszatData);
        }
      })
      .finally(()=> {
        this.aktualisHorgaszatData.refresh();
        this.storage.set(this.aktualisHorgaszatKey(), this.aktualisHorgaszatData.toJson());
        this.aktualisHorgaszatDataSubject.next(this.aktualisHorgaszatData);
      });
  }

  megkezdes(vizterulet: VizteruletTorzsadat){
    this.aktualisHorgaszatData.megkezdes(vizterulet);
    this.aktualisHorgaszatDataSubject.next(this.aktualisHorgaszatData);
    this.addHorgaszNap();
    this.storage.set(this.aktualisHorgaszatKey(), this.aktualisHorgaszatData.toJson());
  }

  befejezes(){
    this.addHorgaszNap(Utils.localDateTimeNow());
    this.aktualisHorgaszatData.befejezes();
    this.aktualisHorgaszatDataSubject.next(this.aktualisHorgaszatData);
    this.storage.remove(this.aktualisHorgaszatKey());
  }

  private addHorgaszNap(befejezes?: Date){
    const fogasiNaploSorszam = this.fogasiNaploService.felhasznalo.ervenyesFogasiNaplo.sorszam;
    const request: HorgaszNapRequest = {
      fogasiNaploId: fogasiNaploSorszam,
      vizteruletId: this.aktualisHorgaszatData.selectedVizterulet.id,
      megkezdes: this.aktualisHorgaszatData.horgaszatKezdete,
      befejezes: befejezes ?? null
    };
    this.fogasiNaploService.enaploAddHorgaszNap(request)
      .subscribe({error: err => console.error(err)});
  }

  private aktualisHorgaszatKey(){
    const fogasiNaploSorszam = this.fogasiNaploService.felhasznalo.ervenyesFogasiNaplo.sorszam;
    const fnSorszam = fogasiNaploSorszam.replace(/[^a-zA-Z0-9]/g, '');
    return 'aktualishorgaszat' + fnSorszam;
  }
}
