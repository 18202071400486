<horgasz-poi-popup *ngIf="poi"
  [title]="poi.megnevezes"
  (closePoiPopup)="onCloseClick()"
  [endSectionType]="'custom'"
  iconUrl="assets/poi/icon/vizterulet-popup.svg">
  <ng-container kepContent>
    <ng-container *ngIf="poi?.bigKepId; else noImg;">
      <horgasz-base64-content [contentId]="poi.bigKepId"  type="image" borderRadius="4px"></horgasz-base64-content>
    </ng-container>
    <ng-template #noImg>
      <div class="no-image"></div>
    </ng-template>
  </ng-container>

  <ng-container middleSection>
    <div *ngIf="isLoading" class="vertical-center">
      <ion-spinner color="primary" class="ion-align-self-center"></ion-spinner>
    </div>
    <p *ngIf="!isLoading && poi?.szolgaltatasId else noContent" style="white-space: pre-wrap;" class="text-overflow-line-clamp-3">
      {{poi.altalanosLeiras}}
    </p>
    <ng-template #noContent>
      <div *ngIf="!isLoading">...</div>
    </ng-template>
  </ng-container>

  <ng-container endSection>
    <div *ngIf="poi?.szolgaltatasId" class="ion-text-center ion-padding">
      <ion-button (click)="onMegnyitasClick()" class="button-small">
        Megnyitás
      </ion-button>
      <!--
      <ion-button fill="outline" (click)="onJegyvasarlasClick()" class="button-small">
        Jegyvásárlás
      </ion-button>
      -->
    </div>
  </ng-container>
</horgasz-poi-popup>
