import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HorgaszNapokPage } from './horgasz-napok.page';
import { HorgaszatokAdottNaponModalPageModule } from './horgaszatok-adott-napon-modal/horgaszatok-adott-napon-modal.module';

const routes: Routes = [
  {
    path: '', component: HorgaszNapokPage
  },
  {
    path: 'horgaszatok-adott-napon-modal', loadChildren: () => HorgaszatokAdottNaponModalPageModule
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HorgaszNapokPageRoutingModule {}
