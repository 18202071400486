import { Component, OnInit } from '@angular/core';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { PostafiokService } from 'app/services/postafiok.service';

@Component({
  selector: 'horgasz-postafiok',
  templateUrl: './postafiok.page.html',
  styleUrls: ['./postafiok.page.scss'],
})
export class PostafiokPage extends OnDestroyMixin implements OnInit {

  kezbesitetlenUzenetekSzama: number;

  constructor(
    private postafiokService: PostafiokService
  ) {
    super();
  }

  ngOnInit() {
    this.postafiokService.kezbesitetlenUzenetekSzama.pipe(untilComponentDestroyed(this))
      .subscribe(result => this.kezbesitetlenUzenetekSzama = result);
  }

}
