/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { TilalmiIdoszak } from './tilalmiIdoszak';

export interface RogzitesiSzabaly { 
    maxNapiDarabLimit?: number;
    maxNapiSulyLimit?: number;
    napiDarabLimit?: number;
    napiDarabVedett?: boolean;
    napiSulyLimit?: number;
    napiSulyVedett?: boolean;
    sulyLimitMax?: number;
    sulyLimitMin?: number;
    tilalmiIdoszakKezdeteHonap?: number;
    tilalmiIdoszakKezdeteNap?: number;
    tilalmiIdoszakVedett?: boolean;
    tilalmiIdoszakVegeHonap?: number;
    tilalmiIdoszakVegeNap?: number;
    tilalmiIdoszakok?: Array<TilalmiIdoszak>;
}