import { VizteruletTorzsadat } from 'api';
import { Utils } from 'app/core/utils';
import * as moment from 'moment';

export class AktualisHorgaszatData {

  fogasiNaploId: string;
  selectedVizterulet: VizteruletTorzsadat;
  horgaszatKezdete: Date;

  megkezdes(fogasiNaploId: string, selectedVizterulet: VizteruletTorzsadat) {
    this.fogasiNaploId = fogasiNaploId;
    this.selectedVizterulet = selectedVizterulet;
    this.horgaszatKezdete = Utils.localDateTimeNow();
  }

  refresh(){
    if(this.horgaszatKezdete){
      const nowId = this.dateAsId(Utils.localDateTimeNow());
      const kezdeteId = this.dateAsId(this.horgaszatKezdete);
      //ha atcsuszik masik napra, akkor leallitjuk a horgaszatot
      if(nowId !== kezdeteId){
        this.befejezes();
      }
    }
  }

  befejezes(){
    this.fogasiNaploId = undefined;
    this.selectedVizterulet = undefined;
    this.horgaszatKezdete = undefined;
  }

  toJson(){
    return JSON.stringify(this);
  }

  fromJson(savedAktualisHorgaszatData: AktualisHorgaszatData){
    this.fogasiNaploId = savedAktualisHorgaszatData.fogasiNaploId;
    this.selectedVizterulet = savedAktualisHorgaszatData.selectedVizterulet;
    this.horgaszatKezdete = savedAktualisHorgaszatData.horgaszatKezdete;
  }

  private dateAsId(date: Date): string{
    return moment(date).format('YYYYMMDD');
  }
}
