import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'horgasz-poi-popup',
  templateUrl: './poi-popup.component.html',
  styleUrls: ['./poi-popup.component.scss'],
})
export class PoiPopupComponent implements OnInit {

  @Input() iconUrl: string;
  @Input() title: string;
  @Input() subTitle: string;
  @Input() endSectionType: 'edit' | 'custom' | undefined;
  @Input() editLabel: string;

  @Output() closePoiPopup: EventEmitter<any> = new EventEmitter();
  @Output() editClicked: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {}

  onCloseClick() {
    this.closePoiPopup.emit(true);
  }

  onEditClick() {
    this.editClicked.emit();
  }

}
