/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AllamiJegyArDimenzio } from './allamiJegyArDimenzio';

export interface AllamiJegy { 
    availableCommands?: Array<AllamiJegy.AvailableCommandsEnum>;
    egyesuletiTagsagKategoria?: AllamiJegy.EgyesuletiTagsagKategoriaEnum;
    elektronikus?: boolean;
    ertekesitoSzervezetId?: number;
    ervenyessegDatuma?: string;
    ervenyessegiEv: number;
    fogyatekkalElo?: boolean;
    kategoria?: AllamiJegyArDimenzio;
    sorszam: string;
    statusz?: AllamiJegy.StatuszEnum;
    sztornozasDatuma?: Date;
    tagEgyesuletId?: number;
    tipus?: AllamiJegy.TipusEnum;
    utolagosVisszavonas?: boolean;
    vasarlasDatuma?: Date;
    visszavonasDatuma?: string;
}
export namespace AllamiJegy {
    export type AvailableCommandsEnum = 'SZTORNO' | 'SZTORNO_VISSZAVON' | 'TURISTA_NYOMTATAS' | 'VISSZAVONASROLLBACK' | 'LEJARTROLLBACK';
    export const AvailableCommandsEnum = {
        SZTORNO: 'SZTORNO' as AvailableCommandsEnum,
        SZTORNOVISSZAVON: 'SZTORNO_VISSZAVON' as AvailableCommandsEnum,
        TURISTANYOMTATAS: 'TURISTA_NYOMTATAS' as AvailableCommandsEnum,
        VISSZAVONASROLLBACK: 'VISSZAVONASROLLBACK' as AvailableCommandsEnum,
        LEJARTROLLBACK: 'LEJARTROLLBACK' as AvailableCommandsEnum
    };
    export type EgyesuletiTagsagKategoriaEnum = 'IFJUSAGI' | 'FELNOTT' | 'FELNOTT_KEDVEZMENYEZETT' | 'IFJUSAGI_IHJ' | 'FELNOTT_IHJ' | 'FELNOTT_JOGFENNTARTO_IHJ' | 'MOHOSZON_KIVULI';
    export const EgyesuletiTagsagKategoriaEnum = {
        IFJUSAGI: 'IFJUSAGI' as EgyesuletiTagsagKategoriaEnum,
        FELNOTT: 'FELNOTT' as EgyesuletiTagsagKategoriaEnum,
        FELNOTTKEDVEZMENYEZETT: 'FELNOTT_KEDVEZMENYEZETT' as EgyesuletiTagsagKategoriaEnum,
        IFJUSAGIIHJ: 'IFJUSAGI_IHJ' as EgyesuletiTagsagKategoriaEnum,
        FELNOTTIHJ: 'FELNOTT_IHJ' as EgyesuletiTagsagKategoriaEnum,
        FELNOTTJOGFENNTARTOIHJ: 'FELNOTT_JOGFENNTARTO_IHJ' as EgyesuletiTagsagKategoriaEnum,
        MOHOSZONKIVULI: 'MOHOSZON_KIVULI' as EgyesuletiTagsagKategoriaEnum
    };
    export type StatuszEnum = 'ERVENYES' | 'LEJART' | 'LEJART_LEADOTT_FN' | 'UJRAFELHASZNALHATO_SZTORNOZOTT' | 'VEGLEGESEN_STORNOZOTT' | 'ERVENYES_SZTORNO_JOVAHAGYASRA_VAR' | 'ELVESZETT' | 'MEGSEMMISULT' | 'ERVENYES_MEGSEMMISULT_JOVAHAGYASRA_VAR' | 'ERVENYES_ELVESZETT_JOVAHAGYASRA_VAR' | 'LEJART_MEGSEMMISULT_JOVAHAGYASRA_VAR' | 'LEJART_ELVESZETT_JOVAHAGYASRA_VAR' | 'VISSZAVONT' | 'SZTORNOZOTT';
    export const StatuszEnum = {
        ERVENYES: 'ERVENYES' as StatuszEnum,
        LEJART: 'LEJART' as StatuszEnum,
        LEJARTLEADOTTFN: 'LEJART_LEADOTT_FN' as StatuszEnum,
        UJRAFELHASZNALHATOSZTORNOZOTT: 'UJRAFELHASZNALHATO_SZTORNOZOTT' as StatuszEnum,
        VEGLEGESENSTORNOZOTT: 'VEGLEGESEN_STORNOZOTT' as StatuszEnum,
        ERVENYESSZTORNOJOVAHAGYASRAVAR: 'ERVENYES_SZTORNO_JOVAHAGYASRA_VAR' as StatuszEnum,
        ELVESZETT: 'ELVESZETT' as StatuszEnum,
        MEGSEMMISULT: 'MEGSEMMISULT' as StatuszEnum,
        ERVENYESMEGSEMMISULTJOVAHAGYASRAVAR: 'ERVENYES_MEGSEMMISULT_JOVAHAGYASRA_VAR' as StatuszEnum,
        ERVENYESELVESZETTJOVAHAGYASRAVAR: 'ERVENYES_ELVESZETT_JOVAHAGYASRA_VAR' as StatuszEnum,
        LEJARTMEGSEMMISULTJOVAHAGYASRAVAR: 'LEJART_MEGSEMMISULT_JOVAHAGYASRA_VAR' as StatuszEnum,
        LEJARTELVESZETTJOVAHAGYASRAVAR: 'LEJART_ELVESZETT_JOVAHAGYASRA_VAR' as StatuszEnum,
        VISSZAVONT: 'VISSZAVONT' as StatuszEnum,
        SZTORNOZOTT: 'SZTORNOZOTT' as StatuszEnum
    };
    export type TipusEnum = 'TURISTA_ALLAMIJEGY' | 'ALLAMIJEGY' | 'E_TURISTA_ALLAMIJEGY' | 'E_JEGY';
    export const TipusEnum = {
        TURISTAALLAMIJEGY: 'TURISTA_ALLAMIJEGY' as TipusEnum,
        ALLAMIJEGY: 'ALLAMIJEGY' as TipusEnum,
        ETURISTAALLAMIJEGY: 'E_TURISTA_ALLAMIJEGY' as TipusEnum,
        EJEGY: 'E_JEGY' as TipusEnum
    };
}