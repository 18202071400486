import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Utils } from 'app/core/utils';
import { FileUploaderWrapper } from 'app/file-uploader/file-uplader-wrapper';
import { FileUploaderConfiguration } from 'app/file-uploader/file-uploader-configuration';
import { FileUploaderResult } from 'app/file-uploader/file-uploader-result';
import { FileUploaderService } from 'app/file-uploader/file-uploader.service';

@Component({
  selector: 'horgasz-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent extends OnDestroyMixin implements OnInit {

  @ViewChild('uploaderInput') uploadInput: ElementRef;
  @Input() configuration: FileUploaderConfiguration;
  @Input() disabled: boolean;
  @Input() dragzoneEmbeddedTemplate: TemplateRef<any>;
  @Output() uploadResult: EventEmitter<FileUploaderResult> = new EventEmitter();

  fileUploaderWrapper: FileUploaderWrapper;
  fileUploaderResult: FileUploaderResult;

  constructor(
    private fileUploaderService: FileUploaderService,
  ) {
    super();
  }

  ngOnInit() {
    if (!this.fileUploaderWrapper) {
      if (this.configuration.externalUploadEvent) {
        this.configuration.externalUploadEvent.subscribe(event => {
          if (event) {
            this.onUploadClick();
          }
        });
      }
      this.fileUploaderWrapper = this.fileUploaderService.buildFileUploaderWrapper(this.configuration);
      this.fileUploaderWrapper.result$.pipe(untilComponentDestroyed(this)).subscribe(result => {
        if (result.isSuccess()) {
          this.uploadResult.emit(result);
        }
        //5 másodperc múlva eltüntetjük a kiírást
        setTimeout(() => this.resetFileUploader(), 5000);

        this.fileUploaderResult = result;
        this.uploadInput.nativeElement.value = ''; //2x fel lehessen tolteni ugyanazt a filet
        this.uploadInput.nativeElement.disabled = false;
      });
    }
  }

  async onUploadClick() {
    this.resetFileUploader();
    const file = await this.getPhoto();
    this.fileUploaderWrapper.uploader.addToQueue([file]);
    this.fileUploaderWrapper.uploader.uploadAll();
  }

  async getPhoto() {
    const capturedPhoto = await Camera.getPhoto({
      resultType: CameraResultType.DataUrl,
      source: CameraSource.Prompt,
      quality: 100
    });
    return Utils.base64DataUrlToFile(capturedPhoto.dataUrl, 'image');
  }

  private resetFileUploader() {
    this.fileUploaderResult = undefined;
    this.uploadInput.nativeElement.disabled = false;
  }

}
