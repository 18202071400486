<ion-content class="moh-bg-light">
  <ng-container *ngIf="halfajList && vizteruletList; else adatokBetoltese">
    <div [ngClass]="fogasokByMonth || selectedVizterulet ? '' : 'vertical-center'">
      <div *ngIf="isSmallDisplay" class="fogas-rogzites-container-mobile">
        <ion-grid>
          <ion-row>
            <ion-col size-md="6" offset-md="3">
              <ng-container [ngTemplateOutlet]="fogasokActionTpl"></ng-container>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
      <ion-grid>
        <ion-row *ngIf="!isLoading">
          <ion-col size-md="6" offset-md="3">

            <mohosz-aktualis-horgaszat-card (ujVizetrulet)="onHorgaszatMegkezdeseClick()"></mohosz-aktualis-horgaszat-card>

            <ng-container *ngIf="eFogasNyilvantartas && fogasokByMonth; else noContent">
              <ng-container *ngFor="let group of fogasokByMonth">
                <h5>{{ group.pkDate | date: 'YYYY. MMMM'}}</h5>
                <ng-container *ngFor="let fogas of group.fogasok">
                  <mohosz-fogas-card
                    [fogas]="fogas"
                    [halfajList]="halfajList"
                    [vizteruletList]="vizteruletList">
                  </mohosz-fogas-card>
                </ng-container>
              </ng-container>
            </ng-container>

            <ng-template #noContent>
              <ion-card class="no-bg ion-text-center">
                <img alt="Figyelem" src="assets/icon/warning.svg" class="moh-warning"/>
                <ion-card-header>
                    <ion-card-title>Még nincs fogás feltöltve.</ion-card-title>
                </ion-card-header>
              </ion-card>
            </ng-template>
          </ion-col>

          <ion-col *ngIf="!isSmallDisplay">
            <div class="sticky-box">
              <ion-card class="felfedezes-card">
                <ion-card-header>
                  <ion-card-title>
                    <ion-card-title>Fogás rögzítés</ion-card-title>
                  </ion-card-title>
                </ion-card-header>
                <ion-card-content>
                  <ng-container [ngTemplateOutlet]="fogasokActionTpl"></ng-container>
                </ion-card-content>
              </ion-card>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>

    <ng-template #fogasokActionTpl>
      <div class="ion-text-center">
        <ion-button *ngIf="selectedVizterulet" (click)="onFogasRogzitesClick()">
          Új fogás rögzítése
        </ion-button>
        <ion-button *ngIf="!selectedVizterulet" (click)="onHorgaszatMegkezdeseClick()">
          Horgászat megkezdése
        </ion-button>
      </div>
      <mohosz-last-modified-refresher
        (refresh)="refreshList()"
        [lastModifiedType]="LastModifiedType.FOGASINAPLO">
      </mohosz-last-modified-refresher>
    </ng-template>
  </ng-container>

  <ng-template #adatokBetoltese>
    <mohosz-no-content></mohosz-no-content>
  </ng-template>
</ion-content>
