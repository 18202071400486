import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NyilatkozatokPageModule } from 'app/horgasz-engedelyek/nyilatkozatok/nyilatkozatok.module';
import { routesConfig } from 'config/routesConfig';
import { AuthGuard } from '../auth/auth.guard';
import { HorgaszEngedelyekPage } from './horgasz-engedelyek.page';
import { HorgaszkartyaPageModule } from './horgaszkartya/horgaszkartya.module';
import { HorgaszvizsgaPageModule } from 'app/horgasz-engedelyek/horgaszvizsga/horgaszvizsga.module';
import { ErtekesitesiPontokPage } from './jegyek/ertekesitesi-pontok/ertekesitesi-pontok.page';
import { JegyekPageModule } from './jegyek/jegyek.module';
import { VersenyzoiEngedelyPageModule } from './versenyzoi-engedely/versenyzoi-engedely.module';


const routes: Routes = [
  {
    path: '',
    component: HorgaszEngedelyekPage,
    children: [
      { path: routesConfig.horgaszkartya, loadChildren: () => HorgaszkartyaPageModule, canActivate: [AuthGuard] },
      { path: routesConfig.jegyek, loadChildren: () => JegyekPageModule, canActivate: [AuthGuard] },
      { path: routesConfig.nyilatkozatok, loadChildren: () => NyilatkozatokPageModule, canActivate: [AuthGuard] },
      { path: routesConfig.horgaszvizsga, loadChildren: () => HorgaszvizsgaPageModule, canActivate: [AuthGuard] },
      { path: routesConfig.versenyzoiEngedely, loadChildren: () => VersenyzoiEngedelyPageModule, canActivate: [AuthGuard] },
      { path: '', redirectTo: `${routesConfig.jegyek}`, pathMatch: 'prefix' }
    ]
  },
  {
    path: routesConfig.ertekesitesiPontok, component: ErtekesitesiPontokPage
  },
  {
    path: 'nyilatkozatok',
    loadChildren: () => import('./nyilatkozatok/nyilatkozatok.module').then( m => m.NyilatkozatokPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HorgaszEngedelyekPageRoutingModule {}
