import { Component, OnInit } from '@angular/core';
import { LifeCycleService } from 'app/services/lifecycle.service';

@Component({
  selector: 'horgasz-horgasz-engedelyek',
  templateUrl: './horgasz-engedelyek.page.html',
  styleUrls: ['./horgasz-engedelyek.page.scss'],
  providers: [LifeCycleService]
})
export class HorgaszEngedelyekPage implements OnInit {

  constructor(
    private lifeCycleService: LifeCycleService
  ) { }

  ngOnInit() {
  }

  ionViewDidEnter() {
    this.lifeCycleService.propagateDidEnter();
  }

}
