<ion-card *ngIf="halallomanyList?.length > 0 && halmatrixList">
  <ion-card-header>
    <ion-card-title>Halállomány</ion-card-title>
  </ion-card-header>
  <ion-card-content>
    <div *ngFor="let halallomany of halallomanyList; let i=index" class="halallomany"
         [class.not-displayed]="i > 3 && showMore === false">
      <ng-container *ngFor="let halmatrix of getHalmatrix(halallomany.halmatrixId)">
        <horgasz-slider
          [max]="10"
          [min]="1"
          [step]="1"
          [base64Content]="halmatrix?.kep"
          [label]="halmatrix?.nev"
          [value]="halallomany.gyakorisag"
          [disabled]="true"
        >
        </horgasz-slider>
      </ng-container>
    </div>
    <div *ngIf="showMore === false && halallomanyList.length > 4 || showMore === true && halallomanyList.length > 3">
      <a (click)="onToggleShowMoreClick()">{{showMoreToggleLabel}}</a>
    </div>
  </ion-card-content>
</ion-card>



