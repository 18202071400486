<horgasz-simple-header [title]="'Horgászrend'" [backButton]="true"></horgasz-simple-header>

<ion-content [class.segment-buttons]="displayableLeirasList?.length > 1">

  <ng-container *ngIf="!hasPDFContent">
    <ion-segment mode="md" #segment *ngIf="displayableLeirasList?.length > 1" [value]="selectedLeiras.nyelv" (ionChange)="onNyelvChange(segment)">
      <ion-segment-button *ngFor="let leiras of displayableLeirasList" [value]="leiras.nyelv">
        <ion-label>{{leiras.nyelv | horgaszrendNyelv}}</ion-label>
      </ion-segment-button>
    </ion-segment>
  </ng-container>

  <div class="horgaszrend-container">
    <ion-grid>
      <ion-row>
        <ion-col size-md="6" offset-md="3">
          <pdf-viewer *ngIf="horgaszrendPDFContent"
            [external-link-target]="'blank'"
            [render-text]="true"
            [src]="horgaszrendPDFContent"
            [original-size]="false"
            style="height: 80vh;">
          </pdf-viewer>

          <div *ngIf="!hasPDFContent && selectedLeiras" [innerHTML]="selectedLeiras.szoveg | safeHtml"></div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>

