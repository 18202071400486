import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { AktualisHorgaszatData } from 'app/fogasi-naplo/aktualis-horgaszat-data';
import { AktualisHorgaszatService } from 'app/fogasi-naplo/aktualis-horgaszat.service';

@Component({
  selector: 'mohosz-aktualis-horgaszat-card',
  templateUrl: './aktualis-horgaszat-card.component.html',
  styleUrls: ['./aktualis-horgaszat-card.component.scss'],
})
export class AktualisHorgaszatCardComponent extends OnDestroyMixin implements OnInit {

  @Output() ujVizetrulet: EventEmitter<boolean> = new EventEmitter();

  aktualisHorgaszatData: AktualisHorgaszatData;


  constructor(
    private aktualisHorgaszatService: AktualisHorgaszatService
  ) {
    super();
    this.aktualisHorgaszatService.aktualisHorgaszatDataObservable.pipe(untilComponentDestroyed(this))
      .subscribe(result => this.aktualisHorgaszatData = result);
  }

  ngOnInit() {
  }

  // ionViewDidEnter(){
  //   this.aktualisHorgaszatService.refresh();
  // }

  onBefejezes() {
    this.aktualisHorgaszatService.befejezes();
  }

  onUjVizterulet() {
    this.ujVizetrulet.emit(true);
  }
}
