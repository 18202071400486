import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CoreModule } from 'app/core/core.module';
import { FogasiNaploPageRoutingModule } from './fogasi-naplo-routing.module';
import { FogasiNaploPage } from './fogasi-naplo.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CoreModule,
    FogasiNaploPageRoutingModule
  ],
  declarations: [
    FogasiNaploPage
  ],
})
export class FogasiNaploPageModule {}
