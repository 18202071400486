import { Component, Input, OnInit } from '@angular/core';
import { Halallomany, Halmatrix } from 'api';

@Component({
  selector: 'horgasz-halallomany-card',
  templateUrl: './halallomany-card.component.html',
  styleUrls: ['../card.component.scss', './halallomany-card.component.scss'],
})
export class HalallomanyCardComponent implements OnInit {

  @Input() halallomanyList: Array<Halallomany>;
  @Input() halmatrixList: Array<Halmatrix>;

  showMore = false;
  showMoreToggleLabel = 'Mutass többet';

  constructor() { }

  ngOnInit() {}

  getHalmatrix(id: number): Array<Halmatrix> {
    return this.halmatrixList?.filter(hf => hf.id === id);
  }

  onToggleShowMoreClick() {
    this.showMore = !this.showMore;
    this.showMoreToggleLabel = this.showMore ? 'Mutass kevesebbet' : 'Mutass többet';
  }

}
