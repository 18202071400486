<ion-card *ngIf="poi" (click)="onMegnyitasClick()">
  <horgasz-tag
    [iconUrl]="poi.type | horgaszturizmusPoi: 'tag-icon-url'"
    [backgroundColor]="poi.type | horgaszturizmusPoi: 'backgound-color'">
  </horgasz-tag>

  <ng-container *ngIf="poi.kepId; else noImg;">
    <horgasz-base64-content [contentId]="poi.kepId"  type="image" borderRadius="4px"></horgasz-base64-content>
  </ng-container>

  <ng-template #noImg>
    <div class="no-image"></div>
  </ng-template>

  <ion-card-title class="text-overflow-line-clamp-2">{{poi.megnevezes}}</ion-card-title>
  <ion-card-content class="text-overflow-line-clamp-3">{{poi.altalanosLeiras}}</ion-card-content>
</ion-card>
