<div class="header">
  <horgasz-regpont-map-header (selectedRegPontChanged)="onRegpontHeaderSelection($event)"></horgasz-regpont-map-header>
</div>

<div class="map-container">
  <div leaflet id="regpontmap"
       [leafletOptions]="mapOptions"
       (leafletMapReady)="onMapReady($event)">
  </div>
</div>
