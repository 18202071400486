import { Component } from '@angular/core';
import { routesConfig } from 'config/routesConfig';

@Component({
  selector: 'horgasz-tabs',
  templateUrl: 'tabs.page.html',
  styleUrls: ['tabs.page.scss']
})
export class TabsPage {

  routesConfig = routesConfig;

  constructor() {}

}
