import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthRestControllerService,
  JogosultsagControllerService,
  MobilAppControllerService,
  SssUser,
} from 'api';
import { AuthenticationState } from 'app/auth/authentication-state';
import { ToastService } from 'app/services/toast.service';
import { routesConfig } from 'config/routesConfig';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { StorageService } from '../core/storage.service';
import { Utils } from '../core/utils';
import { ConnectionStateService } from '../services/connection-state.service';
import { Authentication } from './authentication';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authentication: Observable<Authentication>;
  private authenticationSubject: BehaviorSubject<Authentication>;
  private authToken?: string;

  constructor(
    private connectionStateService: ConnectionStateService,
    private toastService: ToastService,
    private router: Router,
    private authRestControllerService: AuthRestControllerService,
    private jogosultsagControllerService: JogosultsagControllerService,
    private mobilAppControllerService: MobilAppControllerService,
    private storageService: StorageService
  ) {
    this.authenticationSubject = new BehaviorSubject<Authentication>(new Authentication());
    this.authentication = this.authenticationSubject.asObservable().pipe(filter((auth) =>
      auth?.getState() !== AuthenticationState.INIT
    ));

    this.storageService.storage.then(() =>
      this.refreshAuthenticationFromServer()
    );
  }

  getAuthentication(): Authentication {
    return this.authenticationSubject.getValue();
  }

  onPlatformResume() {
    if (this.connectionStateService.isOnline() && this.getAuthentication().isAuthenticated()) {
      this.refreshAuthenticationFromServer();
    }
  }

  refreshAuthenticationFromServer() {
    //ha nincs bejelentkezve akkor mar az authentication eldobodik
    this.authRestControllerService.currentPrincipal().toPromise()
      .then((result) => this.updateAuthentication(result))
      .catch(() => this.setLogoutAuthentication());
  }

  async login(username: string, password: string, redirectUrl?: string) {
    return this.authRestControllerService.login(username, password).toPromise()
      .then((authResult) => {
        this.setAuthToken(authResult.sessionToken);
        const request = { megbizoSzereploId: authResult.trusteeId };
        return this.jogosultsagControllerService
          .megbizoKivalasztas(request)
          .toPromise()
          .then((mkResult) => {
            this.updateAuthentication(mkResult);
            if (redirectUrl) {
              this.router.navigateByUrl(redirectUrl, { replaceUrl: true });
            }
            return mkResult;
          })
          .catch((mkError) => this.toastService.httpError(mkError));
      })
      .catch((err) => console.error('AuthService.login error', err));
  }

  async logout() {
    try {
      if (this.connectionStateService.isOnline()) {
        await this.authRestControllerService.logout().toPromise();
      }
      await this.removeAuthToken();
    } catch (err) {
      console.error('AuthService.logout: error during logout', err);
    } finally {
      this.setLogoutAuthentication();
    }
  }

  async setAuthToken(authToken: string) {
    this.authToken = authToken;
    return (await this.storageService.storage).set('authToken', authToken);
  }

  fetchAuthToken() {
    return this.authToken;
  }

  async getAuthToken() {
    if (this.authToken) {
      return this.authToken;
    } else {
      this.authToken = await (
        await this.storageService.storage
      ).get('authToken');
    }
    return this.authToken;
  }

  async removeAuthToken() {
    this.authToken = undefined;
    return (await this.storageService.storage).remove('authToken');
  }

  private setLogoutAuthentication() {
    this.authenticationSubject.next(this.getAuthentication().logout());
  }

  private updateAuthentication(sssUser: SssUser) {
    const szemelyId = sssUser.trusteeId;
    //ha nem online csatornán van bejelentkezve akkor kidobjuk
    const isOnlineChannel = sssUser.authorityChannel === 'ONLINE';
    if (Utils.hasValue(szemelyId) && isOnlineChannel) {
      this.mobilAppControllerService.felhasznaloLekerdezes({ szemelyId }).toPromise()
        .then((result) => {
          if (this.router.url.endsWith('/' + routesConfig.bejelentkezes)) {
            // ha mar be van jelentkezve a menure iranyitjuk
            this.router.navigateByUrl(routesConfig.menu, { replaceUrl: true });
          }
          this.authenticationSubject.next(
            this.getAuthentication().update(sssUser, result)
          );
        })
        .catch((error) => {
          // logout
          this.toastService.httpError(error);
          this.setLogoutAuthentication();
        });
    } else {
      // logout
      this.setLogoutAuthentication();
    }
  }
}
