<div *ngIf="isLoading" class="vertical-center spinner">
  <ion-spinner color="primary" class="ion-align-self-center"></ion-spinner>
</div>
<ion-grid>
  <ion-row class="ion-justify-content-evenly">
    <ion-col>
      <div class="no-content-block">
        <p *ngIf="isLoading">Adatok betöltése folyamatban...</p>
        <div *ngIf="!isLoading" class="no-content">
          <p [innerHTML]="noContentText"></p>
          <ion-button fill="outline" [size]="'small'" (click)="onSettingRedirectClick()">
            Alkalmazás beállítások megnyitása
          </ion-button>
        </div>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>
