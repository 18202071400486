<ion-content class="moh-bg-light" *ngIf="felhasznalo">
  <ion-grid>
    <ion-row>
      <ion-col size-md="6" offset-md="3">
        <ion-card>
          <ion-card-header>
            <ion-item *ngIf="step === NyilatkozatokStep.INFO">
              <ion-avatar slot="start" class="icon-moh-primary">
                <ion-icon *ngIf="felhasznalo.enaploEngedelyezett" src='assets/icon/state/check-ok.svg'></ion-icon>
                <ion-icon *ngIf="!felhasznalo.enaploEngedelyezett" src='assets/icon/state/warn-white.svg'></ion-icon>
              </ion-avatar>
              <ion-label class="ion-text-wrap">
                <h6>E-napló</h6>
                <p class="allapot" [ngClass]="felhasznalo?.enaploEngedelyezett ? 'active' : 'notallowed'">
                  {{felhasznalo?.enaploEngedelyezett ? 'Engedélyezett' : 'Nem engedélyezett'}}
                </p>
              </ion-label>
              <a slot="end" class="utmutato-link utmutato-top-right" (click)="onUtmutatoLetoltes()">Útmutató</a>
            </ion-item>
            <ng-container *ngIf="step === NyilatkozatokStep.ENGEDELYEZES">
              <ion-grid>
                <ion-row>
                  <ion-col size-md="6" offset-md="3">
                    <ion-list lines="none" style="padding: 0 0 0 0">
                      <ion-item>
                        <h5 style="margin: 0 0 20px 0">E-napló engedélyezése</h5>
                      </ion-item>
                      <ion-item style="line-height: 1.5;text-align: justify">
                        Nyilatkozatával meg kell erősítenie, hogy Ön a tárgyévre az elektronikus fogási napló (e-napló) vezetését választja és ehhez rendelkezik a szükséges okoseszközzel, melyet az e-napló vezetéséhez megfelelően tud majd használni; továbbá ezt megelőzően nyilatkozik arról is, hogy az e-napló vezetésének szabályait elolvasta, az abban foglaltakat megértette, elsajátította és alkalmazni fogja.
                        <br>
                        Felhívjuk a figyelmét arra, hogy az e-napló választásával kapcsolatos jelen nyilatkozatai után az e-napló vezetése még nem válik kötelezővé, így Ön az értékesítési ponton még választhatja a papíralapú fogási napló vezetési kötelezettséget is annak tudomásul vételével, hogy ezen döntése az év közben újra már nem változtatható meg, mert az áttérés a papíralapú és az e-napló között tárgyéven belül nem biztosítható.
                      </ion-item>
                    </ion-list>
                    <ion-list lines="none" style="padding: 20px 0 0 0">
                      <ion-item>
                        <ion-label style="place-self: center;text-wrap: auto">
                          Megértettem az <a class="utmutato-link" (click)="onUtmutatoLetoltes()">útmutatót az e-napló vezetéséhez</a>
                        </ion-label>
                        <ion-checkbox slot="start" [(ngModel)]="utmutatoMegertes" (ionChange)="enaploEngedelyezes=false"></ion-checkbox>
                      </ion-item>
                      <ion-item>
                        <ion-label style="place-self: center">E-naplót szeretnék váltani</ion-label>
                        <ion-checkbox slot="start" [(ngModel)]="enaploEngedelyezes" [disabled]="!utmutatoMegertes"></ion-checkbox>
                      </ion-item>
                    </ion-list>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ng-container>
          </ion-card-header>

          <ion-card-content class="no-padding-top">
            <div class="ion-text-center">
              <ng-container *ngIf="step === NyilatkozatokStep.INFO">
                <ng-container *ngIf="felhasznalo.enaploEngedelyezett">
                  <horgasz-error-message [serverError]="serverError"></horgasz-error-message>
                  <ion-button class="button-round" (click)="onEnaploEngedelyezesVisszavonas()">
                    Visszavonás
                  </ion-button>
                </ng-container>
                <ng-container *ngIf="!felhasznalo.enaploEngedelyezett">
                  <horgasz-error-message *ngIf="showUtmutatoHibauzenet" (finished)="showUtmutatoHibauzenet = false"
                    errorMessage="Engedélyezés előtt kérjük, olvassa el az útmutatót az e&#8209;napló vezetés szabályairól">
                  </horgasz-error-message>
                  <ion-button *ngIf="!felhasznalo.enaploEngedelyezett" class="button-round" (click)="onEnaploEngedelyezese()">
                    E-napló engedélyezése
                  </ion-button>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="step === NyilatkozatokStep.ENGEDELYEZES">

                <horgasz-error-message [serverError]="serverError"></horgasz-error-message>

                <ion-button class="button-round"
                            (click)="onEnaploEngedelyezesBezaras()">
                  Vissza
                </ion-button>

                <ion-button class="button-round"
                            (click)="onEnaploEngedelyezesMentes()"
                            [disabled]="isLetoltesDisabled || !(enaploEngedelyezes && utmutatoMegertes)">
                  Mentés
                </ion-button>

              </ng-container>
            </div>
          </ion-card-content>

        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

