<ion-card class="fogas-card" *ngIf="fogas && halFaj && vizterulet">
  <ion-card-header>
    <ion-item lines="none">
      <ion-avatar slot="start" class="icon-moh-fogas">
        <ion-icon src="assets/icon/hal.svg"></ion-icon>
      </ion-avatar>
      <ion-label class="ion-text-wrap">
        <ion-card-subtitle>
          {{fogas.idopont | fogasDate}}
          <ng-container *ngIf="!fogas.id">
            ・Feltöltésre vár
            <ion-icon name="cloud-offline-outline"></ion-icon>
          </ng-container>
        </ion-card-subtitle>
        <ion-card-title>{{halFaj.nev}} <span *ngIf="fogas.suly">({{fogas.suly | fogasSuly}} kg)</span> </ion-card-title>
        <p>{{vizterulet.megjelenitesiNev}}</p>
      </ion-label>
    </ion-item>
  </ion-card-header>
</ion-card>
