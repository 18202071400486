import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'horgasz-kimeno-uzenetek',
  templateUrl: './kimeno-uzenetek.page.html',
  styleUrls: ['./kimeno-uzenetek.page.scss'],
})
export class KimenoUzenetekPage implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
