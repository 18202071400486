import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, IonList } from '@ionic/angular';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { KedvencHorgaszhely, MobilAppControllerService } from 'api';
import { KedvencHorgaszhelyService } from 'app/kedvenc-horgaszhelyek/kedvenc-horgaszhely.service';
import { ToastService } from 'app/services/toast.service';
import { routesConfig } from 'config/routesConfig';
import { ConnectionStateService } from 'app/services/connection-state.service';


@Component({
  selector: 'mohosz-lista-nezet',
  templateUrl: './lista-nezet.page.html',
  styleUrls: ['./lista-nezet.page.scss'],
})
export class ListaNezetPage extends OnDestroyMixin implements OnInit{
  @ViewChild(IonList) list: IonList;

  isLoading = true;
  kedvencHorgaszhelyList: Array<KedvencHorgaszhely>;
  filteredKedvencHorgaszhelyList: Array<KedvencHorgaszhely>;

  constructor(
    private connectionStateService: ConnectionStateService,
    private kedvencHorgaszhelyService: KedvencHorgaszhelyService,
    private alertController: AlertController,
    private mobilAppControllerService: MobilAppControllerService,
    private toastService: ToastService,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.kedvencHorgaszhelyService.bundleLoading.pipe(untilComponentDestroyed(this)).subscribe(result => this.isLoading = result);
    this.kedvencHorgaszhelyService.kedvencHorgaszhelyList.pipe(untilComponentDestroyed(this)).subscribe(result => {
      this.kedvencHorgaszhelyList = result;
      this.filteredKedvencHorgaszhelyList = this.kedvencHorgaszhelyList;
    });
  }

  onKedvencHorgaszhelyListItemClick(item: KedvencHorgaszhely) {
    if (item?.id) {
      this.kedvencHorgaszhelyService.setKivalasztottHely(item);
      this.kedvencHorgaszhelyService.requireMoveToKivasztotthely();
      this.router.navigateByUrl(`/${routesConfig.tabs}/${routesConfig.kedvencHorgaszhelyek}/${routesConfig.terkepNezet}`);
    }
  }

  handleSearchChange(event) {
    const query = event.target.value.toLowerCase();
    this.filteredKedvencHorgaszhelyList = this.kedvencHorgaszhelyList.filter(d => d.elnevezes.toLowerCase().indexOf(query) > -1);
  }

  onAddKedvencHorgaszhelyClick() {
    if(this.connectionStateService.isOnline()){
      this.kedvencHorgaszhelyService.setKivalasztottHely(undefined);
      this.router.navigateByUrl(`/${routesConfig.tabs}/${routesConfig.kedvencHorgaszhelyek}/${routesConfig.szerkesztes}`);
    }
  }

  async onDeleteClick(hely: KedvencHorgaszhely) {
    const confirm = await this.alertController.create({
      header: 'Biztos, hogy töröljük?',
      cssClass: 'moh-alert',
      buttons: [
        {
          text: 'Mégsem',
          cssClass: 'alert-button-cancel',
          role: 'cancel',
          handler: () => {

          },
        },
        {
          text: 'Igen',
          cssClass: 'alert-button-confirm',
          role: 'confirm',
          handler: () => {
            this.mobilAppControllerService.kedvencHorgaszhelyTorles({ id: hely.id}).toPromise()
              .then(() => {
                this.list.closeSlidingItems();
                this.kedvencHorgaszhelyService.refreshContent();
              })
              .catch(error => this.toastService.httpError(error));
          },
        },
      ],
    });
    await confirm.present();
  }
}
