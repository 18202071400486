import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { MaskitoModule } from '@maskito/angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { FogasDatePipe } from 'app/altalanos/pipes/fogas-date.pipe';
import { FogasSulyPipe } from 'app/altalanos/pipes/fogas-suly.pipe';
import { HalfajPipe } from 'app/altalanos/pipes/halfaj.pipe';
import { VizteruletPipe } from 'app/altalanos/pipes/vizterulet.pipe';

import { FogasRogzitesModalPage } from 'app/fogasi-naplo/fogasok/rogzites-modal/fogas-rogzites-modal.page';
import { MaterialModule } from 'app/material/material.module';
import { SharedModule } from 'app/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NgSelectModule,
    SharedModule,
    MaterialModule,
    MaskitoModule,
    FogasDatePipe,
    HalfajPipe,
    VizteruletPipe,
    FogasSulyPipe,
  ],
  declarations: [
    FogasRogzitesModalPage,
  ]
})
export class FogasRogzitesModalPageModule {}
