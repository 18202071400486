import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HorgaszatokAdottNaponModalData } from './horgaszatok-adott-napon-modal-data';
import { NevesitettVizteruletHorgaszat } from './nevesitett-vizterulet-horgaszat';

@Component({
  selector: 'mohosz-horgaszatok-adott-napon-modal',
  templateUrl: './horgaszatok-adott-napon-modal.page.html',
  styleUrls: ['./horgaszatok-adott-napon-modal.page.scss', '../../rogzites-modal.scss'],
})
export class HorgaszatokAdottNaponModalPage implements OnInit {

  data: HorgaszatokAdottNaponModalData;
  horgaszatList: Array<NevesitettVizteruletHorgaszat>;

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {
    this.horgaszatList = this.data.horgaszattalToltottNap.horgaszatList.map(horgaszat => {
      const nevesitettHorg = new NevesitettVizteruletHorgaszat();
      nevesitettHorg.vizteruletNev = this.data.vizteruletList.find(v => v.id === horgaszat.vizteruletId).megjelenitesiNev;
      nevesitettHorg.megkezdes = horgaszat.megkezdes;
      nevesitettHorg.befejezes = horgaszat.befejezes;
      return nevesitettHorg;
    });
  }

  dismiss() {
    this.modalController.dismiss({ dismissed: true });
  }

}
