<ion-content class="moh-bg-light" *ngIf="horgaszkartyaPreviewData">

  <div [ngClass]="!horgaszkartyaPreviewData.horgaszAzonosito ? 'vertical-center' : ''">

    <ng-container *ngIf="horgaszkartyaPreviewData.vanHorgaszkartya; else noContentTpl">
      <ion-grid>
        <ion-row>
          <ion-col size-md="6" offset-md="3">
            <ion-card class="horgaszkartya">
              <ion-card-content>
                <mohosz-horgaszkartya-preview [previewData]="horgaszkartyaPreviewData"></mohosz-horgaszkartya-preview>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
        <ion-row class="qrcode">
          <qrcode [qrdata]="horgaszkartyaPreviewData.horgaszAzonosito" [width]="256" [margin]="2"></qrcode>
        </ion-row>
      </ion-grid>

    </ng-container>

    <ng-template #noContentTpl>
      <ion-card class="no-bg ion-text-center">
        <img alt="Figyelem" src="assets/icon/warning.svg" class="moh-warning"/>
        <ion-card-header>
          <ion-card-title>
            <ng-container *ngIf="horgaszkartyaPreviewData.vanHorgaszkartyaFizetesAlatt; else nincsHorgaszkartyaTpl">
              A  Magyar Horgászkártya igénylése fizetésre vár. Kérjük a horgaszjegy.hu oldalon lépj be a profilodba,
              vagy keresd fel horgászszervezeted és ellenőrizd a fizetés sikerességét.
            </ng-container>
            <ng-template #nincsHorgaszkartyaTpl>
              Még nem rendelkezel horgászkártyával. A kiváltás szükségességéről, annak felteleiről a horgaszjegy.hu oldalon tájékozódhatsz.
            </ng-template>
          </ion-card-title>
        </ion-card-header>
      </ion-card>
    </ng-template>

  </div>

</ion-content>
