import { Component, OnInit } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { OnDestroyMixin, untilComponentDestroyed, } from '@w11k/ngx-componentdestroyed';
import { SavePassword } from 'capacitor-ios-autofill-save-password';
import { ApiModuleConfiguration } from 'config/ApiModuleConfiguration';
import { AuthService } from './auth.service';

@Component({
  selector: 'horgasz-auth',
  templateUrl: './auth.page.html',
  styleUrls: ['./auth.page.scss'],
})
export class AuthPage extends OnDestroyMixin implements OnInit {
  email = '';
  password = '';

  hostUrl: string;

  hidePassword = true;
  submitted = false;

  serverError = undefined;

  constructor(private authService: AuthService) {
    super();
    this.hostUrl = ApiModuleConfiguration.default().hostUrl;
    this.authService.authentication.pipe(untilComponentDestroyed(this))
      .subscribe((auth) => {
        if (this.submitted && auth.getState()) {
        }
      });
  }

  ionViewWillEnter() {
    this.submitted = false;
  }

  ngOnInit() {}

  async onLogin() {
    this.submitted = true;
    const email = this.email?.trim();
    const password = this.password;
    await this.onSubmit();
    try {
      await this.authService.login(email, password);
    } catch (error) {
      this.serverError = error;
    } finally {
      this.submitted = false;
    }
  }

  async onSubmit() {
    //console.log('onSubmit');
    if (Capacitor.getPlatform() === 'ios') {
      SavePassword.promptDialog({username: this.email,password: this.password,})
        .then(() => console.log('promptDialog success'))
        .catch((err) => console.error('promptDialog failure', err));
    }
  }
}
