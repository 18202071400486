import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { AltalanosModule } from 'app/altalanos/altalanos.module';

import { HorgaszvizsgaRoutingModule } from 'app/horgasz-engedelyek/horgaszvizsga/horgaszvizsga-routing.module';

import { HorgaszvizsgaPage } from 'app/horgasz-engedelyek/horgaszvizsga/horgaszvizsga.page.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    HorgaszvizsgaRoutingModule,
    AltalanosModule,
  ],
  declarations: [HorgaszvizsgaPage]
})
export class HorgaszvizsgaPageModule {}
