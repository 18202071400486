import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { AltalanosModule } from 'app/altalanos/altalanos.module';

import { NyilatkozatokPageRoutingModule } from './nyilatkozatok-routing.module';

import { NyilatkozatokPage } from './nyilatkozatok.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NyilatkozatokPageRoutingModule,
    AltalanosModule,
    ReactiveFormsModule
  ],
  declarations: [NyilatkozatokPage]
})
export class NyilatkozatokPageModule {}
