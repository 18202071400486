import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';

import { ElektronikusFogas, ElektronikusFogasNyilvantartas, Halfaj, VizteruletTorzsadat } from 'api';
import { TimerMutex } from 'app/core/timer-mutex';
import { AktualisHorgaszatService } from 'app/fogasi-naplo/aktualis-horgaszat.service';
import { FogasokByMonth } from 'app/fogasi-naplo/fogasok/fogasok-by-month';
import { HorgaszatMegkezdeseModalData } from 'app/fogasi-naplo/fogasok/horgaszat-megkezdese-modal/horgaszat-megkezdese-modal-data';
import { HorgaszatMegkezdeseModalPage } from 'app/fogasi-naplo/fogasok/horgaszat-megkezdese-modal/horgaszat-megkezdese-modal.page';
import { FogasRogzitesModalData } from 'app/fogasi-naplo/fogasok/rogzites-modal/fogas-rogzites-modal-data';
import { FogasRogzitesModalPage } from 'app/fogasi-naplo/fogasok/rogzites-modal/fogas-rogzites-modal.page';
import { FogasiNaploService } from 'app/services/fogasi-naplo.service';
import { LocationService } from 'app/services/location.service';
import { ToastService } from 'app/services/toast.service';
import { TorzsadatProviderService } from 'app/services/torzsadat-provider.service';
import { DisplayObserverService } from 'app/shared/display-observer.service';
import { LastModifiedType } from 'app/shared/last-modified-refresher/last-modified-type';

@Component({
  selector: 'mohosz-fogasok',
  templateUrl: './fogasok.page.html',
  styleUrls: ['./fogasok.page.scss']
})
export class FogasokPage extends OnDestroyMixin {

  readonly LastModifiedType = LastModifiedType;

  eFogasNyilvantartas: ElektronikusFogasNyilvantartas;
  halfajList: Array<Halfaj>;
  vizteruletList: Array<VizteruletTorzsadat>;

  selectedVizterulet: VizteruletTorzsadat;

  fogasokByMonth: Array<FogasokByMonth>;

  isSmallDisplay: boolean;

  isLoading = true;

  private timerMutex = new TimerMutex(1);

  constructor(
    private toastService: ToastService,
    private displayObserverService: DisplayObserverService,
    private modalController: ModalController,
    private torzsadatProviderService: TorzsadatProviderService,
    private fogasiNaploService: FogasiNaploService,
    private aktualisHorgaszatService: AktualisHorgaszatService,
    private locationService: LocationService
  ) {
    super();

    // halfajok
    this.torzsadatProviderService.halfajList.pipe(untilComponentDestroyed(this)).subscribe(
      result => this.halfajList = result
    );
    // vizteruletek
    this.torzsadatProviderService.vizteruletList.pipe(untilComponentDestroyed(this)).subscribe(
      result => this.vizteruletList = result
    );

    this.fogasiNaploService.synchronization.pipe(untilComponentDestroyed(this)).subscribe(result =>
      this.updateFromDomain(result)
    );

    // kepernyo figyeles
    this.displayObserverService.displaySize.pipe(untilComponentDestroyed(this)).subscribe(size => {
      this.isSmallDisplay = size.isSmall();
    });

    //Horgaszat megkezdese
    this.aktualisHorgaszatService.aktualisHorgaszatDataObservable.pipe(untilComponentDestroyed(this))
      .subscribe(result => this.selectedVizterulet = result.selectedVizterulet);
  }

  ionViewDidEnter(){
    this.aktualisHorgaszatService.refresh();
    this.locationService.refreshLocation();
    this.refreshList();
  }

  refreshList() {
    this.timerMutex.runExclusive(() => {
      //itt a fogasiNaploService kezeli le az online offline statuszt
      this.fogasiNaploService.enaploLekerdezes().toPromise()
        .then(result => this.updateFromDomain(result))
        .catch(error => this.toastService.httpError(error))
        .finally(() => this.isLoading = false);
    });
  }

  onFogasRogzitesClick() {
    this.openFogasRogzitesModal();
  }

  openFogasRogzitesModal() {
    const data = new FogasRogzitesModalData(
      this.fogasiNaploService.felhasznalo.ervenyesFogasiNaplo.sorszam,
      this.eFogasNyilvantartas,
      this.halfajList,
      this.vizteruletList,
      this.selectedVizterulet
    );
    this.modalController.create({
      component: FogasRogzitesModalPage,
      componentProps: { data },
      cssClass: 'fogas-rogzites-modal',
    }).then(modalEl => {
      modalEl.present();
      return modalEl.onDidDismiss();
    }).then(resultData => {
      if (resultData.data?.fogas) {
        this.refreshList();
      }
    });
  }

  onHorgaszatMegkezdeseClick() {
    const data: HorgaszatMegkezdeseModalData = { vizteruletList: this.vizteruletList };
    this.modalController.create({
      component: HorgaszatMegkezdeseModalPage,
      componentProps: { data },
      cssClass: 'horgaszat-megkezdese-modal',
    }).then(modalEl => {
      modalEl.present();
      return modalEl.onDidDismiss();
    }).then(resultData => {
      if (resultData.data?.fogas) {
        this.refreshList();
      }
    });
  }

  private updateFromDomain(result: ElektronikusFogasNyilvantartas){
    this.eFogasNyilvantartas = result;
    if (result.fogasok.length) {
      // ev-honap csoportkepzes
      this.fogasokByMonth = this.groupFogasokByMonth(result.fogasok);
    }
  }

  private groupFogasokByMonth(fogasList: Array<ElektronikusFogas>): Array<FogasokByMonth> {
    //map.key=>FogasokByMonth.pkDate
    const fogasok = FogasokByMonth.sort(fogasList);
    const fogasokMap = new Map<number, FogasokByMonth>();
    fogasok.forEach(fogas => {
      const mapKey = FogasokByMonth.buildPk(fogas);
      const existingFogasByMonth = fogasokMap.get(mapKey);
      if (existingFogasByMonth) {
        existingFogasByMonth.addFogas(fogas);
      } else {
        fogasokMap.set(mapKey, FogasokByMonth.fromDomain(fogas));
      }
    });
    const fogasokByMonth = Array.from(fogasokMap.values());
    fogasokByMonth.sort((a, b) => b.pkDate - a.pkDate);
    return fogasokByMonth;
  }

}
