import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { HorgaszturizmusPoiItem } from 'api';

@Component({
  selector: 'horgasz-horgaszturizmus-poi-card',
  templateUrl: './horgaszturizmus-poi-card.component.html',
  styleUrls: ['./horgaszturizmus-poi-card.component.scss'],
})
export class HorgaszturizmusPoiCardComponent implements OnInit {

  @Input() poi: HorgaszturizmusPoiItem;
  @Input() isLoading = false;
  @Output() closePoiPopup: EventEmitter<any> = new EventEmitter();

  constructor(
    private router: Router
  ) { }

  ngOnInit() {}

  onMegnyitasClick() {
    if (this.poi?.szolgaltatasId) {
      this.router.navigateByUrl(`/tabs/jegyvasarlas/horgasztatas/${this.poi.szolgaltatasId}`);
    }
  }

  onCloseClick() {
    this.closePoiPopup.emit(true);
  }
}
