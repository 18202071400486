import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileUploaderMimeType } from 'app/file-uploader/file-uploader-mime-type.enum';

import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { OutputFormat } from 'ngx-image-cropper/lib/interfaces/cropper-options.interface';
import { KEPFORMATUM_PROPERTIES_MAP, KepSzerkesztoDialogData } from './kep-szerkeszto-models';

@Component({
  selector: 'horgasz-kep-szerkeszto-dialog',
  templateUrl: './kep-szerkeszto-dialog.component.html',
  styleUrls: ['./kep-szerkeszto-dialog.component.scss']
})
export class KepSzerkesztoDialogComponent implements OnInit {

  @Input() uploadInProgress = false;
  @Output() kepSaveClicked: EventEmitter<File> = new EventEmitter();
  @Output() cropperError: EventEmitter<string> = new EventEmitter();
  @Output() cropperClose: EventEmitter<boolean> = new EventEmitter();

  readonly DEFAULT_ASPECT_RATIO = 4/3;
  minWidth: number;
  minHeight: number;

  croppedImage: any = '';
  fileToReturn: any;

  aspectRatio: number;
  type: OutputFormat;
  allowedMimeTypes = [
    FileUploaderMimeType.PNG,
    FileUploaderMimeType.JPG,
    FileUploaderMimeType.BMP
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: KepSzerkesztoDialogData
  ) {
    this.aspectRatio = KEPFORMATUM_PROPERTIES_MAP.get(data.formatum)?.aspectRatio ?? this.DEFAULT_ASPECT_RATIO;
    this.minWidth = KEPFORMATUM_PROPERTIES_MAP.get(data.formatum)?.minWidth;
    this.minHeight = this.minWidth ? this.minWidth / this.aspectRatio : undefined;
    const mimeType = data.file.file.type;
    if (!this.allowedMimeTypes.map(tipus => tipus.toString()).includes(mimeType)) {
      throw new Error('Nem megfelelő mime típus');
    }
    this.type = mimeType.split('/')[1] as OutputFormat;
  }

  ngOnInit(): void {
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
    this.fileToReturn = this.base64ToFile(this.croppedImage, this.data.file?.file.name);
  }
  imageLoaded(event: LoadedImage): void {
    if (!this.uploadInProgress && event.original.size.width < this.minWidth || event.original.size.height < this.minHeight) {
      this.cropperError.emit('A kép mérete nem megfelelő, minimum ' + this.minWidth + 'x' + this.minHeight+ 'px' + ' kell lennie');
    }
  }

  onKepSave(): void {
    if (!this.uploadInProgress) {
      this.kepSaveClicked.emit(this.fileToReturn);
    }
  }

  onCloseClick(): void {
    if (!this.uploadInProgress) {
      this.cropperClose.emit(true);
    }
  }

  private base64ToFile(data: any, filename: any): File {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
}
