<ion-card>
  <ion-card-content class="flog-card-content">
    <div *ngIf="flog.details.kep" class="kep-container pointer">
      <horgasz-tag *ngIf="!(flog.lathatosag === LathatosagEnum.PUBLIKUS)" [label]="flog.lathatosag | flog" class="tag" type="fogaslathatosag"></horgasz-tag>
      <horgasz-base64-content [content]="flog.details.kep" width="100%" borderRadius="4px"></horgasz-base64-content>
    </div>
    <div class="content">
      <div class="font-18">
        <strong>{{flog.details.halfajNev}}</strong>
      </div>

      <div class="main-data">
        <div>
          <div class="font-14 bold"><strong>SÚLY:</strong></div>
          <div class="font-14 police-blue">{{flog.details.suly}} kg</div>
        </div>
        <div class="divider"></div>

        <div>
          <div class="font-14 bold"><strong>HOSSZ:</strong></div>
          <div class="font-14 police-blue">{{flog.details.hossz}} cm</div>
        </div>
        <div class="main-data-divider"></div>

        <div>
          <div class="font-14 bold"><strong>KERÜLET:</strong></div>
          <div class="font-14 police-blue">{{flog.details.kerulet}} cm</div>
        </div>
      </div>

      <div class="center-section">
        <ion-icon src="assets/icon/location-blue.svg" role="img"></ion-icon>
        <span class="police-blue text-overflow-ellipsis line-2">{{flog.details.vizteruletNev}}</span>
      </div>

      <div class="last-section-divider"></div>
      <div class="last-section" (click)="onFogasSzerkesztesClick()">
        <div class="button-container">
          <ion-fab-button size="small" class="fab-kep-szerkesztes">
            <ion-icon size="small" name="pencil"></ion-icon>
          </ion-fab-button>
          <strong>Fogás szerkesztése</strong>
        </div>
        <img src="assets/icon/arrow-right.svg">
      </div>

    </div>
  </ion-card-content>
</ion-card>
