import { Component } from '@angular/core';
import { FileOpener } from '@capacitor-community/file-opener';
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { MobilAppControllerService, VersenyzoiEngedelyRequest } from 'api';
import { VersenyzoiEngedely } from 'api/model/versenyzoiEngedely';
import { AuthService } from 'app/auth/auth.service';
import { Utils } from 'app/core/utils';
import { ConnectionStateEnum, ConnectionStateService } from 'app/services/connection-state.service';
import { ToastService } from 'app/services/toast.service';

@Component({
  selector: 'mohosz-versenyzoi-engedely',
  templateUrl: './versenyzoi-engedely.page.html',
  styleUrls: ['../jegyek/jegyek.page.scss'],
})
export class VersenyzoiEngedelyPage extends OnDestroyMixin {

  szemelyId: number;
  versenyzoiEngedely: VersenyzoiEngedely;
  ervenyessegiEv = new Date().getFullYear();
  VersenyzoiEngedelyStatuszEnum = VersenyzoiEngedely.StatuszEnum;

  isLetoltesDisabled = true;

  constructor(
    private connectionStateService: ConnectionStateService,
    private mobilAppControllerService: MobilAppControllerService,
    private toastService: ToastService,
    protected authService: AuthService,
  ) {
    super();
    this.authService.authentication.pipe(untilComponentDestroyed(this)).subscribe(auth => {
      this.versenyzoiEngedely = auth.felhasznalo?.ervenyesVersenyzoiEngedely;
      this.szemelyId = auth.felhasznalo?.szemelyId;
    });
    this.connectionStateService.state.pipe(untilComponentDestroyed(this)).subscribe(state =>
      this.isLetoltesDisabled = state !== ConnectionStateEnum.ONLINE
    );
  }

  onIgazolasLetoltes(){
    if(this.connectionStateService.isOnline()){
      const request = {
        szemelyId: this.szemelyId,
        ervenyessegiEv: this.versenyzoiEngedely.ervenyessegiEv
      } as VersenyzoiEngedelyRequest;
      this.mobilAppControllerService.versenyzoiEngedelyNyomtatas(request).toPromise()
        .then(result => {
          if(Capacitor.isNativePlatform()){
            const filename = 'versenyzoi_engedely_' + this.versenyzoiEngedely.ervenyessegiEv + '.pdf';
            Filesystem.writeFile({ path: filename, data: result.content, directory: Directory.Data})
              .then(fwResult => FileOpener.open({filePath: fwResult.uri}))
              .catch(wfError => this.toastService.errorMessage(wfError.message));
          } else {
            Utils.openBase64Content(result);
          }
        })
        .catch(error => this.toastService.httpError(error));
      // this.iab.create(downloadUrl, '_blank');
    }
  }

}
