import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'horgasz-kesz',
  templateUrl: './kesz.component.html',
  styleUrls: ['./kesz.component.scss'],
})
export class KeszComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit() {
  }

}
