import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Utils} from 'app/core/utils';
import {RegPont} from '../reg-pont';

@Component({
  selector: 'mohosz-reg-pont-info-panel',
  templateUrl: './reg-pont-info-panel.component.html',
  styleUrls: ['./reg-pont-info-panel.component.less']
})
export class RegPontInfoPanelComponent implements OnInit, AfterViewInit {

  @Input() regPont: RegPont;

  @Output() regPontInfoPanelReady: EventEmitter<boolean> = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.regPontInfoPanelReady.emit(true);
  }

  goToLocation(){
    Utils.goToLocation(this.regPont.lat, this.regPont.lng);
  }
}
