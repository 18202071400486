export const routesConfig = {
  bejelentkezes: 'bejelentkezes',
  menu: 'menu',
  tabs: 'tabs',
  horgaszEngedelyek: 'horgasz-engedelyek',
  horgaszkartya: 'horgaszkartya',
  jegyek: 'jegyek',
  versenyzoiEngedely: 'versenyzoi-engedely',
  horgaszvizsga: 'horgaszvizsga',
  nyilatkozatok: 'nyilatkozatok',
  teruletiJegyVasarlas: 'jegyvasarlas',
  korlatozottTeruletek: 'korlatozas-ala-vont-teruletek',
  fogasiNaplo: 'fogasi-naplo',
  kedvencHorgaszhelyek: 'kedvenc-horgaszhelyek',
  fogas: 'fogas-feltoltes',
  fogasaim: 'fogasaim',
  postafiok: 'postafiok',
  terkepNezet: 'terkep-nezet',
  listaNezet: 'lista-nezet',
  fogasok: 'fogasok',
  kesz: 'kesz',
  horgaszNapok: 'horgasz-napok',
  szerkesztes: 'szerkesztes',
  ertekesitesiPontok: 'ertekesitesi-pontok',
  uzenet: 'uzenet'
};
