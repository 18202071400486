import { Pipe, PipeTransform } from '@angular/core';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { VizteruletTorzsadat } from 'api';
import { TorzsadatProviderService } from 'app/services/torzsadat-provider.service';

@Pipe({
  name: 'vizterulet',
  standalone: true
})
export class VizteruletPipe extends OnDestroyMixin implements PipeTransform {

  private vizteruletList: Array<VizteruletTorzsadat>;

  constructor(
    private torzsadatProviderService: TorzsadatProviderService,
  ) {
    super();
    this.torzsadatProviderService.vizteruletList.pipe(untilComponentDestroyed(this)).subscribe(
      result => this.vizteruletList = result
    );
  }

  transform(vizteruletId: number): string | number {
    return this.vizteruletList.find(vizterulet => vizterulet.id === vizteruletId)?.nev ?? vizteruletId;
  }

}
