<div class="mhk-container" *ngIf="previewData">
  <img src="assets/images/horgaszkartya.png" alt="" class="mhk-image">
  <div class="mhk-data">
    <div>{{previewData.nev}}</div>
    <div>{{previewData.anyjaNeve}}</div>
    <div>{{previewData.szuletesiHely}}, {{previewData.szuletesiDatum | date: DATE_FORMAT}}</div>
    <div>
      <span>{{previewData.ervenyessegKezdete | date: DATE_FORMAT}}</span>
      <span style="margin-left: 14%">{{previewData.ervenyessegVege | date: DATE_FORMAT}}</span>
      <span style="margin-left: 18%">{{previewData.horgaszAzonosito}}</span>
    </div>
  </div>
</div>

