import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { AuthService } from 'app/auth/auth.service';
import { ToastService } from 'app/services/toast.service';
import { routesConfig } from 'config/routesConfig';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FogasiNaploGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private toastService: ToastService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.authentication.pipe(map(auth => {
      if (!auth.felhasznalo?.ervenyesFogasiNaplo?.elektronikus) {
        this.toastService.errorMessageAutoClose('Nincs jogosultsága a funkcióhoz');
        this.router.navigateByUrl(routesConfig.menu);
      }
      return auth.felhasznalo?.ervenyesFogasiNaplo?.elektronikus;
    }));
  }
}
