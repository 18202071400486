import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HorgaszturizmusMapPoi } from 'api';

@Component({
  selector: 'horgasz-vizterulet-kapcsolas-popup-card',
  templateUrl: './vizterulet-kapcsolas-popup-card.component.html',
  styleUrls: ['./vizterulet-kapcsolas-popup-card.component.scss'],
})
export class VizteruletKapcsolasPopupCardComponent implements OnInit {

  @Input() poi: HorgaszturizmusMapPoi;
  @Output() vizteruletKapcsolas: EventEmitter<HorgaszturizmusMapPoi> = new EventEmitter();
  @Output() closePoiPopup: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {}

  onVizteruletKapcsolasa() {
    this.vizteruletKapcsolas.emit(this.poi);
  }

  onCloseClick() {
    this.closePoiPopup.emit(true);
  }

}
