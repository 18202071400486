<div class="modal-content" *ngIf="data && horgaszatList">
  <ion-card class="modal-rogzites">
    <ion-card-header class="ion-text-center">
      <ion-item lines="none" tabindex="-1">
        <ion-label style="font-size: 17px;">{{data.horgaszattalToltottNap.datum | date:'yyyy MMM dd.'}}</ion-label>
        <ion-buttons slot="end">
          <ion-button (click)="dismiss()">
            <ion-icon slot="icon-only" name="close"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-item>
    </ion-card-header>

    <ion-card-content>
      <ion-grid>
        <ng-container *ngFor="let horgaszat of horgaszatList">
          <ion-row>
            <div class="horgaszat-hely">{{horgaszat.vizteruletNev}}</div>
            <div class="horgaszat-ido">
              {{horgaszat.megkezdes | date:'yyyy MMM dd HH:mm'}} - {{horgaszat.befejezes | date:'yyyy MMM dd HH:mm'}}
            </div>
          </ion-row>
        </ng-container>
      </ion-grid>
    </ion-card-content>
  </ion-card>
</div>
