import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Observable } from 'rxjs';


interface CacheItem<T> {
  cachedDate: Date;
  isOnline: boolean;
  data: T;
}

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  private storage: Storage | null = null;

  constructor(
    private ionicStorage: Storage
  ) {
  }

  async init() {
    if (this.storage != null) {
      return;
    }
    this.storage = await this.ionicStorage.create();
  }

  async set<T>(key: string, value: T): Promise<T> {
    await this.init();
    await this.setCacheItem<T>(key, {
      cachedDate: new Date(),
      isOnline: true,
      data: value
    });
    return value;
  }

  async get<T>(key: string): Promise<any> {
    await this.init();
    const item = await this.getCacheItem<T>(key);
    return item?.data;
  }

  async getWithFailure<T>(key: string, err: any): Promise<any> {
    await this.init();
    const item = await this.getCacheItemWithFailure<T>(key, err);
    return item?.data;
  }

  cache<T>(obs: Observable<T>, key: string): Observable<T | undefined> {
    return new Observable<T | undefined>(observer => {
      this.getCacheItem<T>(key).then(result => {
        if(result){
          observer.next(result.data);
        }
        obs.subscribe({
          next: fromServer => {
            this.set<T>(key, fromServer).then(() => {
              observer.next(fromServer);
              observer.complete();
            });
          },
          error: () => observer.complete()
        });
      });
    });
  }

   async setCacheItem<T>(key: string, value: CacheItem<T>): Promise<CacheItem<T>> {
    await this.init();
    await this.storage?.set(key, value);
    return value;
  }

   async getCacheItem<T>(key: string): Promise<CacheItem<T> | undefined> {
    await this.init();
    return this.storage?.get(key);
  }

  async removeCacheItem<T>(key: string) {
    await this.init();
    return this.storage?.remove(key);
  }

  async getCacheItemWithFailure<T>(key: string, err: any): Promise<CacheItem<T> | undefined> {
    await this.init();
    const item = await this.getCacheItem<T>(key);
    if (item == null) {
      throw err;
    }
    item.isOnline = false;
    await this.setCacheItem(key, item);
    return item;
  }
}
