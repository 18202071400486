import { Component } from '@angular/core';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { HorgaszkartyaPreviewData } from 'api';
import { AuthService } from 'app/auth/auth.service';

@Component({
  selector: 'mohosz-horgaszkartya',
  templateUrl: './horgaszkartya.page.html',
  styleUrls: ['./horgaszkartya.page.scss'],
})
export class HorgaszkartyaPage extends OnDestroyMixin{

  horgaszkartyaPreviewData: HorgaszkartyaPreviewData;

  constructor(
    private authService: AuthService,
  ){
    super();
    this.authService.authentication.pipe(untilComponentDestroyed(this)).subscribe(auth => {
      this.horgaszkartyaPreviewData = auth.felhasznalo?.horgaszkartyaPreviewData ?? {} as HorgaszkartyaPreviewData;
    });
  }
}
