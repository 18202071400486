import { Component, OnInit } from '@angular/core';
import { FileOpener } from '@capacitor-community/file-opener';
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { EnaploNyilatkozatRequest, MobilAppControllerService, MobilAppFelhasznalo } from 'api';
import { AuthService } from 'app/auth/auth.service';
import { ConfirmationDialogData } from 'app/core/confirmation-dialog/confirmation-dialog-data';
import { ConfirmationDialogService } from 'app/core/confirmation-dialog/confirmation-dialog.service';
import { Utils } from 'app/core/utils';
import { NyilatkozatokStep } from 'app/horgasz-engedelyek/nyilatkozatok/nyilatkozatok-step';
import { ConnectionStateEnum, ConnectionStateService } from 'app/services/connection-state.service';
import { ToastService } from 'app/services/toast.service';

@Component({
  selector: 'mohosz-nyilatkozatok',
  templateUrl: './nyilatkozatok.page.html',
  styleUrls: ['./nyilatkozatok.page.scss'],
})
export class NyilatkozatokPage extends OnDestroyMixin implements OnInit {

  NyilatkozatokStep = NyilatkozatokStep;

  felhasznalo: MobilAppFelhasznalo;
  step = NyilatkozatokStep.INFO;

  showUtmutatoHibauzenet = false;
  utmutatoMegnyitva = false;
  utmutatoMegertes = false;
  enaploEngedelyezes = false;
  isLetoltesDisabled = true;

  constructor(
    private authService: AuthService,
    private mobilAppControllerService: MobilAppControllerService,
    private toastService: ToastService,
    private confirmationDialogService: ConfirmationDialogService,
    private connectionStateService: ConnectionStateService,
  ) {
    super();
    this.authService.authentication.pipe(untilComponentDestroyed(this))
      .subscribe(auth => {
        this.felhasznalo = auth.felhasznalo;
        this.step = NyilatkozatokStep.INFO;
      });
    this.connectionStateService.state.pipe(untilComponentDestroyed(this)).subscribe(state =>
      this.isLetoltesDisabled = state !== ConnectionStateEnum.ONLINE
    );
  }

  ngOnInit() {
  }

  ionViewDidEnter(){
    this.step = NyilatkozatokStep.INFO;
  }

  onEnaploEngedelyezese() {
    if (this.utmutatoMegnyitva) {
      this.utmutatoMegertes = false;
      this.enaploEngedelyezes = false;
      this.step = NyilatkozatokStep.ENGEDELYEZES;
    } else {
      this.showUtmutatoHibauzenet = true;
    }
  }

  onEnaploEngedelyezesVisszavonas() {
    const data: ConfirmationDialogData = {
      messages: ['Biztosan visszavonod az e-napló váltási lehetőséget?']
    };
    this.confirmationDialogService.openFigyelmeztetesDialog(data).then((reply) => {
      if(reply) {
        this.enaploEngedelyezes = false;
        this.onEnaploEngedelyezesMentes();
      }
    });
  }

  onEnaploEngedelyezesMentes() {
    const request: EnaploNyilatkozatRequest = {
      szemelyId: this.felhasznalo.szemelyId,
      engedelyezett: this.enaploEngedelyezes
    };
    this.mobilAppControllerService.enaploNyilatkozat(request).subscribe({
      next: () => this.authService.refreshAuthenticationFromServer(),
      error: err => this.toastService.httpError(err)
    });
  }

  onEnaploEngedelyezesBezaras() {
    this.step = NyilatkozatokStep.INFO;
  }

  onUtmutatoLetoltes(){
    if(!this.isLetoltesDisabled){
      this.mobilAppControllerService.enaploKitoltesiUtmutato().toPromise()
        .then(result => {
          if(Capacitor.isNativePlatform()){
            const filename = 'enaplo-kitoltesi-utmutato.pdf';
            Filesystem.writeFile({ path: filename, data: result.content, directory: Directory.Data})
              .then(fwResult => {
                FileOpener.open({filePath: fwResult.uri});
                this.utmutatoMegnyitas();
            })
              .catch(wfError => this.toastService.errorMessage(wfError.message));
          } else {
            Utils.openBase64Content(result);
            this.utmutatoMegnyitas();
          }
        })
        .catch(error => this.toastService.httpError(error));
    }
  }

  private utmutatoMegnyitas() {
    this.utmutatoMegnyitva = true;
    this.showUtmutatoHibauzenet = false;
  }
}
