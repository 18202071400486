import { Component, OnInit } from '@angular/core';
import { FileOpener } from '@capacitor-community/file-opener';
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { HorgaszvizsgaIgazolasNyomtatasRequest, MobilAppControllerService, MobilAppVizsgaOsszesito } from 'api';
import { AuthService } from 'app/auth/auth.service';
import { Utils } from 'app/core/utils';
import { ConnectionStateEnum, ConnectionStateService } from 'app/services/connection-state.service';
import { ToastService } from 'app/services/toast.service';

@Component({
  selector: 'horgasz-horgaszvizsga',
  templateUrl: './horgaszvizsga.page.component.html',
  styleUrls: ['../jegyek/jegyek.page.scss']
})
export class HorgaszvizsgaPage extends OnDestroyMixin implements OnInit {

  vizsgaOsszesito: MobilAppVizsgaOsszesito;
  szemelyId: number;
  isLetoltesDisabled = true;

  constructor(
    private connectionStateService: ConnectionStateService,
    private authService: AuthService,
    private mobilAppControllerService: MobilAppControllerService,
    private toastService: ToastService
  ) {
    super();
    this.authService.authentication.pipe(untilComponentDestroyed(this)).subscribe(auth => {
      this.vizsgaOsszesito = auth.felhasznalo?.vizsgaOsszesito;
      this.szemelyId = auth.felhasznalo?.szemelyId;
    });
    this.connectionStateService.state.pipe(untilComponentDestroyed(this)).subscribe(state =>
      this.isLetoltesDisabled = state !== ConnectionStateEnum.ONLINE
    );
  }

  ngOnInit() {
  }

  onIgazolasLetoltes(nyelv: HorgaszvizsgaIgazolasNyomtatasRequest.NyelvEnum): void {
    if(this.connectionStateService.isOnline()){
      const request: HorgaszvizsgaIgazolasNyomtatasRequest = { szemelyId: this.szemelyId, nyelv };
      this.mobilAppControllerService.horgaszvizsgaIgazolasNyomtatas(request).toPromise()
        .then(result => {
          if(Capacitor.isNativePlatform()){
            const filename = 'horgaszvizsga_igazolas_' + nyelv + '.pdf';
            Filesystem.writeFile({ path: filename, data: result.content, directory: Directory.Data})
              .then(fwResult => FileOpener.open({filePath: fwResult.uri}))
              .catch(wfError => this.toastService.errorMessage(wfError.message));
          } else {
            Utils.openBase64Content(result);
          }
        })
        .catch(error => this.toastService.httpError(error));
      // this.iab.create(downloadUrl, '_blank');
    }
  }
}
