import { Component, ViewChild } from '@angular/core';
import { MatCalendar, MatCalendarCellCssClasses, MatCalendarUserEvent } from '@angular/material/datepicker';
import { ModalController } from '@ionic/angular';

import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ElektronikusFogasNyilvantartas, HorgaszattalToltottNap, VizteruletTorzsadat } from 'api';
import { AuthService } from 'app/auth/auth.service';
import { Utils } from 'app/core/utils';
import { FogasiNaploService } from 'app/services/fogasi-naplo.service';
import { LocationService } from 'app/services/location.service';
import { ToastService } from 'app/services/toast.service';
import { TorzsadatProviderService } from 'app/services/torzsadat-provider.service';
import { LastModifiedType } from 'app/shared/last-modified-refresher/last-modified-type';
import { HorgaszatokAdottNaponModalData } from './horgaszatok-adott-napon-modal/horgaszatok-adott-napon-modal-data';
import { HorgaszatokAdottNaponModalPage } from './horgaszatok-adott-napon-modal/horgaszatok-adott-napon-modal.page';

@Component({
  selector: 'mohosz-horgasz-napok',
  templateUrl: './horgasz-napok.page.html',
  styleUrls: ['./horgasz-napok.page.scss'],
})
export class HorgaszNapokPage extends OnDestroyMixin {

  @ViewChild(MatCalendar) calendar: MatCalendar<Date>;

  eFogasNyilvantartas: ElektronikusFogasNyilvantartas;
  vizteruletList: Array<VizteruletTorzsadat>;

  selectedDate = null;
  minDate: Date;
  maxDate: Date;
  horgaszattalToltottNapok: Array<HorgaszattalToltottNap>;

  dateClassVar = this.dateClass();

  protected readonly LastModifiedType = LastModifiedType;

  constructor(
    public modalController: ModalController,
    protected authService: AuthService,
    private fogasiNaploService: FogasiNaploService,
    private toastService: ToastService,
    private locationService: LocationService,
    private torzsadatProviderService: TorzsadatProviderService,
  ) {
    super();

    // vizteruletek
    this.torzsadatProviderService.vizteruletList.pipe(untilComponentDestroyed(this)).subscribe(
      result => this.vizteruletList = result
    );
  }

  ionViewDidEnter(){
    this.locationService.refreshLocation();
    this.refreshList();
  }

  dateSelected(event: MatCalendarUserEvent<Date>) {
    if (this.horgaszattalToltottNapok) {
      const selectedHNap = this.horgaszattalToltottNapok.find(hNap =>
        new Date(hNap.datum).getDate() === event.value?.getDate()
      );
      if(selectedHNap){
        //csak akkor nyitjuk meg a modalt ha van horgaszat
        const data: HorgaszatokAdottNaponModalData = { horgaszattalToltottNap: selectedHNap, vizteruletList: this.vizteruletList };
        this.modalController.create({
          component: HorgaszatokAdottNaponModalPage,
          componentProps: { data },
          cssClass: 'horgaszatok-adott-napon-modal',
        }).then(modalEl => {
          modalEl.present();
          return modalEl.onDidDismiss().then(() => this.selectedDate = undefined);
        });
      }
    }
  }

  refreshList() {
    this.fogasiNaploService.enaploLekerdezes().toPromise()
      .then(eFogasNyilvantartas => {
        this.minDate = Utils.fromServerDate(eFogasNyilvantartas.ervenyessegKezdete);
        this.maxDate = Utils.fromServerDate(eFogasNyilvantartas.ervenyessegVege);
        this.eFogasNyilvantartas = eFogasNyilvantartas;
        this.horgaszattalToltottNapok = eFogasNyilvantartas.horgaszattalToltottNapok;
        this.calendar.updateTodaysDate(); // frissitjuk a naptarban a megjelolt napokat (calendar.dateClass)
      })
      .catch(error => this.toastService.httpError(error));
  }

  private dateClass() {
    return (inputDate: Date): MatCalendarCellCssClasses => {
      const highlightDate = this.horgaszattalToltottNapok?.some(hNap => Utils.equalsDate(new Date(hNap.datum), inputDate));
      return highlightDate ? 'moh-horgasz-nap' : undefined;
    };
  }
}
