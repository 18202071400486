import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FelszereltsegPipe } from 'app/altalanos/pipes/felszereltseg.pipe';
import { HorgaszmodszerPipe } from 'app/altalanos/pipes/horgaszmodszer.pipe';
import { HorgaszturizmusPoiPipe } from 'app/altalanos/pipes/horgaszturizmus-poi.pipe';
import { MegyeKodResolveNamePipe } from 'app/altalanos/pipes/megyeKodResolveName.pipe';
import { VersenyzoiEngedelyKorkategoriaPipe } from 'app/altalanos/pipes/versenyzoi-engedely-korkategoria.pipe';
import { VizteruletTipusPipe } from 'app/altalanos/pipes/vizterulet-tipus.pipe';
import { MaterialModule } from '../material/material.module';
import { KepSzerkesztoDialogComponent } from './kep-szerkeszto-dialog/kep-szerkeszto-dialog.component';
import { AllamiJegyStatuszPipe } from './pipes/allami-jegy-statusz.pipe';
import { AllamiJegyTipusPipe } from './pipes/allami-jegy-tipus.pipe';
import { FlogPipe } from './pipes/flog.pipe';
import { PenzosszegPipe } from './pipes/penzosszeg.pipe';
import { TermekIdoszakPipe } from './pipes/termek-idoszak.pipe';
import { TeruletiJegyKiegeszitoTipusPipe } from './pipes/teruleti-jegy-kiegeszito-tipus.pipe';
import { TeruletiJegyStatuszPipe } from './pipes/teruleti-jegy-statusz.pipe';
import { VersenyzoiEngedelyStatuszPipe } from './pipes/versenyzoi-engedely-statusz.pipe';

@NgModule({
  declarations: [
    VersenyzoiEngedelyKorkategoriaPipe,
    VersenyzoiEngedelyStatuszPipe,
    AllamiJegyTipusPipe,
    AllamiJegyStatuszPipe,
    PenzosszegPipe,
    TeruletiJegyStatuszPipe,
    TeruletiJegyKiegeszitoTipusPipe,
    TermekIdoszakPipe,
    HorgaszturizmusPoiPipe,
    MegyeKodResolveNamePipe,
    VizteruletTipusPipe,
    FelszereltsegPipe,
    HorgaszmodszerPipe,
    KepSzerkesztoDialogComponent,
    FlogPipe,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    IonicModule,
  ],
  exports: [
    VersenyzoiEngedelyKorkategoriaPipe,
    VersenyzoiEngedelyStatuszPipe,
    AllamiJegyTipusPipe,
    AllamiJegyStatuszPipe,
    PenzosszegPipe,
    TeruletiJegyStatuszPipe,
    TeruletiJegyKiegeszitoTipusPipe,
    TermekIdoszakPipe,
    HorgaszturizmusPoiPipe,
    MegyeKodResolveNamePipe,
    VizteruletTipusPipe,
    FelszereltsegPipe,
    HorgaszmodszerPipe,
    FlogPipe
  ],
  providers: [
    VersenyzoiEngedelyKorkategoriaPipe,
    VersenyzoiEngedelyStatuszPipe,
    AllamiJegyTipusPipe,
    AllamiJegyStatuszPipe,
    TeruletiJegyStatuszPipe,
    TeruletiJegyKiegeszitoTipusPipe,
    TermekIdoszakPipe,
    CurrencyPipe,
    PenzosszegPipe,
    MegyeKodResolveNamePipe,
    VizteruletTipusPipe,
    FelszereltsegPipe,
    HorgaszmodszerPipe,
    FlogPipe
  ]
})
export class AltalanosModule {
}
