/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface HorgaszkartyaPreviewData { 
    anyjaNeve: string;
    ervenyessegKezdete: string;
    ervenyessegVege: string;
    horgaszAzonosito?: string;
    nev: string;
    szemelyId: number;
    szuletesiDatum: string;
    szuletesiHely: string;
    vanHorgaszkartya: boolean;
    vanHorgaszkartyaFizetesAlatt: boolean;
}