<ion-card *ngIf="fogasiRekordList?.length > 0">
  <ion-card-header>
    <ion-card-title>Rekordok</ion-card-title>
  </ion-card-header>
  <ion-card-content>
    <ion-list>
      <ng-container *ngFor="let fogas of fogasiRekordList; let i=index">
        <ng-container *ngFor="let halmatrix of getHalmatrix(fogas.halmatrixId)">
          <ion-item lines="none" [class.not-displayed]="i > 3 && showMore === false">
            <ion-thumbnail slot="start" title="{{halmatrix.nev}}">
              <horgasz-base64-content *ngIf="halmatrix.kep" [content]="halmatrix.kep" width="64px"></horgasz-base64-content>
            </ion-thumbnail>
            <ion-label>
              <h3>{{halmatrix.nev}}</h3>
              <p *ngIf="fogas.hossz">Hossza: {{fogas.hossz}} cm</p>
              <p *ngIf="fogas.kerulet">Kerülete: {{fogas.kerulet}} cm2</p>
            </ion-label>
            <ion-label slot="end" class="ion-align-self-center suly">{{fogas.suly}} kg</ion-label>
          </ion-item>
        </ng-container>
      </ng-container>
      <div *ngIf="showMore === false && fogasiRekordList.length > 4 || showMore === true && fogasiRekordList.length > 3">
        <a (click)="onToggleShowMoreClick()">{{showMoreToggleLabel}}</a>
      </div>
    </ion-list>
  </ion-card-content>
</ion-card>
