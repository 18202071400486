import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Base64Content } from 'api';
import { Utils } from 'app/core/utils';
import { ForegroundColor } from '../enums/foregound-color';

@Component({
  selector: 'horgasz-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnChanges {

  @Input() min = 1;
  @Input() max = 10;
  @Input() step = 1;
  @Input() value: number;
  @Input() iconUrl: string;
  @Input() base64Content: Base64Content;
  @Input() imageBackground: ForegroundColor;
  @Input() label: string;
  @Input() control: FormControl;
  @Input() disabled = false;

  @Output() valueChanged: EventEmitter<number> = new EventEmitter();

  values: Array<number>;
  drag = false;

  constructor(
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (!Utils.hasValue(this.value)) {
      this.value = Number(this.min);
    }
    this.values = [];
    if (Utils.hasValue(this.max) && Utils.hasValue(this.min) && Utils.hasValue(this.step)) {
      if ((this.step > 0 && this.max < this.min) || (this.step < 0 && this.max > this.min)) {
        throw new Error('SliderComponent max/min not valid');
      }
      this.values = Utils.numberRangeArray(this.min, this.max, this.step);
    }
  }

  onMouseDown() {
    this.drag = true;
  }
  onMouseUp() {
    this.drag = false;
    this.publishValue();
  }
  onMouseOver(value: number) {
    if (this.drag) {
      this.value = value;
    }
  }
  onMouseLeave() {
    this.drag = false;
    this.publishValue();
  }

  jumpTo(value: number) {
    if (!this.disabled) {
      this.value = value;
      this.publishValue();
    }
  }

  private publishValue() {
    if (!this.disabled) {
      this.valueChanged.emit(this.value);
      if (this.control) {
        this.control.setValue(this.value);
      }
    }
  }

}
