import { Component, OnInit } from '@angular/core';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { MobilAppFelhasznalo } from 'api';
import { AuthService } from 'app/auth/auth.service';
import { TimerMutex } from 'app/core/timer-mutex';
import { PostafiokService } from 'app/services/postafiok.service';
import { routesConfig } from 'config/routesConfig';
import { MenuItem } from './menu-item';


@Component({
  selector: 'horgasz-menu',
  templateUrl: './menu.page.html',
  styleUrls: ['./menu.page.scss'],
})
export class MenuPage extends OnDestroyMixin implements OnInit {

  felhasznalo: MobilAppFelhasznalo;
  kezbesitetlenUzenetekSzama: number;
  menuGroups: Array<Array<MenuItem>>;

  private timerMutex = new TimerMutex(1);

  constructor(
    private authService: AuthService,
    private postafiokService: PostafiokService
  ) {
    super();
    authService.authentication.pipe(untilComponentDestroyed(this)).subscribe(auth => {
      this.felhasznalo = auth.felhasznalo;
      this.buildMenu();
    });
  }

  ngOnInit() {
    this.postafiokService.kezbesitetlenUzenetekSzama.pipe(untilComponentDestroyed(this))
      .subscribe(result => this.kezbesitetlenUzenetekSzama = result);
  }

  ionViewDidEnter() {
    this.buildMenu();
  }

  onRefreshClick(event: any){
    this.authService.refreshAuthenticationFromServer();
    event.target.complete();
  }

  buildMenu() {
    if (this.felhasznalo) {
      this.timerMutex.runExclusive(() => {
        const hasEnaplo = this.felhasznalo.ervenyesFogasiNaplo?.elektronikus;
        const enaploMenuLabel = hasEnaplo ? 'E-napló' : 'E-napló engedélyezése';
        let enaploRouteValue: string;
        if(hasEnaplo){
          enaploRouteValue = `/${routesConfig.tabs}/${routesConfig.fogasiNaplo}`;
        } else {
          enaploRouteValue = `/${routesConfig.tabs}/${routesConfig.horgaszEngedelyek}/${routesConfig.nyilatkozatok}`;
        }
        this.menuGroups = [
          [
            {label: 'Horgász okmánytárca', route: `/${routesConfig.tabs}/${routesConfig.horgaszEngedelyek}`},
            {label: 'Interaktív területi jegy vásárlás', route: `/${routesConfig.tabs}/${routesConfig.teruletiJegyVasarlas}`},
            {label: 'Korlátozás alá vont területek', route: `/${routesConfig.tabs}/${routesConfig.korlatozottTeruletek}`},
            {label: enaploMenuLabel, route: enaploRouteValue},
          ]
          // [
          //   {label: 'Kedvenc horgászhelyek', route: `/${routesConfig.tabs}/${routesConfig.kedvencHorgaszhelyek}`, disabled: true},
          //   {label: 'Fogásaim', route: `/${routesConfig.tabs}/${routesConfig.fogasaim}`, disabled: true},
          // ],
        ];
      });
    }
  }

  async onLogout() {
    await this.authService.logout();
    //this.router.navigateByUrl(routesConfig.bejelentkezes, { replaceUrl: true });
  }

}
