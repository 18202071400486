import { Injectable } from '@angular/core';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import {
  AbstractFogas,
  AddFogasRequest,
  AddFogasResponse,
  ElektronikusFogas,
  ElektronikusFogasNyilvantartas,
  FogasNyilvantartasRequest,
  HorgaszattalToltottNap,
  HorgaszNapRequest,
  MobilAppControllerService,
  MobilAppFelhasznalo
} from 'api';
import { LocationService } from 'app/services/location.service';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { concatMap, filter, mergeMap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { CacheService } from './cache.service';
import { ConnectionStateService } from './connection-state.service';

enum HorgaszNapOperationType {
  ADD = 'add',
  REMOVE = 'remove'
}

interface HorgaszNapOperation {
  type: HorgaszNapOperationType;
  horgaszNapRequest: HorgaszNapRequest;
}

const KEY_FOGASINAPLO = 'fogasiNaploLekerdezes';
const KEY_LOCAL_FOGASOK = 'localFogasok';
const KEY_HORGASZ_NAP_OPERATIONS = 'horgaszNapOperations';

@Injectable({
  providedIn: 'root'
})
export class FogasiNaploService extends OnDestroyMixin {

  felhasznalo: MobilAppFelhasznalo;

  refreshedDate: Observable<Date>;
  synchronization: Observable<ElektronikusFogasNyilvantartas>;

  private refreshedDateSubject = new BehaviorSubject<Date>(null);
  private synchronizationSubject = new BehaviorSubject<ElektronikusFogasNyilvantartas>(null);

  constructor(
    private authService: AuthService,
    private connectionStateService: ConnectionStateService,
    private mobilAppControllerService: MobilAppControllerService,
    private cacheService: CacheService,
    private locationService: LocationService,
  ) {
    super();

    this.refreshedDate = this.refreshedDateSubject.asObservable();
    this.synchronization = this.synchronizationSubject.asObservable().pipe(
      filter(value => !!value)
    );

    this.nextRefreshedDate();

    this.authService.authentication.pipe(untilComponentDestroyed(this)).subscribe(async auth => {
      this.felhasznalo = auth.felhasznalo;
      await this.refreshData();
    });

    this.connectionStateService.onlineState.pipe(untilComponentDestroyed(this)).subscribe(async () => {
      await this.refreshData();
    });
  }

  enaploLekerdezes(): Observable<ElektronikusFogasNyilvantartas> {
    if (this.felhasznalo?.ervenyesFogasiNaplo?.elektronikus) {
      const request: FogasNyilvantartasRequest = {fogasiNaploId: this.felhasznalo.ervenyesFogasiNaplo.sorszam};
      return this.cacheService.cache<ElektronikusFogasNyilvantartas>(this.mobilAppControllerService.enaploLekerdezes(request), KEY_FOGASINAPLO)
        .pipe(
          concatMap(async (response) => {
            this.nextRefreshedDate();
            const localFogasok = (await this.getLocalFogasok()).map((fogas) => {
              const elektronikusFogas: ElektronikusFogas = {
                halfajId: fogas.halfajId,
                suly: fogas.suly,
                vizteruletId: fogas.vizteruletId,
                idopont: fogas.idopont
              };
              return elektronikusFogas;
            });
            response.fogasok = response.fogasok ? response.fogasok.concat(localFogasok) : localFogasok;
            if (!response.horgaszattalToltottNapok) {
              response.horgaszattalToltottNapok = [];
            }
            await this.syncHorgaszNapOperationsIntoArray(response.horgaszattalToltottNapok);
            return response;
          })
        );
    } else {
      return of<ElektronikusFogasNyilvantartas>(null);
    }
  }

  enaploForecastAddFogas(forecastRequest: AddFogasRequest): Observable<AddFogasResponse> {
    if(this.connectionStateService.isOnline()){
      return this.mobilAppControllerService.enaploForecastAddFogas(forecastRequest);
    } else {
      return this.buildLocalAddFogasResponse(forecastRequest);
    }
  }

  enaploAddFogas(request: AddFogasRequest): Observable<AddFogasResponse> {
    request.gpsKoordinata = this.locationService.lastGpsKoordinata;
    if(this.connectionStateService.isOnline()){
      return this.mobilAppControllerService.enaploAddFogas(request);
    } else {
      // Do the local storage stuff here
      return from(this.addFogasToLocalStorage(request)).pipe(
        mergeMap(() => this.buildLocalAddFogasResponse(request))
      );
    }
  }

  enaploAddHorgaszNap(request: HorgaszNapRequest): Observable<ElektronikusFogasNyilvantartas> {
    request.gpsKoordinata = this.locationService.lastGpsKoordinata;
    if(this.connectionStateService.isOnline()){
      return this.mobilAppControllerService.enaploAddHorgaszNap(request);
    } else {
      return from(this.addHorgaszNapOperation({
        type: HorgaszNapOperationType.ADD,
        horgaszNapRequest: request
      })).pipe(mergeMap(() => this.enaploLekerdezes()));
    }
  }

  private buildLocalAddFogasResponse(request: AddFogasRequest){
    const abstractFogas: AbstractFogas = {
      halfajId: request.halfajId,
      suly: request.suly,
      vizteruletId: request.vizteruletId,
      id: undefined
    };
    const addFogasResponse: AddFogasResponse = {
      figyelmeztetoUzenetek: ['Offline módban vagyunk, a fogásokat a későbbiekben szinkronizáljuk.'],
      fogas: abstractFogas
    };
    return of<AddFogasResponse>(addFogasResponse);
  }

  private async removeHorgaszNapOperations(){
    await this.cacheService.set(KEY_HORGASZ_NAP_OPERATIONS, []);
  }

  private async getHorgaszNapOperations(): Promise<Array<HorgaszNapOperation>> {
    return await this.cacheService.get<Array<HorgaszNapOperation>>(KEY_HORGASZ_NAP_OPERATIONS) ?? [];
  }

  private async addHorgaszNapOperation(operation: HorgaszNapOperation) {
    const horgaszNapOperations = await this.getHorgaszNapOperations();
    horgaszNapOperations.push(operation);
    await this.cacheService.set(KEY_HORGASZ_NAP_OPERATIONS, horgaszNapOperations);
  }

  private async syncHorgaszNapOperations() {
    const operations = await this.getHorgaszNapOperations();
    const jobs = operations.map(async (operation) => {
      switch (operation.type) {
        case HorgaszNapOperationType.ADD:
          return this.mobilAppControllerService.enaploAddHorgaszNap(operation.horgaszNapRequest).toPromise();
        //case HorgaszNapOperationType.REMOVE:
          //return this.mobilAppControllerService.eFogasiNaploRemoveHorgaszNap(operation.horgaszNapRequest).toPromise();
      }
    });
    await Promise.all(jobs);
    await this.removeHorgaszNapOperations();
  }

  private async syncHorgaszNapOperationsIntoArray(horgaszattalToltottNapok: Array<HorgaszattalToltottNap>) {
    //TODO megcsinalni
    // const horgaszNapok = horgaszattalToltottNapok.map(htn => htn.datum);
    // const operations = await this.getHorgaszNapOperations();
    // operations.forEach((operation) => {
    //   const index = horgaszNapok.indexOf(operation.horgaszNapRequest.datum);
    //   switch (operation.type) {
    //     case HorgaszNapOperationType.ADD:
    //       if (index < 0) {
    //         horgaszNapok.push(operation.horgaszNapRequest.datum);
    //       }
    //       break;
    //     case HorgaszNapOperationType.REMOVE:
    //       if (-1 < index) {
    //         horgaszNapok.splice(index, 1);
    //       }
    //       break;
    //   }
    // });
  }

  private async getLocalFogasok(): Promise<Array<AddFogasRequest>> {
    return await this.cacheService.get<Array<AddFogasRequest>>(KEY_LOCAL_FOGASOK) ?? [];
  }

  private async addFogasToLocalStorage(request: AddFogasRequest) {
    const localFogasok = await this.getLocalFogasok();
    localFogasok.push(request);
    await this.cacheService.set(KEY_LOCAL_FOGASOK, localFogasok);
  }

  private async syncLocalFogasok() {
    const localFogasok = await this.getLocalFogasok();
    if (localFogasok.length === 0) {
      return;
    }
    const jobs = localFogasok.map(async fogas => {
      await this.mobilAppControllerService.enaploAddFogas(fogas).toPromise();
    });
    await Promise.all(jobs);
    await this.removeLocalFogasok();
  }

  private async removeLocalFogasok() {
    await this.cacheService.set(KEY_LOCAL_FOGASOK, []);
  }

  private nextRefreshedDate() {
    this.cacheService.getCacheItem<any>(KEY_FOGASINAPLO).then(item => {
      if(item){
        this.refreshedDateSubject.next(item.cachedDate);
      }
    });
  }

  private async refreshData(): Promise<void> {
    if(this.felhasznalo){
      await Promise.all([this.syncHorgaszNapOperations(), this.syncLocalFogasok()]);
      await this.enaploLekerdezes().toPromise().then(result =>
        this.synchronizationSubject.next(result)
      );
    }
  }
}
