<ion-header>
  <ion-toolbar color="secondary">
    <ion-title>Fogasaim</ion-title>
    <ion-buttons slot="end" class="ion-margin-top">
      <horgasz-header-notification></horgasz-header-notification>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="moh-bg-light" [fullscreen]="true">

  <ion-fab slot="fixed" vertical="bottom" horizontal="end">
    <ion-fab-button size="small" (click)="onAddFogasClick()">
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>

  <ng-container *ngIf="fogasaimList?.length > 0; else noContent">
    <ion-searchbar placeholder="Keresés" show-clear-button="always" [debounce]="500" (ionChange)="handleSearchChange($event)"></ion-searchbar>

    <ion-grid>
      <ion-row *ngFor="let item of filteredFogasaimList">
        <horgasz-flog-card [flog]="item"></horgasz-flog-card>
      </ion-row>
    </ion-grid>
  </ng-container>

  <ng-template #noContent>
    <horgasz-no-content [isLoading]="isLoading" noContentText="Nincs fogás"></horgasz-no-content>
  </ng-template>
</ion-content>
