<horgasz-poi-popup *ngIf="poi"
  [title]="poi.elnevezes"
  [subTitle]="poi.details?.horgasztatasNev"
  editLabel="Kedvenc horgászhely szerkesztése"
  (editClicked)="onKedvencHelySzerkesztese()"
  (closePoiPopup)="onCloseClick()"
  [endSectionType]="'edit'"
  iconUrl="assets/poi/icon/kedvenchorgaszhely-popup.svg">
  <horgasz-base64-content kepContent [content]="kepBase64Content"></horgasz-base64-content>
  <ng-container middleSection>
    <p style="white-space: pre-wrap;" class="ion-padding-top text-overflow-line-clamp-3">
      {{ poi.details?.leiras }}
    </p>
    <p>
      <span style="font-weight: 400;">Koordináta: </span><strong>{{ poi.koordinata.szelessegiFok | number:'1.1-6':'en' }}, {{ poi.koordinata.hosszusagiFok | number:'1.1-6':'en' }}</strong>
    </p>
    <p *ngIf="poi.koordinata.szelessegiFok && poi.koordinata.hosszusagiFok" class="actions">
      <a href="javascript:void(0)" (click)="goToLocation()">Útvonal ide</a>
    </p>
  </ng-container>
</horgasz-poi-popup>
