<ion-content class="moh-bg-light">
  <ion-grid>
    <ion-row>
      <ion-col size-md="6" offset-md="3">
        <ion-card class="inline-calendar-card">
          <mat-calendar
            class="moh-calendar"
            [(selected)]="selectedDate"
            (_userSelection)="dateSelected($event)"
            [minDate]="minDate"
            [maxDate]="maxDate"
            [dateClass]="dateClassVar">
          </mat-calendar>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
<horgasz-last-modified-refresher
  (refresh)="onLastModifierRefreshEvent()"
  [lastModifiedType]="LastModifiedType.FOGASINAPLO">
</horgasz-last-modified-refresher>

