import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { AllamiJegy, MobilAppFelhasznalo } from 'api';
import { AuthService } from 'app/auth/auth.service';
import { routesConfig } from 'config/routesConfig';

@Component({
  selector: 'mohosz-jegyek',
  templateUrl: './jegyek.page.html',
  styleUrls: ['./jegyek.page.scss'],
})
export class JegyekPage extends OnDestroyMixin{

  felhasznalo: MobilAppFelhasznalo;
  allamiJegy: AllamiJegy;

  constructor(
    private authService: AuthService,
    private router: Router
  ){
    super();
    this.authService.authentication.pipe(untilComponentDestroyed(this)).subscribe(auth => {
      this.allamiJegy = auth.felhasznalo?.ervenyesAllamiJegy;
      this.felhasznalo = auth.felhasznalo;
    });
  }

  onTeruletiJegyVasarlasClick(){
    this.router.navigateByUrl(`/${routesConfig.tabs}/${routesConfig.teruletiJegyVasarlas}`);
  }

  onErtekesitesiPontokClick() {
    this.router.navigateByUrl(`/${routesConfig.tabs}/${routesConfig.horgaszEngedelyek}/${routesConfig.ertekesitesiPontok}`);
  }
}
